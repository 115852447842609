/**
 * Regex used to parse numeric values (int, float) from knobs like '<anything>=numeric_value'.
 * This pattern is used if knob format isn't specified in metadata.
 * @type {RegExp}
 */
export const DEFAULT_KNOB_REGEX = new RegExp('^.*=([-]?(\\d*[.])?\\d+)$', '');

/**
 * Regex used to create pattern from knob format
 * @type {RegExp}
 */
export const patternRegex = new RegExp('^(.*[^%]+)?(%[^%\\s]+)$', '');

/**
 * String used to replace printf decimal formats (%d, %i, %.2f, ...) with pattern to match int or float (decimal)
 * @type {string}
 */
export const DECIMAL_SUBST = '^$1([-]?(\\d*[.])?\\d+)$';

/**
 * String used to replace printf hexadecimal format (%x, %X)
 * @type {string}
 */
export const HEXADECIMAL_SUBST = '^$1([1-9A-Fa-f][0-9A-Fa-f]*)$';
