import {DEFAULT_PAGE_LIMIT} from '@/constants';
import Endpoint from '@/api/resources/base';
import urls from '@/api/urls';

export default class Experiment extends Endpoint {
  /**
   * For each experiments under current active project returns aggregated sample metrics.
   * @param {UUID} project project's uuid
   * @param {ExperimentsQuerySerializer} [data] GET query params
   * @return {Promise<import('axios').AxiosResponse<AgExperimentMetricsSerializer[]>>}
   */
  aggregated = async (project, data) => {
    const url = urls.project.experiment.aggregated.format({project});
    return this.api.get(url, data);
  };
  /**
   * List of all tags in project
   * @param {UUID} project
   * @returns {Promise<import('axios').AxiosResponse<AgTagSerializer[]>>}
   */
  agTags = async (project) => {
    const url = urls.project.experiment.agTags.format({project});
    return this.api.get(url);
  };
  /**
   * Status of an experiment
   * @param {UUID} project
   * @param {UUID} experiment
   * @returns {Promise<import('axios').AxiosResponse<AgExperimentStatusSerializer>>}
   */
  agStatus = async (project, experiment) => {
    const url = urls.project.experiment.agStatus.format({project, experiment});
    return this.api.get(url);
  };
  /**
   * Get configurations 2d array by experiment
   * @param {UUID} project
   * @param {UUID} experiment
   * @returns {Promise<import('axios').AxiosResponse<AggregatedConfigurationKeys[]>>}
   */
  agConfiguration = async (project, experiment) => {
    const url = urls.project.experiment.agConfiguration.format({project, experiment});
    return this.api.get(url);
  };

  /**
   * Retrieve history for experiment
   * @param {UUID} project
   * @param {UUID} experiment
   * @param {{created_at__gt: string}} params
   * @returns {Promise<import('axios').AxiosResponse<ExperimentHistorySerializer[]>>}
   */
  history = async (project, experiment, params) => {
    const url = urls.project.experiment.history.format({project, experiment});
    return this.api.get(url, params);
  };
  /**
   * Patch experiment
   * @param {UUID} project
   * @param {UUID} experiment
   * @param {ExperimentSerializerWriteFields} data
   * @returns {Promise<import('axios').AxiosResponse<ExperimentSerializer>>}
   */
  update = async (project, experiment, data = {}) => {
    const url = urls.project.experiment.detail.format({project, experiment});
    return this.api.patch(url, data);
  };
  /**
   * Retrieves list of experiments in compact form
   * @param {UUID} project
   * @param {number | null} offset start fetching from this index
   * @param {number | null} limit number of experiments to fetch starting from offset
   * @param {Record<string, any>} [query] optional GET query
   * @returns {Promise<import('axios').AxiosResponse<Paginated<ExperimentTrackSerializer[]>>>}
   */
  track = async (project, offset = 0, limit = DEFAULT_PAGE_LIMIT, query = {}) => {
    const data = {offset, limit, ...query};
    const url = urls.project.experiment.track.format({project});
    const response = await this.api.get(url, data);
    return this.rehydrate(this.addPagination(response));
  };

  /**
   * Deletes an experiment
   * @param {UUID} project
   * @param {UUID} experiment
   * @returns {Promise<import('axios').AxiosResponse<*>>}
   */
  destroy = async (project, experiment) => {
    const url = urls.project.experiment.detail.format({project, experiment});
    return this.api.delete(url);
  };

  /**
   *
   * @param {UUID} project
   * @param {UUID} experiment
   * @returns {Promise<import('axios').AxiosResponse<ExperimentDownloadableSerializer>>}
   */
  downloadable = async (project, experiment) => {
    const url = urls.project.experiment.downloadable.format({project, experiment});
    return this.api.get(url);
  };
}
