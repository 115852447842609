import AbortController from 'abort-controller';
import {rehydrate} from '@/utils/array';

export default class Endpoint {
  /** @type {Record<str, AbortController>} **/
  _ctrl = {};

  /**
   * Base class for all API endpoints
   * @param {API} client
   */
  constructor(client) {
    this.api = client;
  }

  /**
   * If you need cancel your request in the future, create abort controller for it.
   * @param {string} name
   * @returns {AbortController|{abort: (function(): null)}}
   */
  ctrl(name) {
    return this._ctrl[name] || {abort: () => null};
  }

  /**
   * Creates an abort controller and adds it to register.
   * Only works with plan `fetch`, don't know about `axios`.
   * Usage:
   *  fetch(..., {signal: this.addCtrl('foo')});
   *  // cancelling request
   *  api.<endpoint>.ctrl('foo').abort()
   * @param {string} name
   * @returns {AbortController}
   */
  addCtrl(name) {
    const ctrl = new AbortController();
    this._ctrl = {[name]: ctrl};
    return ctrl;
  }

  /**
   * Rehydrates CSV response and mutates `response` argument.
   * CSV is a form of response where keys and data stored separately, example:
   *  [['a', 'b', ['c', ['d']]], ['a value', 'b value', ['c name of key', ['d value']]]]
   * @param {import('axios').AxiosResponse<any>} response
   */
  rehydrate(response) {
    let results;
    const isPaginated = !Array.isArray(response.data);

    if (isPaginated) {
      results = response.data.results;
    } else {
      results = response.data;
    }

    if (results.length > 0) {
      const keys = results.shift();
      results = rehydrate(keys, results);
    }

    // set results depending on response type (paginated or non-paginated)
    if (isPaginated) {
      response.data.results = results;
    } else {
      response.data = results;
    }
    return response;
  }

  /**
   * Depending on how endpoint is called response may or may not be paginated, for example if you have all objects on
   * UI, and you call refresh using limit offset pagination, response will be non-paginated. This ambiguous behaviour
   * makes things harder, this method converts a non-paginated response to paginated.
   * @param {import('axios').AxiosResponse<(Paginated<*[]>|*[])>} response
   * @returns {import('axios').AxiosResponse<Paginated<*[]>>}
   */
  addPagination(response) {
    if (Array.isArray(response.data)) {
      response.data = {
        count: response.data.length,
        next: null,
        previous: null,
        results: response.data,
      };
    }
    return response;
  }
}
