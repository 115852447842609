import Endpoint from '@/api/resources/base';
import urls from '@/api/urls';

export default class SLMAlertEvent extends Endpoint {
  /**
   * @return {Promise<import('axios').AxiosResponse<AlertEventSerializer[]>>}
   */
  list = async () => {
    return this.api.get(urls.slm.alert.list);
  };
  /**
   * retrieve AlertEvent detail by pk of alert instance
   * @param {number} alertId
   * @returns {Promise<import('axios').AxiosResponse<AlertEventSerializer>>}
   */
  detail = async (alertId) => {
    const url = urls.slm.alert.event.format({alertId});
    return this.api.get(url);
  };
  /**
   * Returns information about where the event occurred on a chip.
   * @param {number} alertId
   * @returns {Promise<import('axios').AxiosResponse<AlertEventLocationSerializer>>}
   */
  location = async (alertId) => {
    const url = urls.slm.alert.location.format({alertId});
    return this.api.get(url);
  };
  /**
   * Returns information about controlled area on a device block.
   * @param {number} alertId
   * @returns {Promise<import('axios').AxiosResponse<BriefDeviceDomainSerializer>>}
   */
  brief = async (alertId) => {
    const url = urls.slm.alert.brief.format({alertId});
    return this.api.get(url);
  };
  /**
   * Return compare with pmm selectors in the parent scopes.
   * @param {number} alertId
   * @param {string} to
   * @returns {Promise<import('axios').AxiosResponse<AlertCompareSerializer>>}
   */
  compare = async (alertId, to) => {
    const url = urls.slm.alert.compare.format({alertId});
    return this.api.get(url, {to});
  };
  /**
   * Return alert endpoint margins for current alert PMU.
   * @param {number} alertId
   * @returns {Promise<import('axios').AxiosResponse<AlertPMUMarginSerializer[]>>}
   */
  pmuMargin = async (alertId) => {
    const url = urls.slm.alert.pmuMargin.format({alertId});
    return this.api.get(url);
  };
}
