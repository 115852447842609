import Vue from 'vue';
import Vuex from 'vuex';
import modules from '@/store/modules';


Vue.use(Vuex);
const store = new Vuex.Store({
  modules,
  // we have a lot of mutations inside actions, but from what I see it only affects debug tools to track store
  // changes, I don't see any other issues with this
  strict: false,
});

export default store;
