import moment from 'moment-timezone';

const ISO_DATE_FMT = 'YYYY-MM-DDTHH:mm:ss.SSSSZ';

/**
 * Subtract N `days` from `date`
 * @param {Date} date datetime
 * @param {Number} days number of days
 * @returns {Date} a new datetime
 */
export function subDays(date, days) {
  const d = new Date(date.getTime());
  d.setDate(date.getDate() - days);
  return d;
}

/**
 * Adds N `days` from `date`
 * @param {Date} date datetime
 * @param {Number} days number of days
 * @returns {Date} a new datetime
 */
export function addDays(date, days) {
  const d = new Date(date.getTime());
  d.setDate(date.getDate() + days);
  return d;
}

/**
 * Difference between a and b dates in days
 * @param {Date} a
 * @param {Date} b
 */
export function daysSince(a, b) {
  const timeDiff = a.getTime() - b.getTime();
  return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
}

/**
 * Format date according to timezone and format
 * @param {Date | moment.Moment} date
 * @param {string} tz timezone
 * @returns {string}
 */
export function formatDate(date, tz) {
  return moment(date).tz(tz).format(ISO_DATE_FMT);
}

/**
 * Parses ISO date time string
 * @param {string} date
 * @return {moment.Moment}
 */
export function parseDate(date) {
  return moment(date, ISO_DATE_FMT);
}
