import jStat from 'jstat';


class TTest {
  /**
   *
   * @param {TTest.Statistics} groupA
   * @param {TTest.Statistics} groupB
   * @param {TTest.Parameters?} params
   */
  constructor(groupA, groupB, params) {
    this.groupA = groupA;
    this.groupB = groupB;
    this.params = params || new TTest.Parameters();
    this.results = null;
  }

  /**
   * Verifies if TTest can be performed
   * @returns {(boolean|string)[]} is valid and error message if isn't valid or empty string.
   */
  validate() {
    this.results = this.calcGroups();
    if (this.groupA.n <= 1 || this.groupB.n <= 1) {
      return [false, 'Number of samples of each group should be at least 2.'];
    }
    return [true, ''];
  }

  /**
   * FIXME: add description
   * @returns {TTest.Calculated}
   */
  calcGroups() {
    const results = new TTest.Calculated();

    const aStdev = Math.pow(this.groupA.s, 2) / this.groupA.n;
    const bStdev = Math.pow(this.groupB.s, 2) / this.groupB.n;
    const aVar = Math.pow(this.groupA.s, 4) / (Math.pow(this.groupA.n, 2) * (this.groupA.n - 1));
    const bVar = Math.pow(this.groupB.s, 4) / (Math.pow(this.groupB.n, 2) * (this.groupB.n - 1));
    results.df = Math.pow(aStdev + bStdev, 2) / (aVar + bVar);

    results.sampleVal = this.groupA.avg - this.groupB.avg;
    results.h0Val = this.params.d;
    results.s = Math.sqrt(Math.pow(this.groupA.s, 2) / this.groupA.n + Math.pow(this.groupB.s, 2) / this.groupB.n);
    if (results.simple) {
      results.statistic = (results.sampleVal - results.h0Val) / results.s;
      results.CDFValue = jStat.studentt.inv(1 - this.params.alfa / 2, results.df);
      results.CDFValueL = -results.CDFValue;
    }
    return results;
  }

  /**
   * FIXME: add description
    * @returns {number[]}
   */
  getDataTable() {
    const {results} = this;
    const step = 0.01;
    const delta = 5 * step;
    // [[value, reject, accept, T]]
    const series = [];
    let startPoint = jStat.studentt.inv(0.001, results.df) - 0.5;
    const endPoint = jStat.studentt.inv(0.999, results.df) + 0.5;
    for (; startPoint <= endPoint; startPoint = startPoint + step) {
      const y = jStat.studentt.pdf(startPoint, results.df);
      if (startPoint <= results.statistic + delta && startPoint >= results.statistic - delta) {
        series.push([startPoint, 0, 0, y]);
      } else {
        if (startPoint <= results.CDFValueL || startPoint >= results.CDFValue) {
          series.push([startPoint, y, 0, 0]);
        } else {
          series.push([startPoint, 0, y, 0]);
        }
      }
    }
    return Object.freeze(series);
  }

  /**
   * FIXME: add description
   * @returns {[*,*][][]}
   */
  getSeries() {
    const table = this.getDataTable();
    return [
      Object.freeze(table.map(([value, reject]) => ([value, reject]))),
      Object.freeze(table.map(([value, , accept]) => ([value, accept]))),
      Object.freeze(table.map(([value, , , T]) => ([value, T]))),
    ];
  }
}

/**
 * @param {Number} CDFValue the value of x in the cdf of the Student's T distribution with dof degrees of freedom.
 * @param {Number} CDFValueL the value of x in the inverse of the cdf for the Student's T distribution with dof degrees of freedom.
 * @param {Number} statistic
 * @param {Number} df degrees of freedom
 * @param {Boolean} simple
 * @param {Number} sampleVal
 * @param {Number} h0Val
 * @param {Number} s
 */
TTest.Calculated = function (CDFValue = 0, CDFValueL = 0, statistic = 0, df = 0, simple = true, sampleVal = 0, h0Val = 0, s = 0) {
  this.CDFValue = CDFValue;
  this.CDFValueL = CDFValueL;
  this.statistic = statistic;
  this.df = df;
  this.simple = simple;
  this.sampleVal = sampleVal;
  this.h0Val = h0Val;
  this.s = s;

  this.toString = function () {
    return `df: ${this.df}; cdf of t: ${this.CDFValue}`;
  };
};

/**
 * @param {Number} d expected difference
 * @param {Number} alfa significance level
 */
TTest.Parameters = function (d = 0, alfa = 0.05) {
  this.d = d;
  this.alfa = alfa;

  this.toString = function () {
    return `expected difference: ${this.d}; significance level: ${this.alfa}`;
  };
};

/**
 * @param {Number} s sample alpha (Sample standard deviation)
 * @param {Number} n number of samples
 * @param {Number} avg sample average
 */
TTest.Statistics = function (s, n, avg) {
  this.s = s;
  this.n = n;
  this.avg = avg;

  this.toString = function () {
    return `stdev: ${this.s}; count: ${this.n}; mean: ${this.avg}`;
  };
};


export {TTest};
