import Endpoint from '@/api/resources/base';
import urls from '@/api/urls';

export default class DeviceConfiguration extends Endpoint {
  /**
   * List of configurations.
   */
  list = async (params) => {
    return this.api.get(urls.slm.configuration.list, params);
  };

  /**
   * A single configuration.
   * @param {number} id Configuration ID.
   */
  detail = async (id) => {
    return this.api.get(urls.slm.configuration.detail.format({configurationId: id}));
  };

  /**
   * Create a new configuration.
   */
  create = async (data) => {
    return this.api.post(urls.slm.configuration.list, data);
  }

  /**
   * Update a configuration
   */
  update = async (id, data) => {
    return this.api.patch(urls.slm.configuration.detail.format({configurationId: id}), data);
  }

  /**
   * Delete a configuration
   */
  destroy = async (id) => {
    return this.api.delete(urls.slm.configuration.detail.format({configurationId: id}));
  }
}
