/**
 * YOU ARE GOING TO BREAK VUE IF YOU DEFINE OBJECT PROTOTYPES
 */

import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import get from 'lodash/get';
import {elapsed, strftime} from '@/utils/time';
import {substring} from '@/utils/text';
import {maybeToFixed} from '@/utils/number';


Number.prototype.isWhole = function () {
  return this - Math.floor(this) === 0;
};

/**
  * This method add k and M sufixes for positive and negative numbers
  * using `k` for kilo - according to https://www.nist.gov/pml/owm/metric-si-prefixes
 */
Number.prototype.kFormat = function () {
  if (this < 1000 && this > -1000) {
    return this.toString();
  } else if (this < 1000000 && this > -1000000) {
    return `${Math.floor(this / 1000)}k`;
  }
  return `${Math.floor(this / 1000000)}M`;
};

Number.DISPLAY_FMT = {
  COMMA_SEP: 1,
  PERCENT: 2,
};

Number.prototype.display = function (fmt = Number.DISPLAY_FMT.COMMA_SEP) {
  if (fmt === Number.DISPLAY_FMT.PERCENT) {
    return maybeToFixed(this, 3, 2) + '%';
  }
  return this.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * @param {Object} cfg
 * @param {string} cfg.prop key in object, or element in array
 * @param {function?} cfg.key as argument gets each element in array, return value will be used to compare `a` and `b` in sort func
 * @param {boolean?} cfg.desc order, false by default
 * @param {function?} cfg.cast instead of comparing values as is, use this to cast into a proper type for comparison
 * @returns {this|*[]} sorted array
 */
Array.prototype.sortBy = function (cfg) {
  const toString = Object.prototype.toString;
  // default key function
  const parse = function (x) {
    return x;
  };
  // gets the item to be sorted
  const getItem = function (x) {
    const isObject = typeof x === 'object';
    const isProp = isObject && this.prop in x;
    const value = this.key(isProp ? x[this.prop] : x);
    if (cfg.cast) {
      return cfg.cast(value);
    }
    return value;
  };
  if (this.length === 0) {
    return [];
  }
  if (toString.call(cfg) !== '[object Object]') {
    cfg = {};
  }
  if (typeof cfg.key !== 'function') {
    cfg.key = parse;
  }
  cfg.desc = cfg.desc ? -1 : 1;
  return [...this].sort(function (a, b) {
    a = getItem.call(cfg, a);
    b = getItem.call(cfg, b);
    return cfg.desc * (a < b ? -1 : +(a > b));
  });
};

Array.prototype.last = function () {
  return this[this.length - 1];
};

/**
 * Joins array elements, Object properties and casts everything else to String, then performs indexOf on this string
 * Optionally accepts aliases, purely for UI where an array element can be represented somehow else (for example
 * booleans)
 * @param {String} term - search term
 * @param {Object} aliases - {replace_from: replace_to}
 * @returns {*[]} - filtered array
 */
Array.prototype.search = function (term, aliases = {}) {
  term = term.toLowerCase();

  /**
   * @param {String | Array | Object} o String, Array or Object
   * @returns {Set}
   */
  const toStringSet = function (o) {
    const result = new Set();

    /**
     * This function handles arrays, objects or strings.
     * Iterates recursively over array or object and if value type is string push it to result array.
     * Returns result array values as  strings (string set) joined by spaces.
     * @param {*[]|object|*} obj
     */
    function rec(obj) {
      if (isArray(obj)) {
        obj.forEach((el) => rec(el));
      } else if (isObject(obj)) {
        rec(Object.values(obj));
      } else if (obj) {
        result.add((aliases[obj] || obj).toString().toLowerCase());
      }
    }

    rec(o);
    return result;
  };

  return this.filter((el) => {
    return Array(...toStringSet(el)).join('|').indexOf(term) > -1;
  });
};

/**
 * Alternative to listOfObjects.map((value) => value === 'foo' ? newValue : oldvalue )
 * @template T
 * @param {string} key newObject's key or path (key.key.key)
 * @param {T} newObject
 * @returns {T[]}
 */
Array.prototype.update = function (newObject, key) {
  return this.map((object) => {
    if (get(object, key) === get(newObject, key)) {
      return {...object, ...newObject};
    }
    return object;
  });
};

Array.prototype.remove = function (oldObject, key) {
  return this.filter((object) => get(object, key) !== get(oldObject, key));
};

Array.prototype.exclude = function (func) {
  return this.filter((el) => !func(el));
};

/**
 * Looks for an object in array, usage [{a: 1, b: 3}].indexBy({a: 1})
 * @param {{[key in any]: any}} predicate
 * @returns {number} index in array
 */
Array.prototype.indexBy = function (predicate) {
  /**
   * Try if every `key:value` pair of the `predicate` object matches with an object in list.
   * @param {{[key in any]: any}} elem
   * @returns {this is string[]}
   */
  function search(elem) {
    return Object.keys(predicate).every(function (k) {
      return elem[k] === predicate[k];
    });
  }

  return this.map(search).indexOf(true);
};

Date.prototype.strftime = function (format) {
  // just for keeping all formats in a single place you can use __ __ thing
  // instead of format
  switch (format) {
    case '__short__': {
      return strftime('%d %b', this);
    }
    case '__long__': {
      return strftime('%d %b, %Y %H:%M:%S', this);
    }
    case '__full__': {
      const val = strftime('%d %b %Y, %H:%M:%S', this);
      const ms = strftime('%f', this).slice(0, 3);
      return `${val}:${ms}`;
    }
    default: {
      return strftime(format, this);
    }
  }
};

Date.prototype.display = function () {
  return this.strftime('__full__');
};

Date.prototype.relative = function () {
  const passed = elapsed(new Date(), this);
  return {
    0: 'Today',
    1: 'Yesterday',
    7: 'Week ago',
    365: 'Year ago',
  }[passed.days] || this.strftime('%d %b %Y');
};

Boolean.prototype.display = function () {
  return {[true]: 'Yes', [false]: 'No'}[this];
};

String.prototype.display = function () {
  return this.replace(/_/g, ' ');
};

/**
 * Replace :<var> with fmt[<var>]
 * @param {Object} fmt
 * @returns {string}
 */
String.prototype.format = function (fmt) {
  return substring(this, fmt);
};

/**
 * https://stackoverflow.com/a/7616484
 * Seems fast and simple, for my current needs, I don't have collisions concerns
 * @return {number}
 */
String.prototype.hash = function () {
  let hash = 0;
  let i;
  let chr;
  if (this.length === 0) {
    return hash;
  }
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

/**
 * Python's rsplit
 * @param {string} sep separator string
 * @param {number} [maxSplit] how many times split
 * @returns {string[]}
 */
String.prototype.rsplit = function (sep, maxSplit) {
  const split = this.split(sep);
  if (split.length === 1) {
    return split;
  }
  return maxSplit ? [split.slice(0, -maxSplit).join(sep)].concat(split.slice(-maxSplit)) : split;
};


/**
 * First 5 symbols of guid, we use it often, so having a function makes sense
 * @param {string} prefix
 * @return {string}
 */
String.prototype.shorten = function (prefix = '') {
  let s = this.slice(0, 5);
  if (prefix) {
    s = `${prefix} ${s}`;
  }
  return s;
};

/**
 * Shorts a hex guid string to 13 symbols and uppercase them
 */
String.prototype.toShortGUID = function () {
  return this.slice(0, 13).toUpperCase();
};

window.path = {
  /**
   * Parsers current pathname and returns project uuid from it (url format is project/:uuid).
   * In many places we need to know id of project before making API call, unfortunately how we retrieve it is not
   * uniform, the current project can be stored in the vuex store and read from URL. Reading from URL is the most
   * reliable approach as it will be there before any component is mounted and start calling API as well as we don't
   * have to have reference $route or $store and instance of window is available everywhere.
   *
   * @returns {UUID} project id
   */
  get projectID() {
    const {pathname} = window.location;
    const tokens = pathname.split('/');
    const index = tokens.indexOf('projects') + 1;
    const uuid = tokens[index];
    if (!uuid) {
      throw new Error(`project uuid not found in ${pathname}`);
    }
    return uuid;
  },
};
