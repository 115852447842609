import Endpoint from '@/api/resources/base';
import urls from '@/api/urls';

export default class SLMOverview extends Endpoint {
  /**
   * List of statistics: devices/sessions/events count
   * @param {StartsAtQuerySerializer} params
   * @return {Promise<import('axios').AxiosResponse<OverviewStatisticSerializer>>}
   */
  list = async (params = {}) => {
    return this.api.get(urls.slm.overview.list, params);
  };
  /**
   * Events per day
   * @param {StartsAtQuerySerializer} params
   * @return {Promise<import('axios').AxiosResponse<AlertChartSerializer>>}
   */
  chart = async (params = {}) => {
    return this.api.get(urls.slm.overview.chart, params);
  };
}
