/**
 * Returns how many whole digits, fraction and total number of digits.
 * @param {number} value
 * @returns {number[]}
 */
export function digitsAfter(value) {
  const stringifyed = value.toString();
  const [whole, fraction = ''] = stringifyed.split('.');
  return [whole.length, fraction.length, stringifyed.length];
}

/**
 * Conditionally calls `toFixed` on the `value` if number's fraction number is higher than `maxFraction`.
 * @param {number | undefined | null} value
 * @param {number} [maxFraction=2]
 * @param {number} [precision=2]
 * @returns {string | undefined | null}
 */
export function maybeToFixed(value, maxFraction = 2, precision = 2) {
  if (!value) {
    return value;
  }
  const stringifyed = value.toString();
  const [, afterComma] = digitsAfter(value);
  if (afterComma > maxFraction) {
    return value.toFixed(precision);
  }
  return stringifyed;
}

/**
 * Conditionally calls `Number.toExponential` or `Number.toPrecision` based on the `value` length
 * @param {number} value
 * @param {number} precision
 * @returns {string}
 */
export function maybeToExponent(value, precision = 4) {
  if (value === 0 || !Number.isFinite(value)) {
    return '0';
  }
  if (value.toString().length < 5) {
    return value.toString();
  }
  return (value < 0.1) ? value.toExponential(3) : value.toPrecision(precision);
}

/**
 * Converts a number (in bytes) to a human-readable string
 * @link https://stackoverflow.com/a/18650828
 * @param {number} bytes
 * @param {number} decimals
 * @returns {string}
 */
export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) {
    return '0 Bytes';
  }
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
