/**
 * OLD, USE api/index.js
 */

import {apiUrl, requests} from '@/api/client';

/**
 * Uniforms various backend ways to report an error.
 * @param {import('axios').AxiosError<*>} error
 * @property {string} detail any generic error
 * @property {{[key in string]: string[]}} fields form related errors, form field name: error list
 * @property {number} status status code
 * @constructor
 */
export function APIError(error) {
  this.detail = '';
  this.fields = {};
  this.status = error.response?.status;

  const messages = [];
  // 4xx
  if (error.response) {
    if (typeof error.response.data === 'string') {
      messages.push(error.response.statusText);
    } else if (error.response.data) {
      const {detail = '', non_field_errors = [], ...rest} = error.response.data;
      if (Object.keys(rest).length > 0) {
        this.fields = rest;
      }
      // Can be if thrown from ViewSet
      if (Array.isArray(error.response.data)) {
        messages.push(...error.response.data);
      }
      if (detail.length > 0) {
        messages.push(detail);
      }
      if (non_field_errors.length > 0) {
        messages.push(...non_field_errors);
      }
    }
    // 5xx
  } else if (error.request) {
    messages.push('No response from server');
  } else {
    messages.push(error.message);
  }

  this.detail = messages.join('. ');
}

/**
 * Usually we are reporting errors via notification toast, this gives us ability to report everything in a single
 * string, a generic error, form field error.
 * @returns {string}
 */
APIError.prototype.toString = function APIErrorToString() {
  const messages = [];
  if (this.detail.length > 0) {
    messages.push(this.detail);
  }
  Object.entries(this.fields).forEach(([key, value]) => {
    const val = Array.isArray(value) ? value.join(', ') : value;
    messages.push(`${key}: ${val}`);
  });
  return messages.join('. ');
};

/**
 * @deprecated use api/index.js!
 * @param {string} method HTTP method
 * @param {string} url
 * @param {object} kwargs
 * @returns {Promise<*[]>}
 */
export async function requestAction(method, url, kwargs = {}) {
  let response = null;
  let error = null;
  let {ver = 1, headers = {}, ...rest} = kwargs;

  headers['Accept'] = `application/json; version=${ver}`;
  try {
    response = await requests.request({method, url, headers, ...rest});
    // Django routes all /api/... requests to DRF, anything else goes to root router, the router intercepts all requests
    // and uses FrontendView.
    // And so sometimes some API calls return html, can't catch how and when it happens exactly.
    // If API returns an empty response (to DELETE for example), content-type is not set.
    const ct = 'content-type';
    if (url !== apiUrl.planListHTML && ct in response.headers && !response.headers[ct].startsWith('application/json')) {
      error = new APIError({response, message: 'Incorrect Content-Type'});
      response = null;
      console.error(`[${method.toUpperCase()}] ${url} (${JSON.stringify(kwargs)}): Expected JSON got HTML.`);
    }
  } catch (e) {
    error = new APIError(e);
    const login = '/account/login/';
    if (error?.status === 401 && window.location.pathname !== login) {
      window.location.href = `${login}?next=${window.location.pathname}`;
    }
  }

  return [response, error];
}

/**
 * If it is {name: any, value: any}[] api sends ask names in array of all names and array of all values
 * here we turn the data back to name, value pairs
 */
export function rehydrate(keys, values, names = ['value']) {
  return keys.map((key, keyIdx) => ({
    name: key,
    ...names.reduce((pv, cv, nameIdx) => {
      let value = values[keyIdx];
      if (Array.isArray(value)) {
        value = value[nameIdx];
      }
      pv[cv] = value;
      return pv;
    }, {}),
  }));
}
