import {namedtuple} from '@/utils/array';

/**
 * Delays execution
 * Usage:
 *  await sleep(5);
 *  sleep(5).then(...);
 * @param {number} ms
 * @return {Promise<unknown>}
 */
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/** Port of strftime() by T. H. Doan (https://thdoan.github.io/strftime/)
 *
 * Day of year (%j) code based on Joe Orost's answer:
 * http://stackoverflow.com/questions/8619879/javascript-calculate-the-day-of-the-year-1-366
 *
 * Week number (%V) code based on Taco van den Broek's prototype:
 * http://techblog.procurios.nl/k/news/view/33796/14863/calculate-iso-8601-week-and-year-in-javascript.html
 *
 * @param {string} sFormat string format
 * @param {Date} [date] current date by default
 */
export function strftime(sFormat, date) {
  if (!(date instanceof Date)) {
    date = new Date();
  }
  const nDay = date.getDay(),
    nDate = date.getDate(),
    nMonth = date.getMonth(),
    nYear = date.getFullYear(),
    nHour = date.getHours(),
    aDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    aMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    aDayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334],
    isLeapYear = function () {
      return (nYear % 4 === 0 && nYear % 100 !== 0) || nYear % 400 === 0;
    },
    getThursday = function () {
      const target = new Date(date);
      target.setDate(nDate - ((nDay + 6) % 7) + 3);
      return target;
    },
    zeroPad = function (nNum, nPad) {
      return ((Math.pow(10, nPad) + nNum) + '').slice(1);
    };
  return sFormat.replace(/%[a-z]/gi, function (sMatch) {
    return (({
      '%a': aDays[nDay].slice(0, 3),
      '%A': aDays[nDay],
      '%b': aMonths[nMonth].slice(0, 3),
      '%B': aMonths[nMonth],
      '%c': date.toUTCString(),
      '%C': Math.floor(nYear / 100),
      '%d': zeroPad(nDate, 2),
      '%e': nDate,
      '%F': date.toISOString().slice(0, 10),
      '%G': getThursday().getFullYear(),
      '%g': (getThursday().getFullYear() + '').slice(2),
      '%H': zeroPad(nHour, 2),
      '%I': zeroPad((nHour + 11) % 12 + 1, 2),
      '%j': zeroPad(aDayCount[nMonth] + nDate + ((nMonth > 1 && isLeapYear()) ? 1 : 0), 3),
      '%k': nHour,
      '%l': (nHour + 11) % 12 + 1,
      '%m': zeroPad(nMonth + 1, 2),
      '%n': nMonth + 1,
      '%M': zeroPad(date.getMinutes(), 2),
      '%p': (nHour < 12) ? 'AM' : 'PM',
      '%P': (nHour < 12) ? 'am' : 'pm',
      '%s': Math.round(date.getTime() / 1000),
      '%S': zeroPad(date.getSeconds(), 2),
      '%u': nDay || 7,
      '%V': (function () {
        const target = getThursday(),
          n1stThu = target.valueOf();
        target.setMonth(0, 1);
        const nJan1 = target.getDay();
        if (nJan1 !== 4) {
          target.setMonth(0, 1 + ((4 - nJan1) + 7) % 7);
        }
        return zeroPad(1 + Math.ceil((n1stThu - target) / 604800000), 2);
      })(),
      '%w': nDay,
      '%x': date.toLocaleDateString(),
      '%X': date.toLocaleTimeString(),
      '%y': (nYear + '').slice(2),
      '%Y': nYear,
      '%z': date.toTimeString().replace(/.+GMT([+-]\d+).+/, '$1'),
      '%Z': date.toTimeString().replace(/.+\((.+?)\)$/, '$1'),
      '%f': date.getMilliseconds(),
    }[sMatch] || '') + '') || sMatch;
  });
}

/**
 * Returns difference between two dates in several formats as namedtuple
 *
 * @typedef {Array} Elapsed
 * @property {number} milliseconds
 * @property {number} seconds
 * @property {number} minutes
 * @property {number} hours
 * @property {number} days
 * @property {number} month
 * @property {number} year
 *
 * @param {Date} a
 * @param {Date} b
 * @returns {Elapsed}
 */
export function elapsed(a, b) {
  const Delta = namedtuple('milliseconds', 'seconds', 'minutes', 'hours', 'days', 'month', 'year');

  const MS_PER_SECOND = 1000;
  const MS_PER_MINUTE = 60 * 1000;
  const MS_PER_HOUR = MS_PER_MINUTE * 60;
  const MS_PER_DAY = MS_PER_HOUR * 24;
  const MS_PER_MONTH = MS_PER_DAY * 30;
  const MS_PER_YEAR = MS_PER_DAY * 365;

  const elapsed = a - b;
  const divider = [1, MS_PER_SECOND, MS_PER_MINUTE, MS_PER_HOUR, MS_PER_DAY, MS_PER_MONTH, MS_PER_YEAR];
  return Delta(divider.map((div) => Math.round(elapsed / div) || 0));
}

/**
 * Calculates how much time has passed since the provided datetime..
 * Formats: {Number}s, m, h, d, months, years
 * @param {Date} datetime
 * @returns {string}
 */
export function timeFromNow(datetime) {
  const passed = elapsed(new Date(), datetime).reverse();
  const suffix = ['years', 'months', 'd', 'h', 'm', 's'];
  const idx = passed.findIndex((v) => v > 0);
  return `${passed[idx]}${suffix[idx]}`;
}

/**
 * Clears timeout, if present, and returns null
 * Example:
 *  timeout = setTimeout(...);
 *  timeout = unsetTimeout(timeout);
 *  timeout = unsetTimeout(timeout);
 * @param id
 * @return {null}
 */
export function unsetTimeout(id) {
  id && clearTimeout(id);
  return null;
}
