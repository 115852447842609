import {refreeze} from '@/utils/object';
import api from '@/api';

/**
 * @typedef SessionState
 * @property {Record<UUID, DeviceSessionSerializer>} sessions
 * @property {Record<UUID, SLMSamplesSerializer>} samples
 * @property {Record<UUID, string[]>} metrics
 * @property {{samples: boolean}} loading
 */

export default {
  namespaced: true,
  /**
   * @return {SessionState}
   */
  state: () => ({
    sessions: {},
    samples: {},
    metrics: {},
    loading: {
      samples: false,
    },
    isFetchSamplesFail: false,
  }),
  mutations: {
    /**
     * @param state
     * @param {'samples'} key
     * @param {boolean} value
     */
    setLoading(state, {key, value}) {
      state.loading[key] = value;
    },
    /**
     * @param state
     * @param {UUID} sessionId
     * @param {string} y
     * @param {SLMSamplesSerializer} samples
     * @param {boolean} isInit
     */
    setSamples(state, {sessionId, y, samples, isInit}) {
      const {names, values} = samples;
      if (isInit) {
        if (state.samples[sessionId] === undefined) {
          this._vm.$set(state.samples, sessionId, {});
        }
        this._vm.$set(state.samples[sessionId], y, {names, values: []});
      }
      if (values.length) {
        state.samples[sessionId][y].values.push(...values);
      }
    },
    /**
     * @param state
     * @param {DeviceSessionSerializer} session
     */
    setSession(state, session) {
      this._vm.$set(state.sessions, session.guid, session);
    },
    setMetrics(state, {sessionId, metrics}) {
      this._vm.$set(state.metrics, sessionId, metrics);
    },
    delSession(state, sessionId) {
      this._vm.$delete(state.sessions, sessionId);
    }
  },
  actions: {
    /**
     * Similar to `fetchAlertSamples` but fetches metrics for a session
     * @param state
     * @param commit
     * @param {UUID} sessionId
     * @param {string} y metric to fetch
     * @returns {Promise<boolean>} true if samples array got an update (not initial insert)
     */
    async fetchSamples({state, commit}, {sessionId, y}) {
      const samples = state.samples[sessionId];
      const isInit = !samples || samples[y] === undefined;

      if (isInit && !state.isFetchSamplesFail) {
        // set the loading flag only if no metrics present and previous request completed successfully
        commit('setLoading', {key: 'samples', value: true});
      }

      let after = undefined;
      if (!isInit) {
        const lastSample = samples[y].values[samples[y].values.length - 1];
        after = lastSample ? lastSample[0] : undefined;
      }

      try {
        const response = await api.slm.session.samples(sessionId, after, y);
        commit('setSamples', {sessionId, y, samples: refreeze(response.data), isInit});
        state.isFetchSamplesFail = false;
        return isInit ? false : response.data.values.length > 0;
      } catch (e) {
        // Set isFetchSamplesFail to prevent metrics tab reloading loop.
        state.isFetchSamplesFail = true;
        console.error(e);
      } finally {
        commit('setLoading', {key: 'samples', value: false});
      }
    },
    /**
     * Returns from cache or fetches (and caches it) debug session info by session id
     * @param state
     * @param commit
     * @param {UUID} sessionId
     * @return {Promise<DeviceSessionSerializer>}
     */
    async fetchSession({state, commit}, sessionId) {
      if (state.sessions[sessionId] === undefined) {
        try {
          const response = await api.slm.session.detail(sessionId);
          commit('setSession', response.data);
        } catch (e) {
          console.error(e);
        }
      }
      return state.sessions[sessionId];
    },
    /**
     * Fetches or takes the list of metrics from cache and returns it.
     * @param state
     * @param commit
     * @param {UUID} sessionId
     * @returns {Promise<string[]>}
     */
    async fetchMetrics({state, commit}, sessionId) {
      if (state.metrics[sessionId] === undefined) {
        try {
          const response = await api.slm.session.metrics(sessionId);
          commit('setMetrics', {sessionId, metrics: response.data});
        } catch (e) {
          console.error(e);
        }
      }
      return state.metrics[sessionId];
    },
    async deleteSession({commit}, sessionId) {
      try {
        await api.slm.session.destroy(sessionId);
        commit('delSession', sessionId);
      } catch (e) {
        console.error(e);
      }
    }
  }
};
