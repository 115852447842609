import Endpoint from '@/api/resources/base';
import urls from '@/api/urls';

export default class SLMAlert extends Endpoint {
  /**
   * List of alerts
   * @param {number} offset https://www.django-rest-framework.org/api-guide/pagination/#limitoffsetpagination
   * @param {number | null} limit items per page, all items if limit is null, RESPONSE WILL BE NON PAGINATED
   * @param {AlertQuerySerializer} params
   * @return {Promise<import('axios').AxiosResponse<(Paginated<AlertSerializer[]> | AlertSerializer[])>>}
   */
  list = async (params, offset = 0, limit = 20) => {
    const data = {...params, offset, limit};
    return this.api.get(urls.slm.alert.list, data);
  };

  /**
   * Single alert
   * @param {number | string} alertId
   * @returns {Promise<import('axios').AxiosResponse<AlertSerializer>>}
   */
  detail = async (alertId) => {
    return this.api.get(urls.slm.alert.detail.format({alertId}));
  };

  /**
   * Single alert samples
   * @param {number} alertId
   * @param {DateTime} [after]
   * @param {string} [metric]
   * @returns {Promise<import('axios').AxiosResponse<SLMSamplesSerializer>>}
   */
  samples = async (alertId, after, metric) => {
    const url = urls.slm.alert.samples.format({alertId});
    return this.api.get(url, {after, metric});
  };

  /**
   * Stats of a metric
   * @param {UUID} alertId
   * @param {string} [metric]
   * @returns {Promise<import('axios').AxiosResponse<SLMStatisticsSerializer>>}
   */
  statistics = async (alertId, metric) => {
    const url = urls.slm.alert.statistics.format({alertId});
    return this.api.get(url, {metric});
  };

  /**
   * Retrieve related projects
   * @param {number} alertId id of alert
   * @returns {Promise<import('axios').AxiosResponse<RelatedProjectsSerializer[]>>}
   */
  relatedProjects = async (alertId) => {
    const url = urls.slm.alert.relatedProjects.format({alertId});
    return this.api.get(url);
  };

  /**
   * Retrieve related events
   * @param {number} alertId id of alert
   * @returns {Promise<import('axios').AxiosResponse<AlertSerializer[]>>}
   */
  relatedEvents = async (alertId) => {
    const url = urls.slm.alert.relatedEvents.format({alertId});
    return this.api.get(url);
  };

  /**
   * Retrieve related events with self object. ID and ends_at fields only.
   * @param {number} alertId id of alert
   * @returns {Promise<import('axios').AxiosResponse<TrackRelatedEventsSerializer[]>>}
   */
  trackRelatedEvents = async (alertId) => {
    const url = urls.slm.alert.trackRelatedEvents.format({alertId});
    return this.api.get(url);
  };

  /**
   * Retrieve layout of sensors of a device where the alert was triggered
   * @param {number} alertId
   * @returns {Promise<import('axios').AxiosResponse<SensorLayoutSerializer[]>>}
   */
  sensorLayout = async (alertId) => {
    const url = urls.slm.alert.sensorLayout.format({alertId});
    return this.api.get(url);
  }
  /**
   * Delete alert
   * @param {number} alertId
   * @returns {Promise<import('axios').AxiosResponse>}
   */
  destroy = async (alertId) => {
    const url = urls.slm.alert.detail.format({alertId});
    return this.api.delete(url);
  };
  /**
   * Bulk archive events
   * @param {{events: Number[]}} data
   * @returns {Promise<import('axios').AxiosResponse>}
   */
  bulkArchive = async (data) => {
    const url = urls.slm.alert.bulkArchive;
    return this.api.post(url, data);
  };
  /**
   * Fetch max and min starts_at date bounds.
   * @returns {Promise<import('axios').AxiosResponse<SlmDateRangeSerializer>>}
   */
  fetchBounds = async () => {
    const url = urls.slm.alert.dateRange;
    return this.api.get(url);
  };

  metrics = async (alertId) => {
    return this.api.get(urls.slm.alert.metrics.format({alertId}));
  }
}
