import {refreeze} from '@/utils/object';

export const LONG_POLL_INTERVAL = 10000; // for data with rare updates
export const DEFAULT_POLL_INTERVAL = 10000; // for "heavy calls"
export const SHORT_POLL_INTERVAL = 10000; // for not so heavy
export const MANAGER_CALL = 'optimizer-ctl init --stages=:stages && optimizer-ctl start';

// FIXME: rename to "DATASET" or use "Stages" UI uses it instead of dataset, dataset and stage have different
//    meaning although same names.
export const STAGES = {
  READINESS: 'readiness',
  OPTIMIZATION: 'optimization',
  REFINEMENT: 'refinement',
  VALIDATION: 'validation',
};

export const BEST_CONFIG_VALUE = 't.metric';

export const NUM_OF_NON_BASELINE_KNOBS = '# of non-baseline knobs';

export const CURRENCY_SYMBOLS = {
  USD: '$',
};

export const ICON_SIZES_BY_QUANTITY = {
  1: {maxWidth: '180px', maxHeight: '80px'},
  2: {maxWidth: '90px', maxHeight: '60px'},
  3: {maxWidth: '75px', maxHeight: '55px'},
  4: {maxWidth: '65px', maxHeight: '35px'},
};

export const FILTER_TYPE = {
  SPECIFIC: 'equal to',
  STRING: 'any of',
  RANGE: 'range',
};

export const HINT_NAMES = {
  toWizard: 'hint-to-wizard',
  createProject: 'project-creation-hint',
  scratchTooltip: 'scratch-tooltip',
  wizardTooltip: 'wizard-tooltip',
  cloneTooltip: 'clone-tooltip',
  comparison: 'comparison-hint',
  discover: 'discover-hint',
  createReport: 'report-creation-hint',
  eventSearch: 'event-search-hint',
};

export const FILTER_TYPE_DEFAULT_VALUES = {
  [FILTER_TYPE.SPECIFIC]: '',
  [FILTER_TYPE.STRING]: [],
  [FILTER_TYPE.RANGE]: {from: null, to: null},
};

export const States = {
  ready: 1,
  running: 2,
  paused: 3,
  failed: 4,
  done: 5,
  1: 'ready',
  2: 'running',
  3: 'paused',
  4: 'failed',
  5: 'done',

  get recent() {
    return [this.paused, this.failed, this.done];
  },

  get active() {
    return [this.ready, this.running];
  }
};

export const Stages = {
  readiness: 1,
  optimization: 2,
  refinement: 3,
  validation: 4,
  done: 5,
  idle: 6,
  1: 'readiness',
  2: 'optimization',
  3: 'refinement',
  4: 'validation',
  5: 'done',
  6: 'idle',
};

export const CONFIG_STATE = {
  INVALID: 'invalid',
  NON_CONVERGING: 'non-converging',
  ACTIVE: 'active',
  COMPLETED: 'completed',
  DISABLED: 'disabled',
  UNKNOWN: 'unknown',
};

// root events
export const EVENTS = {
  CONFIGURATION_SELECTED: 'point-selected',
  SHOW_ONBOARDING: 'show-onboarding',
  SCATTER_POP_POINTS: 'scatter-pop-point',
  FORCE_OPEN_EXPERIMENT_REFRESH: 'force-open-experiment-refresh',
  // select a point on scatter plot, args: point's configuration id
  SELECT_POINT: 'select-point',
  // highlights a point on scatter plot, args: point's configuration id or null to remove highlight
  HIGHLIGHT_POINT: 'highlight-point',
  HIGHLIGHT_POINTS: 'highlight-points',
  MARK_AREA: 'mark-area',
  SHOW_PROJECT_TRANSFER_OWNERSHIP_MODAL: 'show-project-transfer-ownership-modal',
  SHOW_REPORT_TRANSFER_OWNERSHIP_MODAL: 'show-report-transfer-ownership-modal',
  SHOW_INFO_MODAL: 'show-info-modal',
  SHOW_T_TEST_MODAL: 'show-t-test-modal',
  SHOW_EXP_INVENTORY: 'show-exp-inventory',
  SHOW_ZOOM_IN_MODAL: 'show-zoomin-modal',
  SHOW_EXPERIMENT_SELECT_MODAL: 'show-experiment-select-modal',
  SHOW_EVENT_SELECT_MODAL: 'show-event-select-modal',
  SHOW_CONFIGURATION_SELECT_MODAL: 'show-configuration-select-modal',
  SHOW_SCATTER_OVERLAY: 'show-scatter-overlay',
  SHOW_SESSION_INVENTORY: 'show-session-inventory',
  SHOW_UPLOAD_CHIP_IMAGE_MODAL: 'show-upload-chip-image-modal',
  SLM_ALERT_HOVERED: 'slm-alert-hovered',
};

export const MSG_TYPES = {
  COPY: 'copy',
  RELOAD: 'reload',
};

export const ROLES = {
  VIEWER: 'viewer',
  EDITOR: 'editor',
  MANAGER: 'manager',
  OWNER: 'owner',
};

export const ALEVEL = {
  NOBODY: 0,
  PROJECT_VIEWER: 1,
  PROJECT_EDITOR: 2,
  PROJECT_MANAGER: 3,
  PROJECT_OWNER: 4,
  ACCOUNT_OWNER: 5,

  /**
   * @param {ProjectSerializer | ReportSerializer | {access_level: number}} project
   * @param {number} level
   * @returns {boolean}
   */
  hasPermission(project, level) {
    return project.access_level >= level;
  },
};

// Some metrics are always exist in some context
export const METRICS = {
  TIMESTAMP: 'timestamp',
  CREATED_BY: 'created_by',
  INDEX: 'index',
};
export const SCATTER_Y_EXCLUDE = [METRICS.INDEX, METRICS.TIMESTAMP, METRICS.CREATED_BY, NUM_OF_NON_BASELINE_KNOBS];
export const SCATTER_X_DEFAULT = METRICS.TIMESTAMP;

// Target metric goals
export const GOAL = {
  MIN: 'min',
  MAX: 'max',
  get stringOptions() {
    return refreeze([{text: 'Min', value: GOAL.MIN}, {text: 'Max', value: GOAL.MAX}]);
  },
  get numberOptions() {
    return refreeze([{text: 'Min', value: 0}, {text: 'Max', value: 1}]);
  },
  get helpText() {
    return 'Select the metric minimum or maximum tuned value for each experiment';
  }
};


/**
 * @readonly
 * @enum {ReportEntryType}
 */
export const REPORT_ENTRY_TYPE = {
  PROJECT: 'PROJECT',
  SESSION: 'SESSION',
};

/**
 * @readonly
 * @enum {AlertKindType}
 */
export const ALERT_KIND_TYPE = {
  PROMETHEUS: 1,
  OPTIMIZER: 2,
};

// These metrics cannot have goal
export const GOAL_EXCLUDE = [METRICS.TIMESTAMP, METRICS.CREATED_BY, METRICS.INDEX];

export const DEFAULT_PAGE_LIMIT = 10;
