import urls from '@/api/urls';
import Endpoint from '@/api/resources/base';

export default class Sample extends Endpoint {
  /**
   * Conduct Welch's T-Test
   * @param {UUID} project
   * @param {UUID} experiment
   * @param {number} confA id of configuration a
   * @param {number} confB id of configuration b
   * @param {string} metric name of metric
   * @returns {Promise<import('axios').AxiosResponse<TTestResponse>>}
   */
  tTest = async (project, experiment, confA, confB, metric) => {
    const url = urls.project.experiment.sample.tTest.format({project, experiment});
    return this.api.get(url, {a_conf: confA, b_conf: confB, metric});
  };

  /**
   * @param {UUID} experiment
   * @param {number} [offset]
   * @returns {Promise<SampleGraphValues[]>}
   */
  stream = async (experiment, offset) => {
    const uri = urls.v2.experiment.sample.stream.format({experiment});
    return this.api.fetchJSONL({
      uri,
      init: {
        method: 'GET',
        signal: this.addCtrl('stream').signal,
      },
      offset,
    });
  };

  /**
   * @param {UUID} project
   * @param {UUID} experiment
   * @return {Promise<*>}
   */
  options = async (project, experiment) => {
    const url = urls.project.experiment.sample.list.format({project, experiment});
    return this.api.options(url);
  };
}
