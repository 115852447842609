/**
 * Nomenclature:
 *  Preset: each experiment may have one or multiple filter presets. Every preset is defined by its label and body.
 *          Preset body is an array, each element defines a filter for each knob, each array element contain knob name
 *          and list of the knob constraints.
 *
 *  Constraint: defines `equal`, `contains` or `between` constraint. For example if knob A has constraint `equal` and
 *              value is `2`, on graph samples only with knob set to 2 will be shown.
 */

import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import {UUIDv4} from '@/utils/text';
import {apiUrl} from '@/api/client';
import {requestAction} from '@/api/helpers';

/**
 * @typedef {Object} Constraint
 * @property {String} key: an unique (per knob) identifier, created when adding a new knob filter or attaching knob filters
 *                    from a saved preset. Required here - FilterItem, otherwise vue does not understand what to render
 *                    when removing a knob filter
 * @property {Boolean} enabled: indicates apply the constraint or not
 */

/**
 * @typedef {Object & Constraint} ConstraintSpecific
 * @property {'equal to'} type
 * @property {String} value
 */

/**
 * @typedef {Object & Constraint} ConstraintAny
 * @property {'any of'} type
 * @property {String[]} value
 */

/**
 * @typedef {Object & Constraint} ConstraintRange
 * @property {'range'} type
 * @property {{from: Number, to: Number}} value
 */

/**
 * @typedef {(ConstraintAny|ConstraintRange|ConstraintSpecific)} Constraint
 */

/**
 * @typedef {Object} ConstraintLocator
 * @property {String} name: knob name
 * @property {Constraint} value
 */

/**
 * @typedef {Object} PresetItem  (aka snapshot)
 * @property {String} name: knob name
 * @property {Constraint[]} value
 */

/**
 * @typedef {Object} Preset
 * @property {Number} id
 * @property {String} label: snapshot name
 * @property {PresetItem[]} body, although it's an array of knob_name: filter_data, each array element _should_ represent a
 *                      unique knob
 */

/**
 * @typedef {Object} Knob
 * @property {String} name
 * @property {String[]} options
 * @property {String} description
 * @property {String} baseline_value
 * @property {{max: Number, min: Number, step: Number, num_options: Number}|undefined} range
 */

/**
 * @typedef {Object} __Filter
 * @property {PresetItem} filter
 *
 * @typedef {Knob & __Filter} KnobFilter
 */

/**
 * @typedef Cached
 * @property {Preset} active
 * @property {KnobFilter[]} knobs
 */

/**
 * @typedef {Object} FiltersState
 * @property {Record<String, Preset[]>} presets
 * @property {Preset} active
 * @property {KnobFilter[]} knobs
 * @property {String} experiment
 * @property {String} project
 * @property {Record<String, Cached>} cached
 */

const DEFAULT_FILTER = () => ({id: null, label: '', body: []});

export default {
  namespaced: true,
  /**
   * @returns {FiltersState}
   */
  state: () => ({
    // experiment uuid: all available filters
    presets: {},
    // holds copy of preset user works on, on save will be sent to backend
    active: DEFAULT_FILTER(),
    knobs: [],
    cached: {},
    // current active experiment uuid
    experiment: null,
    // current active project uuid
    project: null,
  }),

  mutations: {
    /**
     * Every time user opens an experiment this should be called. Otherwise the module won't work
     * @param {FiltersState} state
     * @param {String} project
     * @param {String} experiment
     */
    init(state, {project, experiment}) {
      if (state.experiment === experiment && state.project === project) {
        // already initialized for this experiment
        return;
      }
      state.experiment = experiment;
      state.project = project;
      if (!state.presets[experiment]) {
        this._vm.$set(state.presets, experiment, [DEFAULT_FILTER()]);
      }
    },

    /**
     * Sets a filter preset (payload) as active for the selected experiment uuid
     * If payload contains only id, func will try to find a filter in presets and clone it
     * @param {FiltersState} state
     * @param {Preset|{id: Number}} payload
     */
    setActive(state, payload) {
      // clone in case if payload is part of `state.presets`, not sure how vuex handles
      // but we have nested mutable objects there
      if (!payload.body && payload.id) {
        payload = state.presets[state.experiment].find((preset) => preset.id === payload.id);
      }
      state.active = cloneDeep(payload);
    },

    resetActive(state) {
      state.active = DEFAULT_FILTER();
    },

    /**
     * Adds constraint to an exist knob preset or creates new one in `active.body`
     * @param {FiltersState} state
     * @param {ConstraintLocator} payload
     */
    addActiveConstraint(state, payload) {
      const idx = findIndex(state.active.body, ({name}) => name === payload.name);
      payload.value.key = payload.value.key || UUIDv4();
      payload.value.enabled = payload.value.enabled || false;
      if (idx > -1) {
        state.active.body[idx].value.push(payload.value);
      } else {
        state.active.body.push({name: payload.name, value: [payload.value]});
      }
    },

    /**
     * Removes a knob constraint
     * @param {FiltersState} state
     * @param {ConstraintLocator} payload
     */
    removeActiveConstraint(state, payload) {
      // assign it to active.body, for vue easier to observe root changes
      state.active.body = state.active.body.map((preset) => {
        if (preset.name === payload.name) {
          // actual constraint remove part
          preset.value = preset.value.filter(({key}) => payload.value.key !== key);
        }
        return preset;
        // check for presets without any constraints and remove found
      }).filter((preset) => {
        return preset.value.length > 0;
      });
    },

    /**
     * For this part it is crucial for each constraint to have unique key at least per knob, other solution
     * will be use array indexes but each constraint can be added and removed and it hard to keep these indexes unique
     * during app life time
     * @param {FiltersState} state
     * @param {ConstraintLocator} payload
     */
    changeActiveConstraint(state, payload) {
      state.active.body = state.active.body.map((preset) => {
        if (preset.name !== payload.name) {
          return preset;
        }
        preset.value = preset.value.map((constraint) => {
          if (constraint.key === payload.value.key) {
            return payload.value;
          }
          return constraint;
        });
        return preset;
      });
    },

    /**
     * Pushes multiple filter presets
     * @param {FiltersState} state
     * @param {Preset[]} payload
     */
    addPresets(state, payload) {
      payload.forEach((value) => {
        // in db some body filters contain invalid body data, this is a quick workaround
        try {
          value.body.forEach((item) => {
            item.value = item.value.map((element) => {
              element.key = UUIDv4();
              return element;
            });
          });
        } catch (e) {
          value.body = [];
        }
      });
      state.presets[state.experiment].push(...payload);
    },

    /**
     * Saves sorted list of knobs, later will be used in getter where the state.knobs will be merged with state.presets
     * to render final list of filters
     * It's done in such way because we need get list of knobs only once and sort it only once
     * This mutation should be called after `state.active` is available
     * @param {FiltersState} state
     * @param {Boolean} fromState, if true -> will populate state.knobs array with filters from state.active and sort it
     */
    insertKnobs(state, fromState = false) {
      let knobs = fromState ? state.knobs : this._modules.root.state.experiment.metadata[state.experiment]?.knobs;
      if (!knobs) {
        return;
      }
      knobs = cloneDeep(knobs).map((knob) => {
        // attach exist filter from preset or empty one as template
        // here we have a duplication, knob.name in body[*].name and body[*].value.name
        knob.filter = state.active.body.find((filter) => filter.name === knob.name) || {name: knob.name, value: []};
        return knob;
      });
      knobs = knobs.sort((a, b) => {
        // item with filter has higher priority
        const aHasFilters = a.filter.value.length > 0;
        const bHasFilters = b.filter.value.length > 0;
        if (aHasFilters && !bHasFilters) {
          return -1;
        }
        if (!aHasFilters && bHasFilters) {
          return 1;
        }
        // this part can be reached only if both items have or do not have a filter
        // sorting is equal for both cases
        if (a.name > b.name) {
          return -1;
        }
        if (a.name < b.name) {
          return 1;
        }
        return 0;
      });
      state.knobs = knobs;
    },

    /**
     * Looks for a filter preset in the experiment's filters and if found replaces it with payload
     * Unnecessary because state.active reflects what we be saved but to avoid state out of sync checks
     * if the `payload.id` matches `active.id` and if so copies `payload` into `active`
     * @param {FiltersState} state
     * @param {Preset} payload
     */
    changePreset(state, payload) {
      state.presets[state.experiment] = state.presets[state.experiment].map((el) => {
        if (el.id === payload.id) {
          return {...el, ...payload};
        }
        return el;
      });
      if (payload.id === state.active.id) {
        state.active = cloneDeep(payload);
      }
    },

    /**
     * Removes a filter preset from the experiment filters, payload can be any object with `id` property
     * If the filter is also active resets it
     * @param {FiltersState} state
     * @param {{id: Number}} payload
     */
    removePreset(state, payload) {
      state.presets[state.experiment] = state.presets[state.experiment].filter((filter) => filter.id !== payload.id);
      if (state.active.id === payload.id) {
        state.active = DEFAULT_FILTER();
      }
    },

    /**
     * This and `fromCache` are last minute solution. the `active` should be stored per experiment just like
     * `presets` and the `knobs` too. I don't see, for now, any big downsides to this approach, filter presets are
     * small and having no extra nesting (for any data for the current open experiment we can access `active` and not
     * active[experiment], this facilitates work for us and for vue as well)
     *
     * Reason why it does exist - user can apply a filter, close experiment, reopen it but plot will remain filtered
     * because in the sample.js you can find that xy coordinates are cached and don't reevaluate on the experiment
     * close/open
     * @param {FiltersState} state
     */
    toCache(state) {
      state.cached[state.experiment] = {active: state.active, knobs: state.knobs};
    },

    fromCache(state) {
      if (state.cached[state.experiment]?.active) {
        state.active = state.cached[state.experiment].active;
      }
      if (state.cached[state.experiment]?.knobs) {
        state.knobs = state.cached[state.experiment].knobs;
      }
    },
  },

  actions: {
    async getList({state, commit}) {
      // getList used only once when filter component mounts so if data already preset in store skip this action
      // > 1 because default filter is always available
      if (state.presets[state.experiment].length > 1) {
        return;
      }
      const ctx = {projectId: state.project, experimentId: state.experiment};
      const url = apiUrl.experimentFilters.format(ctx);
      const [response] = await requestAction('get', url);
      if (response) {
        commit('addPresets', response.data);
      }
    },

    /**
     * Calls PUT replacing an existing filter, in success replaces it locally too by committing "change"
     * @param {FiltersState} state
     * @param commit
     * @param {Preset} payload
     * @returns {Promise<void>}
     */
    async put({state, commit}, payload) {
      const ctx = {projectId: state.project, experimentId: state.experiment, filterId: state.active.id};
      const url = apiUrl.experimentFilter.format(ctx);
      const [response] = await requestAction('put', url, {data: payload});
      if (response) {
        commit('changePreset', response.data);
        commit('insertKnobs', true);
      }
    },

    /**
     * Deletes a filter and calls local filter remove
     * @param {FiltersState} state
     * @param commit
     * @param {{id: Number}} payload
     * @returns {Promise<void>}
     */
    async delete({state, commit}, payload) {
      const ctx = {projectId: state.project, experimentId: state.experiment, filterId: payload.id};
      const url = apiUrl.experimentFilter.format(ctx);
      await requestAction('delete', url);
      commit('removePreset', payload);
    },

    /**
     * Creates a new filter, on success adds created filter to local store and sets it as active
     * @param {FiltersState} state
     * @param commit
     * @param {Preset} payload
     * @returns {Promise<void>}
     */
    async post({state, commit}, payload) {
      const ctx = {projectId: state.project, experimentId: state.experiment};
      const url = apiUrl.experimentFilters.format(ctx);
      const [response] = await requestAction('post', url, {data: payload});
      if (response) {
        commit('addPresets', [response.data]);
        commit('setActive', cloneDeep(response.data));
        commit('insertKnobs', true);
      }
    },
  },

  getters: {
    /**
     * Combines knobs from selected experiment with filter preset
     * knobs with presets puts on top
     * @param {FiltersState} state
     * @return {KnobFilter[]}
     */
    knobs(state) {
      return state.knobs.map((knob) => {
        return {
          ...knob,
          filter: state.active.body.find((filter) => filter.name === knob.name) || {name: knob.name, value: []},
        };
      });
    },

    /**
     * Saved filters for current experiment
     * @param {FiltersState} state
     * @return {Preset[]}
     */
    list(state) {
      return state.presets[state.experiment];
    },

    /**
     * Returns only enabled constraints, if a preset has all constraints disabled such preset will be removed
     * @param {FiltersState} state
     * @return {Preset}
     */
    preset(state) {
      /** @type {Preset} **/
      const active = cloneDeep(state.active);
      active.body = active.body.map((preset) => {
        preset.value = preset.value.filter((constraint) => constraint.enabled);
        return preset;
      }).filter((preset) => {
        return preset.value.length > 0;
      });
      return active;
    },
  },
};
