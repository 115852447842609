import moment from 'moment-timezone';
import isObject from 'lodash/isObject';
import {maybeToExponent, maybeToFixed} from '@/utils/number';
import {validationSeriesFactory} from '@/utils/echarts/series';
import {TTest} from '@/utils/ttest';
import {cycle} from '@/utils/array';
import {METRICS, Stages, CONFIG_STATE} from '@/constants';
import {SERIES_NAMES} from '@/utils/echarts/series/constants';
import {B_GAP} from '@/utils/echarts/constants';

const pointComparators = Object.freeze({
  topRight: (a, b) => (b[0] < a[0] ? -1 : (b[0] > a[0] ? 1 : (b[1] < a[1] ? -1 : (b[1] > a[1] ? 1 : 0)))),
  topLeft: (a, b) => (a[0] < b[0] ? -1 : (a[0] > b[0] ? 1 : (a[1] < b[1] ? 1 : (a[1] > b[1] ? -1 : 0)))),
  bottomRight: (a, b) => (b[0] < a[0] ? -1 : (b[0] > a[0] ? 1 : (b[1] < a[1] ? 1 : (b[1] > a[1] ? -1 : 0)))),
  bottomLeft: (a, b) => (a[0] < b[0] ? -1 : (a[0] > b[0] ? 1 : (a[1] < b[1] ? -1 : (a[1] > b[1] ? 1 : 0)))),
});


/**
 * Divide array of points to 4 basic series groups: Baseline, Tuned, Pareto and BaselinePareto
 * @param {import('../store/modules/samples').Point[]} points
 * @param {Object} optimize
 * @param {Number|null} configId, optional 'best configuration' id used to identify best configuration point
 *
 * Get groups using interface method groups(), usage example:
 * > const groups = new PointsDivider(...).groups();
 */
export function PointsDivider(points, optimize, configId = null) {
  this.tuned = [];
  this.optimization = [];
  this.refinement = [];
  this.baseline = [];
  this.target = [];
  this.targetBaseline = [];
  this.canHavePareto = [];
  this.position = ['bottomLeft', 'bottomRight', 'topLeft', 'topRight'][parseInt(`${optimize.y}${optimize.x}`, 2)];

  /**
   * @description Put point into a stage bin (optimization, refinement, idle, baseline)
   * @param {import('../store/modules/samples').Point} point
   */
  const putIntoBin = (point) => {
    if (point.sample.is_baseline) {
      this.baseline.push(point);
    } else if ([Stages.optimization, Stages.idle].includes(point.sample.stage)) {
      this.optimization.push(point);
    } else if (point.sample.stage === Stages.refinement) {
      this.refinement.push(point);
    }
  };

  points.forEach((p) => {
    const stateIsValid = [
      CONFIG_STATE.COMPLETED,
      CONFIG_STATE.UNKNOWN,
      CONFIG_STATE.ACTIVE,
    ].includes(p.sample.state);
    const valueIsValid = typeof p.x === 'number' && typeof p.y === 'number';
    const valueIsBest = p.sample.configuration_id === configId;
    if (valueIsBest || (stateIsValid && valueIsValid)) {
      this.canHavePareto.push(p);
    } else {
      putIntoBin(p);
    }
  });

  /**
   * https://github.com/justinormont/pareto-frontier
   * Y-axis min max 0 1
   * X-axis min max 0 1
   * Y0 bottom Y1 top
   * X0 left X1 right
   * 0 + 0 = bottomLeft = 0
   * 0 + 1 = bottomRight = 1
   * 1 + 0 = topLeft = 2
   * 1 + 1 = topRight = 3
   **/
  this._calculatePareto = () => {
    const pointComparator = pointComparators[this.position];
    const findMax = (pointComparator([0, 1], [0, 0]) < 0); // Optimize +y
    let last;

    this.canHavePareto.sort(pointComparator).forEach((p, i) => {
      if (i === 0 || findMax && p.y > last || !findMax && p.y < last) {
        last = p.y;
        if (p.sample.is_baseline) {
          this.targetBaseline.push(p);
          this.baseline.push(p);
        } else {
          this.target.push(p);
        }
      } else {
        putIntoBin(p);
      }
    });
  };

  this.groups = () => {
    this._calculatePareto();
    return [
      this.optimization,
      this.refinement,
      this.baseline,
      this.target,
      [...this.target, ...this.targetBaseline].sort((a, b) => a[0] - b[0]),
    ];
  };

  // TODO: remove this, return Object from groups() instead. To do so, you'll need to change all usages of
  //  `this.groups()` in the codebase: `Scatter.vue:methods.renderPlot`, `ExperimentContentBody.vue:computed.categories`
  // The key name is also legend name, but don't confuse with series name, a single legend can have multiple series,
  // for example 'Baseline' legend can have 'active', 'completed' and 'non-converging' series.
  this.sequence = [
    SERIES_NAMES.OPTIMIZATION,
    SERIES_NAMES.REFINEMENT,
    SERIES_NAMES.BASELINE,
    SERIES_NAMES.FRONTIER,
    SERIES_NAMES.PARETO_LINE,
  ];
}

/**
 * Echarts axis types
 * @type {{LOG: string, CATEGORY: string, TIME: string, VALUE: string}}
 */
export const AXIS_TYPES = {
  TIME: 'time',
  VALUE: 'value',
  CATEGORY: 'category',
  LOG: 'log',
};

/**
 * @typedef AxisMeta
 * @property {string} type
 * @property {string[]} boundaryGap
 * @property {function(arg0: number): number} [formatter]
 * @property {function(arg0: number): *} display
 */

export const AXIS_META = {
  [AXIS_TYPES.TIME]: {
    type: AXIS_TYPES.TIME,
    boundaryGap: ['0%', B_GAP],
    // use e-charts formatter instead
    formatter: {
      day: '{MM}.{dd}'
    },
    display: (v) => {
      const ts = moment(v);
      return ts.tz('GMT').local().format('MM-DD HH:mm:ss.x');
    },
  },
  [AXIS_TYPES.VALUE]: {
    type: AXIS_TYPES.VALUE,
    boundaryGap: [B_GAP, B_GAP],
    formatter: (v) => maybeToExponent(v),
    display: (v) => maybeToExponent(v, 6),
  },
  [AXIS_TYPES.CATEGORY]: {
    type: AXIS_TYPES.CATEGORY,
    boundaryGap: [B_GAP, B_GAP],
    formatter: (v) => v,
    display: (v) => v,
  },
  /**
   * @param {AXIS_TYPES[keyof AXIS_TYPES]} type
   * @returns {AxisMeta}
   */
  get(type) {
    return this[type] || this[AXIS_TYPES.VALUE];
  },
  /**
   * @param {METRICS[keyof METRICS] & string} name
   * @returns {AxisMeta & {label: string}}
   */
  getByName(name) {
    let meta;
    switch (name) {
      case METRICS.TIMESTAMP: {
        meta = this.get(AXIS_TYPES.TIME);
        break;
      }
      case METRICS.CREATED_BY: {
        meta = this.get(AXIS_TYPES.CATEGORY);
        break;
      }
      default: {
        meta = this.get(AXIS_TYPES.VALUE);
        break;
      }
    }
    return {...meta, label: metricToOption(name).text};
  },
  /**
   * Using dimensions to change datapoints on plot instead of assigning data directly to `dataset.source` or axis.data
   * you may want to add suffix to a metric, for example you have metric 'foo', this metric may have the 'min' and
   * 'max' value, user selects 'foo' and selects the "suffix", dimension is "<metric> <suffix>", and your
   * `dataset.source` will look like `{"<metric> <suffix>": value}`.
   * `name` and `suffix` have no space between, echarts, when returns seriesName, uses space as separator between two
   * dimensions, so it becomes hard separate a dimension on x and y.
   * @param {string} name metric name
   * @param {string} suffix metric suffix
   * @returns {string} metric name with a suffix
   */
  createUniqueName(name, suffix) {
    return `${name}: ${suffix}`;
  },
  /**
   * Reverts createUniqueName
   * @param {string} name metric name with suffix
   * @returns {string[]} name and suffix
   */
  parseUniqueName(name) {
    return name.rsplit(': ', 1);
  },
  /**
   * Reverts createUniqueName to a metric name without suffix.
   * @param {string} uniqueName metric name with suffix
   * @returns {AxisMeta & {label: string}}
   */
  getByUniqueName(uniqueName) {
    const [name] = this.parseUniqueName(uniqueName);
    return this.getByName(name);
  },
  /**
   * Returns x/yAxis.minInterval setting based on metric name
   * @param {string} name
   * @returns {string | undefined}
   */
  getMinIntervalSetting(name) {
    return {
      '# of non-baseline knobs': 1,
      'index': 1
    }[name];
  },
  /**
   *
   * @param options v-chart options object
   * @param value metric name
   * @param {'x' | 'y'} axis
   */
  updateOptionsByName(options, value, axis) {
    const meta = this.getByName(value);
    const axisName = `${axis}Axis`;
    options[axisName].name = meta.label;
    options[axisName].type = meta.type;
    options[axisName].axisLabel.formatter = meta.formatter;
    options[axisName].boundaryGap = meta.boundaryGap;
  }
};

/**
 * Returns series object to use in echarts
 * @param {TTest} tTest
 * @param {TTestGroup} groupA
 * @param {TTestGroup} groupB
 * @returns {*[]}
 */
export const getTTestSeries = (tTest, groupA, groupB) => {
  const series = [
    validationSeriesFactory('reject'),
    validationSeriesFactory('accept'),
    validationSeriesFactory('T'),
  ];
  if (tTest === undefined) {
    return series;
  }
  const test = new TTest(
    new TTest.Statistics(groupA.stdev, groupA.total, groupA.mean),
    new TTest.Statistics(groupB.stdev, groupB.total, groupB.mean),
    new TTest.Parameters(tTest.p_value, tTest.alpha_value),
  );
  const [valid, detail] = test.validate();
  if (!valid) {
    console.error(detail);
  }
  if (valid) {
    const c = cycle(series);
    for (let seriesData of test.getSeries()) {
      c.next().value.data = seriesData;
    }
  }
  // easier to check on caller side and inform user that we cannot show graph
  if (series[0].data.length + series[1].data.length + series[2].data.length === 0) {
    return [];
  }
  return series;
};

export const STAT_FIELDS = {
  MIN: 'min',
  MAX: 'max',
  STDEV: 'stdev',
  CV_OF_MEAN: 'cv_of_mean',
  TOTAL: 'total',
  MEAN: 'mean',
};

export const StatUI = {
  FIELDS: Object.freeze(new Map([
    [STAT_FIELDS.TOTAL, ['Sample population', Number]],
    [STAT_FIELDS.MEAN, ['Mean', maybeToExponent]],
    [STAT_FIELDS.STDEV, ['STDEV', (v) => Number.isNaN(v) ? '-' : maybeToFixed(v, 5, 5)]],
    [STAT_FIELDS.CV_OF_MEAN, ['CV of mean', (v) => maybeToFixed(v, 4, 4)]],
    [STAT_FIELDS.MAX, ['MAX', maybeToExponent]],
    [STAT_FIELDS.MIN, ['MIN', maybeToExponent]],
  ])),

  /**
   * @param {(Record<string, number> | Record<string, number>[])} statistics
   * @param {string} [metric]
   * @param {STAT_FIELDS[keyof STAT_FIELDS][]} [exclude]
   * @return {*[]}
   */
  toTableData(statistics, metric, exclude = []) {
    const rows = [];
    let estimator;
    const fields = new Map(this.FIELDS);

    if (statistics.total < 2) {
      fields.delete(STAT_FIELDS.STDEV);
      fields.delete(STAT_FIELDS.CV_OF_MEAN);
    }
    exclude.forEach((key) => fields.delete(key));

    // some statistic entries are objects, if the same statistic calculated for multiple metrics
    // dirty, I know, but StatUI is used in a few places
    const get = (val) => {
      return isObject(val) ? val[metric] : val;
    };

    if (Array.isArray(statistics)) {
      estimator = statistics[0].estimator;
      for (const [key, [label, fmt]] of fields) {
        const [a, b] = statistics;
        const aName = get(a.name) || 'baseline';
        const bName = get(b.name) || 'tuned';
        const aValue = get(a[key]);
        const bValue = get(b[key]);
        rows.push({label, [aName]: fmt(aValue), [bName]: fmt(bValue)});
      }
    } else {
      estimator = statistics.estimator;
      for (const [key, [label, fmt]] of fields) {
        let value = statistics[key];
        if (isObject(value)) {
          value = value[metric];
        }
        rows.push({label, value: fmt(value)});
      }
    }

    // aliases
    if (estimator !== undefined && !['mean', 'average'].includes(estimator)) {
      let extra = {label: estimator};
      const fmt = maybeToExponent;
      if (Array.isArray(statistics)) {
        const [baseline, tuned] = statistics;
        Object.assign(extra, {
          baseline: fmt(baseline.target_value),
          tuned: fmt(tuned.target_value),
        });
      } else {
        Object.assign(extra, {
          value: fmt(statistics.target_value),
        });
      }
      rows.push(extra);
    }

    return rows;
  },
};

/**
 * Converts TTest object into something we can show in table
 * @param {TTest} tTest
 * @returns {{name, items: [{name: string, value},{name: string, value},{name: string, value: (number|*)},{name: string, value: (number|*)},{name: string, value: string}], errors}}
 */
export const tTestToTable = (tTest) => {
  const outcome = `H0 is ${tTest.H0_rejected ? 'rejected' : 'not rejected'} at ${100 - tTest.alpha_value * 100}% confidence`;
  const items = [
    {name: 'H0 hypothesis', value: tTest.H0},
    {name: 'H1 hypothesis', value: tTest.H1},
    {name: 'alpha-value', value: maybeToExponent(tTest.alpha_value)},
    {name: 'p-value', value: maybeToExponent(tTest.p_value)},
    {name: 'Outcome', value: outcome},
  ];
  return {name: tTest.test, items};
};

/**
 * Converts a metric name into an option.
 * @param {string} metric
 * @param {string} [target]
 * @returns {SelectOption}
 */
export const metricToOption = (metric, target) => {
  const value = metric;
  let text = metric;
  if (metric === METRICS.CREATED_BY) {
    text = 'User';
  }
  if (metric === METRICS.INDEX) {
    text = 'Index';
  }
  text = value === target ? `[PE] ${text}` : text;
  return {value, text};
};
