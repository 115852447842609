<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  watch: {
    '$store.state.sys.messages': {
      immediate: true,
      handler: function (messages) {
        if (messages.length < 1) {
          return;
        }
        for (let msg of messages) {
          this.$store.commit('sys/removeMessage');
          this.$bvToast.toast(msg.msg, {
            variant: msg.level,
            solid: false,
            noCloseButton: true,
            toaster: 'b-toaster-top-center',
          });
        }
      },
    }
  },
};
</script>

<style lang="scss">
@import "#/login/main.scss";
@import '#/custom';
</style>
