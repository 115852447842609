import axios from 'axios';
import qs from 'qs';
import {APIError} from '@/api/helpers';
import {IdentityProvider} from '@/api/resources/login';
import Account from '@/api/resources/account';
import Definition from '@/api/resources/definition';
import Experiment from '@/api/resources/experiment';
import Hint from '@/api/resources/hint';
import Configuration from '@/api/resources/configuration';
import Comparison from '@/api/resources/comparison';
import Report from '@/api/resources/report';
import Plan from '@/api/resources/plan';
import Project from '@/api/resources/project';
import Sample from '@/api/resources/sample';
import SLMDevice from '@/api/resources/slm/device';
import SLMSearch from '@/api/resources/slm/search';
import SLMAlert from '@/api/resources/slm/alert';
import SLMSession from '@/api/resources/slm/session';
import SLMLabel from '@/api/resources/slm/label';
import SLMAlertEvent from '@/api/resources/slm/event';
import SLMOverview from '@/api/resources/slm/overview';
import SLMDeviceDisplayPreset from '@/api/resources/slm/deviceDisplayPreset';
import SLMDeviceConfiguration from '@/api/resources/slm/configuration';
import Users from '@/api/resources/users';


/**
 * All urls must end with slash.
 * URL should not include "undefined" in it. Usually if it does this or bug in code or intentional, for example when
 * an url definition allows a request without a parameter. For example, `/api/slm/alerts/:id/` and `/api/slm/alerts/` are
 * both valid.
 * URL should not include un-formatted parameters. For example, in `/api/slm/alerts/:id/` :id is a parameter and has
 * to be removed from the final url.
 * @param {string} url to normalize
 * @returns {string} normalized url
 */
export function normalizeURL(url) {
  // Remove undefined from url
  url = url.split('/').filter((v) => ['undefined'].indexOf(v) === -1).join('/');
  // Remove un-formatted parameters
  url = url.replace(/\/:[^/]+/g, '');
  // Add trailing slash
  if (!url.endsWith('/')) {
    url += '/';
  }
  return url;
}

class API {
  /**
   * Register here all your endpoints, don't forget to pass API class to it.
   */
  constructor() {
    this.samples = new Sample(this);
    this.definitions = new Definition(this);
    this.experiments = new Experiment(this);
    this.hints = new Hint(this);
    this.configuration = new Configuration(this);
    this.comparison = new Comparison(this);
    this.reports = new Report(this);
    this.plan = new Plan(this);
    this.projects = new Project(this);
    this.account = new Account(this);
    this.login = {
      provider: new IdentityProvider(this),
    };
    this.slm = {
      device: new SLMDevice(this),
      search: new SLMSearch(this),
      alert: new SLMAlert(this),
      session: new SLMSession(this),
      label: new SLMLabel(this),
      event: new SLMAlertEvent(this),
      overview: new SLMOverview(this),
      deviceDisplayPreset: new SLMDeviceDisplayPreset(this),
      configuration: new SLMDeviceConfiguration(this),
    };
    this.users = new Users(this);
  }

  /**
   * @returns {import('axios').AxiosRequestConfig<*>}
   */
  get config() {
    return {
      xsrfHeaderName: 'X-CSRFToken',
      xsrfCookieName: 'csrftoken',
      baseURL: '/api',
      headers: {
        Accept: 'application/json;',
      },
    };
  }

  /**
   * Creates a new axios client
   * @returns {import('axios').AxiosInstance}
   */
  get client() {
    return axios.create(this.config);
  }

  /**
   * Adds baseURL and get params, only for `fetch`, axios uses `this.config` for same purpose.
   * @param {string} uri and API url
   * @param {Record<string, string>} [kwargs] GET params
   */
  buildURL(uri, kwargs) {
    return `${this.config.baseURL}${uri}?${qs.stringify(kwargs)}`;
  }

  /**
   * @param func
   * @param args
   * @returns {Promise<import('axios').AxiosResponse<*>>}
   * @throws {APIError<import('axios').AxiosError<*>>}
   */
  async _request(func, ...args) {
    let response = null;
    let error = null;
    try {
      response = await func(...args);
    } catch (e) {
      error = new APIError(e);
      // js polls api periodically, if 401 occurred, send user to login page
      const login = 'account/login';
      if (error.status === 401 && window.location.pathname.indexOf(login) === -1) {
        window.location.href = `${login}?next=${window.location.pathname}`;
      } else {
        throw error;
      }
    }
    return response;
  }

  /**
   * Ensures that url has trailing slash and performs GET request.
   * @param {string} url
   * @param {Record<string, *>} [data] if defined will be turned into GET params
   * @returns {Promise<import('axios').AxiosResponse<*>>}
   */
  async get(url, data) {
    url = normalizeURL(url);
    if (data) {
      url += `?${qs.stringify(data)}`;
    }
    return this._request(this.client.get, url);
  }

  /**
   * @param {string} url
   * @param {object} [data]
   * @param {import('axios').AxiosRequestConfig} [config]
   * @returns {Promise<import('axios').AxiosResponse<*>>}
   */
  async post(url, data, config) {
    url = normalizeURL(url);
    return this._request(this.client.post, url, data, config);
  }

  /**
   * Performs OPTIONS request
   * @param {string} url
   * @returns {Promise<import('axios').AxiosResponse<*>>}
   */
  async options(url) {
    return this._request(this.client.options, normalizeURL(url));
  }

  /**
   * @param {string} url
   * @param {object} data
   * @returns {Promise<import('axios').AxiosResponse<*>>}
   */
  async patch(url, data) {
    return this._request(this.client.patch, normalizeURL(url), data);
  }

  /**
   * @param {string} url
   * @returns {Promise<import('axios').AxiosResponse<*>>}
   */
  async delete(url) {
    return this._request(this.client.delete, normalizeURL(url));
  }

  /**
   *
   * @param {string} uri
   * @param {RequestInit} init
   * @param {Record<string, any>} kwargs
   * @returns {Promise<any[]>}
   */
  async fetchJSONL({uri, init, ...kwargs}) {
    const decoder = new TextDecoder('utf-8');
    const delimiter = /\r?\n/gm;

    const buffer = [];
    let bindex = 0;

    const response = await fetch(this.buildURL(uri, kwargs), init);
    if (response.status !== 200) {
      throw new APIError({response});
    }
    const reader = response.body.getReader();

    for (; ;) {
      let {value, done} = await reader.read();
      if (done) {
        if (buffer.length === 0) {
          return [];
        }
        return buffer.join('').trim().split(delimiter).map((v) => JSON.parse(v));
      }
      buffer[bindex] = decoder.decode(value, {stream: true});
      bindex += 1;
    }
  }
}

const api = new API();
export default api;
