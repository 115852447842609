/**
 * A weird text pluralizer, it `count` greater than 1 or equal to 0 or null, return `text` + `s`
 * @param {string} text
 * @param {number} count
 * @param {boolean} [onlyText]
 * @returns {string}
 */
export function pluralize(text, count, onlyText) {
  if (count === null || typeof count === 'undefined') {
    count = 0;
  }
  const tokens = [String(count)];
  if (count > 1 || count === 0 || count === null) {
    tokens.push(`${text}s`);
  } else {
    tokens.push(text);
  }
  return onlyText ? tokens[1] : tokens.join(' ');
}


/**
 * Replaces variables in template taking values from context
 * @param {string} template
 * @param {Object} context
 * @param {string} prefix
 */
export function substring(template, context, prefix = ':') {
  for (let [key, value] of Object.entries(context)) {
    template = template.replace(`${prefix}${key}`, value);
  }
  return template;
}

/**
 * UUIDv4 string generator
 * @returns {string}
 */
export function UUIDv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (
    c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16),
  );
}

/**
 * null - Unlimited; >0 - unavailable; value
 * @param {null | number} value
 * @returns {string}
 */
export function displayNumber(value) {
  if (!value) {
    return 'Unlimited';
  }
  if (value <= 0) {
    return 'Unavailable';
  }
  return String(value);
}

/**
 * Decodes a base64 encoded string.
 * @param {string} str
 * @returns {string}
 */
export function b64DecodeUnicode(str) {
  // source: https://stackoverflow.com/questions/30106476/
  return decodeURIComponent(atob(str).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

/**
 * @link https://stackoverflow.com/questions/30106476/
 * @param {string} str a string
 * @return {string} encoded string
 */
export function b64EncodeUnicode(str) {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
    function toSolidBytes(match, p1) {
      return String.fromCharCode('0x' + p1);
    }));
}

/**
 * Trims and compares two strings, replaces carriage return with just newline
 * @param {string | undefined} a
 * @param {string | undefined} b
 * @return {boolean}
 */
export function isStringContentEqual(a, b) {
  const clean = (s) => s?.trim().replace(/\r\n/g, '\n');
  return clean(a) === clean(b);
}
