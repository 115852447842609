import urls from '@/api/urls';
import Endpoint from '@/api/resources/base';

export default class Comparison extends Endpoint {
  TYPES = {
    EXPERIMENT: 'experiment',
    CONFIGURATION: 'configuration',
  };

  /**
   * Returns two experiments
   * @param {TYPES} aType a model to compare
   * @param {number | UUID} aID models' id
   * @param {TYPES} bType
   * @param {number | UUID} bID
   * @returns {Promise<import('axios').AxiosResponse<ComparisonExperimentSerializer[]>>}
   */
  list = async (aType, aID, bType, bID) => {
    const url = urls.comparison.list.format({aType, aID, bType, bID});
    return this.api.get(url);
  };

  /**
   * Aggregated by configuration samples
   * @param {TYPES} aType a model to compare
   * @param {number | UUID} aID models' id
   * @param {TYPES} bType
   * @param {number | UUID} bID
   * @param {number} [offset]
   * @returns {Promise<import('axios').AxiosResponse<{values: ComparisonSamples[]; metrics: string[]}>>}
   */
  samples = async (aType, aID, bType, bID, offset) => {
    const url = urls.comparison.samples.format({aType, aID, bType, bID});
    const response = await this.api.get(url, {offset});
    response.data.values = response.data.values.map(([metrics, configuration, experiment]) => {
      metrics = metrics.map(([nameIndex, min, max]) => {
        const name = response.data.metrics[nameIndex];
        return {name, min, max};
      });
      return {metrics, configuration, experiment};
    });
    return response;
  };
  /**
   * Regulators of left and right configurations grouped by name
   * @param {number} aID configuration' id
   * @param {number} bID
   * @returns {Promise<import('axios').AxiosResponse<ComparisonKnobs[]>>}
   */
  knobs = async (aID, bID) => {
    const url = urls.comparison.knobs.format({
      aID, bID,
      aType: this.TYPES.CONFIGURATION,
      bType: this.TYPES.CONFIGURATION
    });
    return this.api.get(url);
  };
}
