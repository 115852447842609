import api from '@/api';

export const STATUS = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  ERROR: 'ERROR',
};

export default {
  namespaced: true,
  state: () => ({
    plan: {
      name: null,
      variant: null,
      dataRetention: null,
      collaboratorsLimit: null,
      configurationsLimit: null,
      projectsLimit: null,
      projectsUsed: null,
      projectsLeft: null,
      collaboratorsUsed: null,
      configurationsUsed: null,
      configurationsLeft: null,
    },
    loading: true,
    status: STATUS.INITIAL,
    error: null,
  }),

  mutations: {
    handlePlanResponse(state, data) {
      state.status = STATUS.LOADED;
      state.plan = {
        name: data.name,
        variant: data.variant,
        dataRetention: data.data_retention,
        collaboratorsLimit: data.collaborators,
        configurationsLimit: data.configurations,
        projectsLimit: data.projects,
        projectsUsed: data.status.projects,
        projectsLeft: data.projects !== null ? data.projects - data.status.projects : null,
        collaboratorsUsed: data.status.collaborators,
        configurationsUsed: data.configurations !== null ? data.configurations - data.status.configurations_left : null,
        configurationsLeft: data.status.configurations_left,
      };
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setStatus(state, payload) {
      state.status = payload;
    },
  },

  actions: {
    async fetchPlan({state, commit}) {
      // Don't make a request if loading
      if ([STATUS.LOADING].includes(state.status)) {
        return;
      }
      commit('setLoading', true);
      commit('setStatus', STATUS.LOADING);
      try {
        const response = await api.plan.my();
        commit('handlePlanResponse', response.data);
      } catch (e) {
        commit('setStatus', STATUS.ERROR);
        console.error(e);
      } finally {
        commit('setLoading', false);
      }
    },
  },
};
