import {DEFAULT_PAGE_LIMIT} from '@/constants';
import {mapStringValues} from '@/utils/object';
import {b64DecodeUnicode, b64EncodeUnicode} from '@/utils/text';
import Endpoint from '@/api/resources/base';
import urls from '@/api/urls';

export default class Definition extends Endpoint {
  /**
   * List of all tags in project
   * @param {UUID} project
   * @param {number | null} [offset] start search from this index
   * @param {number | null} [limit] max number of definitions searched by one call to api starting from offset
   * @param {Record<string, string>} [query] optional GET query
   * @returns {Promise<import('axios').AxiosResponse<Paginated<DefinitionSerializer[]>>>}
   */
  list = async (project, offset = 0, limit = DEFAULT_PAGE_LIMIT, query = {}) => {
    const url = urls.project.definition.list.format({project});
    const data = {offset, limit, ...query};
    return this.api.get(url, data);
  };

  /**
   * List of small payload definition
   * @param {UUID} project
   * @param {number | null} [offset] start search from this index
   * @param {number | null} [limit] max number of definitions searched by one call to api starting from offset
   * @param {Record<string, string>} [query] optional GET query
   * @returns {Promise<import('axios').AxiosResponse<Paginated<Definition[]>>>}
   */
  track = async (project, offset = 0, limit = DEFAULT_PAGE_LIMIT, query = {}) => {
    const response = await this.api.get(urls.project.definition.track.format({project}), {offset, limit, ...query});
    return this.rehydrate(response);
  };

  /**
   * Amount of samples in a definition
   * @param {UUID} project id
   * @param {number} [definition] optionally a single definition id
   * @returns {Promise<import('axios').AxiosResponse<(DefinitionSampleCountSerializer[] | DefinitionSampleCountSerializer)>>}
   */
  countSamples = async (project, definition) => {
    let url;
    if (definition) {
      url = urls.project.definition.count.format({project, definition});
    } else {
      url = urls.project.definition.countList.format({project});
    }
    return this.api.get(url);
  };

  /**
   * Deletes a definition by id
   * @param {UUID} project id
   * @param {number} definition id
   * @returns {Promise<import('axios').AxiosResponse<undefined>>}
   */
  destroy = async (project, definition) => {
    const url = urls.project.definition.detail.format({project, definition});
    return this.api.delete(url);
  };

  /**
   * Retrieve a definition by id
   * @param {UUID} project
   * @param {number} definition
   * @returns {Promise<import('axios').AxiosResponse<DefinitionSerializer>>}
   */
  retrieve = async (project, definition) => {
    const url = urls.project.definition.detail.format({project, definition});
    return this.api.get(url);
  };

  /**
   * @param {UUID} project
   * @param {number} definition
   * @returns {Promise<import('axios').AxiosResponse<DefinitionFilesSerializer>>}
   */
  retrieveFiles = async (project, definition) => {
    const url = urls.project.definition.files.format({project, definition});
    const response = await this.api.get(url);
    response.data = mapStringValues(response.data, b64DecodeUnicode);
    return response;
  };

  /**
   * @param project
   * @param definition
   * @param data
   * @returns {Promise<import('axios').AxiosResponse<DefinitionSerializer>>}
   */
  postFiles = async (project, definition, data) => {
    const url = urls.project.definition.files.format({project, definition});
    return this.api.post(url, mapStringValues(data, b64EncodeUnicode));
  };
}
