import Endpoint from '@/api/resources/base';
import urls from '@/api/urls';

export default class SLMLabel extends Endpoint {
  /**
   * @deprecated unused
   * @param {AlertQuerySerializer} params
   * @return {Promise<import('axios').AxiosResponse<AlertLabelSerializer[]>>}
   */
  list = async (params) => {
    return this.api.get(urls.slm.label.list, params);
  };
}
