import {apiUrl} from '@/api/client';
import {requestAction} from '@/api/helpers';
import {ROLES} from '@/constants';
import {cloneDeep} from 'lodash';

export default {
  namespaced: true,
  state: () => ({
    /**
     * @type Role[]
     */
    list: [],
    /**
     * @type {Account | {id: null, name: null, projects: []}}
     */
    account: {id: null, name: null, projects: []},
    // temporary entities, when multiple components are sharing the same entity it's easier to update it in a single
    // place, here, so change propagates to components back.
    term_: '',
    // user clicks on a row in table and role_ gets set
    /**
     * @type {Role | {user: object, memberships: [], role: 0}}
     */
    role_: {user: {}, memberships: [], role: 0},
  }),

  getters: {
    /**
     * @param [state]
     * @returns {Role[]}
     */
    filteredList(state) {
      return state.list.search(state.term_, {true: '', false: ''});
    },
    /**
     * @param [state]
     * @return {function(Role): {name: string, guid: string}[]}
     */
    getRoleOwns(state) {
      return (role) => {
        if (!role) {
          return [];
        }
        return role.memberships.filter((member) => {
          return member.role === ROLES.OWNER;
        }).map((member) => {
          return {name: member.project.name, guid: member.project.guid};
        });
      };
    },
  },

  mutations: {
    setList(state, data) {
      state.list = data;
    },

    setTerm(state, term_) {
      state.term_ = term_;
    },

    addMember(state, data) {
      state.list.push(data);
    },

    updateRole(state, data) {
      state.list = state.list.update(data, 'id');
    },

    /**
     * Removes a role from `state.list`
     * @param [state]
     * @param {Role} data
     */
    remove(state, data) {
      state.list = state.list.remove(data, 'id');
    },

    /**
     * @param [state]
     * @param {Role} [data]
     */
    setRole_(state, data) {
      state.role_ = cloneDeep(data);
    },

    resetRole_(state) {
      state.role_ = cloneDeep(state.list.find((role) => role.id === state.role_.id) || null);
    },

    unsetRole_(state) {
      state.role_ = {user: {}, memberships: [], role: 0};
    }
  },

  actions: {
    async fetchList({commit}) {
      const [response, error] = await requestAction('get', apiUrl.planRoles);
      if (error) {
        throw error;
      } else {
        commit('setList', response.data);
      }
    },
    /**
     * Deletes role and memberships essentially removing user from account
     * @param commit
     * @param {Role} role a role to remove
     * @param {string?} setOwnerTo if role owns any projects - transfer ownership to this email
     * @returns {Promise<void>}
     */
    async destroy({commit}, {role, setOwnerTo}) {
      const [, error] = await requestAction(
        'delete',
        apiUrl.planRole.format({id: role.id}),
        {headers: {'Set-Owner-To': setOwnerTo}},
      );
      if (!error) {
        commit('remove', role);
      } else {
        throw error;
      }
    },
    /**
     * Fetches a single role by id, updates store and returns it
     * @param {state}
     * @param {number} [id] roles id
     * @return Promise<Role>
     */
    async fetch({state, commit}, id) {
      const [response, error] = await requestAction('GET', apiUrl.planRole.format({id}));
      if (error) {
        throw error;
      }
      commit('updateRole', response.data);
      // fetch it from store so returned value will have observers
      return state.list.find((role) => role.id === id);
    },
    /**
     * Fetches account data, sets it and returns it
     * @param [state]
     * @return {Promise<Account>}
     */
    async fetchAccount({state}) {
      const [response, error] = await requestAction('GET', apiUrl.planAccount.format({id: 'active'}));
      if (error) {
        throw error;
      }
      state.account = response.data;
      return state.account;
    }
  },
};
