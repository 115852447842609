import {getCookie, REPORT_COOKIE, setCookie} from '@/utils/cookies';
import {ROLES, ALEVEL} from '@/constants';
import api from '@/api';

const setInitialFields = () => ([
  {label: 'Type', key: 'type', sortable: true},
  {label: 'Name', key: 'entity_name', sortable: true},
  {label: 'Version', key: 'version', sortable: true},
  {label: 'Experiment name', key: 'experiment_name', sortable: true},
  {label: 'T.metric name', key: 't_metric_name', sortable: true},
  {label: 'Best t.metric', key: 'best_t_metric', sortable: true},
  {label: 'Baseline t.metric', key: 'baseline_t_metric', sortable: true},
  {label: 'User', key: 'user', sortable: true},
  {label: 'Tags', key: 'tags', formatter: (item) => item?.join(', '), sortable: true},
  {label: '# of samples', key: 'num_of_samples', sortable: true},
  {label: '# of config explored', key: 'num_of_configs', sortable: true},
  {label: 'Inventory', key: 'inventory'},
]);

export default {
  namespaced: true,
  state: () => ({
    /** @type {ReportSerializer[]} **/
    list: [],
    /** @type {ReportDetailSerializer} **/
    selected: {},
    initialFields: setInitialFields(),
    selectedFields: getCookie(REPORT_COOKIE)
      ? getCookie(REPORT_COOKIE).split(',')
      : setInitialFields().map((el) => el.key),
    /** @type {ReportMember[]} **/
    members: [],
    roles: [
      {
        title: ROLES.VIEWER,
        description: 'Can only view',
        short: 'Can view',
      },
      {
        title: ROLES.EDITOR,
        description: 'Can only view',
        short: 'Can edit',
      },
      {
        title: ROLES.MANAGER,
        description: 'Can invite new users and change their roles',
        short: 'Can manage',
      },
      {
        title: ROLES.OWNER,
        description: 'All permissions.',
        short: 'Owner',
        disabled: true,
      }
    ],
  }),
  getters: {
    /**
     * Array of selected project's UUIDs, used for adding or removing project to report
     * @param state
     * @returns {string[]}
     */
    selectedProjectUUIDs(state) {
      return state.selected.tree?.map((el) => el.project.guid) || [];
    },
    /**
     * Array of fields of report data table
     * @param state
     * @returns {object[]}
     */
    fields(state) {
      if (state.selectedFields.length > 0) {
        return state.initialFields.filter((el) => state.selectedFields.indexOf(el.key) > -1);
      }
      return state.initialFields;
    },
    isViewer(state) {
      return (state.selected.access_level || ALEVEL.NOBODY) > ALEVEL.NOBODY;
    },
    isEditor(state) {
      return (state.selected.access_level || ALEVEL.NOBODY) > ALEVEL.PROJECT_VIEWER;
    },
    isManager(state) {
      return (state.selected.access_level || ALEVEL.NOBODY) > ALEVEL.PROJECT_EDITOR;
    },
    isProjectOwner(state) {
      return (state.selected.access_level || ALEVEL.NOBODY) > ALEVEL.PROJECT_MANAGER;
    },
    isAccountOwner(state) {
      return (state.selected.access_level || ALEVEL.NOBODY) > ALEVEL.ACCOUNT_OWNER;
    },
    accessLevel(state) {
      return state.selected.access_level || ALEVEL.NOBODY;
    },
  },
  mutations: {
    resetSelected(state) {
      state.selected = {};
    },
    /**
     * @param state
     * @param {ReportDetailSerializer} data
     */
    setSelected(state, data) {
      Object.entries(data).forEach(([key, value]) => {
        state.selected[key] = value;
      });
    },
    /**
     * Update selected columns array and store it into cookies
     * @param state
     * @param {array} selectedFields array of selected fields
     */
    updateSelectedFields(state, selectedFields) {
      state.selectedFields = selectedFields;
      setCookie(REPORT_COOKIE, state.selectedFields.join(','));
    },
    removeReport(state, id) {
      state.list = state.list.filter((el) => el.id !== id);
    },
    /**
     * Set members list
     * @param state
     * @param {array.<ReportMemberSerializer>} data
     */
    setMembers(state, data) {
      state.members = data;
    },
    /**
     * Set particular member data
     * @param state
     * @param {number} id
     * @param {{role: string}} rest
     */
    setMember(state, {id, ...rest}) {
      state.members = state.members.map((el) => {
        if (el.id === id) {
          return {...el, ...rest};
        }
        return el;
      });
    },
    /**
     * Add member to the list of report members
     * @param state
     * @param {ReportMemberSerializer} data
     */
    addMember(state, data) {
      state.members = [data, ...state.members];
    },
    /**
     * Pop member from the list of report members
     * @param state
     * @param {number} id
     */
    popMember(state, id) {
      state.members = state.members.filter((el) => el.id !== id);
    },
  },
  actions: {
    async fetchList({state}) {
      const response = await api.reports.list();
      state.list = response.data;
    },
    async fetchDetail({state, commit}, reportID) {
      const response = await api.reports.retrieve(reportID);
      state.selected = response.data;
    },
    async patchProjectContent({state, getters}, currentProjects) {
      const guidToRemove = getters.selectedProjectUUIDs.filter((id) => !currentProjects.includes(id));
      const guidToAdd = currentProjects.filter((id) => !getters.selectedProjectUUIDs.includes(id));
      try {
        for (let guid of guidToAdd) {
          await api.reports.patchContent(
            state.selected.id,
            api.reports.ENTITIES.PROJECT,
            guid,
            api.reports.ACTIONS.ADD,
          );
        }
        for (let guid of guidToRemove) {
          await api.reports.patchContent(
            state.selected.id,
            api.reports.ENTITIES.PROJECT,
            guid,
            api.reports.ACTIONS.REMOVE,
          );
        }
      } catch (error) {
        console.error(error.toString());
      }
    },
    async removeProject({state}, projectID) {
      await api.reports.patchContent(
        state.selected.id,
        api.reports.ENTITIES.PROJECT,
        projectID,
        api.reports.ACTIONS.REMOVE,
      );
    },
    /**
     * Refresh report members list
     * @param state
     * @param commit
     */
    async refreshMembers({state, commit}) {
      if (!state.selected.id) {
        console.warn('No report id set.');
        return;
      }
      try {
        const response = await api.reports.retrieveMembers(state.selected.id);
        commit('setMembers', response.data);
      } catch (error) {
        console.error(error.toString());
      }
    },
  },
};
