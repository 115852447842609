import urls from '@/api/urls';
import Endpoint from '@/api/resources/base';

export default class Plan extends Endpoint {
  /**
   * @return {Promise<import('axios').AxiosResponse<AccountOptionResponse>>}
   */
  fetchIsAccountOwner = async () => {
    return this.api.options(urls.plan.account.list);
  };
  /**
   * @return {Promise<import('axios').AxiosResponse<MyPlanROSerializer>>}
   */
  my = async () => {
    return this.api.get(urls.plan.my);
  };
}
