import {cloneDeep, intersection, differenceBy} from 'lodash';
import {apiUrl} from '@/api/client';
import {requestAction} from '@/api/helpers';
import {SCATTER_Y_EXCLUDE, SCATTER_X_DEFAULT, METRICS, GOAL, GOAL_EXCLUDE} from '@/constants';
import {AXIS_META, metricToOption} from '@/utils/vis';
import {refreeze} from '@/utils/object';
import {daysSince} from '@/utils/datetime';
import api from '@/api';

/**
 * @typedef {{[key in string]: number}} EChartsAgExperimentDataset
 * @property {string} created_by experiment author display name
 * @property {AgExperimentMetricsSerializer} $experiment
 */


const getState = () => ({
  /** @type {ProjectSummary} **/
  summary: {
    guid: '',
    created_at: '',
    definition: {
      version: '',
      created_at: '',
    },
    experiment: {
      guid: '',
      progress: 0,
      state: '',
    },
    statistics: {
      definitions: {
        avg_per_project: 0,
        total_for_project: 0,
      },
    },
  },
  /**
   * @property {AgExperimentMetricsSerializer[]} aggregated sorted aggregated data. Sorting is done on UI.
   * @property {{x: SelectOption[], y: SelectOption[]}} metric list of available metrics
   * @property {{x: (string | null), y: (string | null)}} axis selected metric
   * @property {{x: string, y: string}} goal data is aggregated by min or max. Here it represented as number just
   *  because the AxisSelect component wants a number, and I don't want to make it accept strings.
   * @property {AgTagSerializer[]} tags tags options to select from
   * @property {number[]} tagsSelected id of selected tags
   * @property {boolean} tagsExclude if true experiments with tag ids in tagsSelected will be excluded from overview
   * @property {{text: string, value: number}[]} age experiment age options in days
   * @property {number} ageSelected selected experiment age to fetch from backend
   * @property {PublicUserSerializer[]} authors experiment authors
   * @property {PublicUserSerializer} authorSelected selected author
   */
  scatter: {
    aggregated: [],
    metric: {x: [], y: []},
    axis: {x: null, y: null},
    goal: {x: GOAL.MIN, y: GOAL.MIN},
    tags: [],
    tagsSelected: [],
    tagsExclude: false,
    age: [
      {text: 'Last week', value: 7},
      {text: 'Last month', value: 28},
      {text: '6 months', value: 168},
      {text: '1 year', value: 365},
      {text: 'the beginning of time', value: null},
    ],
    // if undefined the best age will be selected, see `aggregated` action
    ageSelected: undefined,
    authors: [],
    authorSelected: null,
  },
  /**
   * Cached experiment details
   * @type {{[key in UUID]: Readonly<AgExperimentStatusSerializer>}}
   */
  experiments: {},
});

export default {
  namespaced: true,
  state: getState,
  getters: {
    /**
     * Should we show aggregated experiments scatter or not
     * @param state
     * @returns {boolean}
     */
    agReady(state) {
      return state.scatter.axis.x && state.scatter.axis.y && state.scatter.aggregated.length > 0;
    },

    /**
     * Filters-out experiments.
     * @param state
     * @returns {Readonly<AgExperimentMetricsSerializer[]>}
     */
    aggregated(state) {
      const {axis, aggregated, tagsSelected, tagsExclude, authorSelected} = state.scatter;
      return refreeze(aggregated.filter(({metrics, tag_ids, created_by}) => {
        // if an author is selected filter-out experiments of other users
        if (authorSelected !== null && created_by.id !== authorSelected.id) {
          return false;
        }
        // filter-out experiments without matching x or y metric
        const xExist = axis.x === METRICS.CREATED_BY || metrics.find((metric) => metric.name === axis.x);
        const yExist = axis.y === METRICS.CREATED_BY || metrics.find((metric) => metric.name === axis.y);
        if (!xExist || !yExist) {
          return false;
        }
        // if tags are selected, filter-out experiments with (or without) matching tags
        return tagsSelected.length === 0 || (intersection(tagsSelected, tag_ids).length > 0) !== tagsExclude;
      }));
    },

    /**
     * Turns aggregated data from backend into array of elements for echarts essentially making metrics flat -
     *  {{name: string, max: number, min: number }, created_by: string}
     *  ->
     *  {[name + min + goal]: number, [name + max + goal]: number}
     * This allows run this only when the aggregated array changes (in case if experiments where added or removed).
     * @param state
     * @param getters
     * @returns {Readonly<EChartsAgExperimentDataset[]>}
     */
    dataset(state, getters) {
      return refreeze(getters.aggregated.map((experiment) => {
        const metrics = experiment.metrics.reduce((pv, cv) => {
          let {name, min, max, min_baseline, max_baseline} = cv;
          if (GOAL_EXCLUDE.includes(name)) {
            pv[name] = min;
          } else {
            pv[AXIS_META.createUniqueName(name, GOAL.MIN)] = min;
            pv[AXIS_META.createUniqueName(name, GOAL.MAX)] = max;
            pv[name] = {baseline: {[GOAL.MIN]: min_baseline, [GOAL.MAX]: max_baseline}};
          }
          pv[METRICS.CREATED_BY] = experiment[METRICS.CREATED_BY].display || 'Unknown';
          return pv;
        }, {});
        metrics['$experiment'] = {
          guid: experiment.guid,
          reported_at: experiment.reported_at,
          created_by: experiment.created_by,
        };
        return metrics;
      }));
    },

    /**
     * Counts number of experiments per day.
     * @param state
     * @param getters
     * @returns {Array.<string, number>[]} day and number of experiments
     */
    perDay(state, getters) {
      // aggregated is sorted, use Map to preserve sort order.
      return [
        ...getters.aggregated.reduce((pv, cv) => {
          const key = new Date(cv['reported_at']).strftime('%d %b %Y');
          const value = pv.get(key) || 0;
          pv.set(key, value + 1);
          return pv;
        }, new Map()).entries()
      ];
    },

    /**
     * See getters.dataset, combining selected axis (metric name) and goal (min or max) we can create a dimension key
     * for x and y, sending the new dimension to echarts it will re-render plot and change dots
     * @param state
     * @returns {string[]}
     */
    dimensions(state) {
      const {axis, goal} = state.scatter;
      let {x, y} = axis;
      // See the `dataset` getter, author metric doesn't have min or max.
      if (!GOAL_EXCLUDE.includes(x)) {
        x = AXIS_META.createUniqueName(axis.x, goal.x);
      }
      if (!GOAL_EXCLUDE.includes(y)) {
        y = AXIS_META.createUniqueName(axis.y, goal.y);
      }
      return [x, y];
    },
    ageDisplay(state) {
      const selected = state.scatter.ageSelected;
      return (state.scatter.age.find(({text, value}) => value === selected) || {text: 'n/a'}).text;
    },
  },
  mutations: {
    reset(state) {
      Object.assign(state, getState());
    },

    /**
     * @param state
     * @param {AgExperimentMetricsSerializer[]} ag
     */
    setScatter(state, ag) {
      if (ag.length === 0) {
        state.scatter.aggregated = [];
        return;
      }

      ag = ag.sortBy({prop: 'reported_at', key: (v) => new Date(v)});

      let metrics = [];
      const authors = [];
      const authorsSeen = [];
      ag.forEach((experiment, index) => {
        experiment.metrics.forEach((metric) => {
          // default to baseline if metric is null, can happen when experiment has only baseline samples
          metric.min = metric.min ?? metric.min_baseline;
          metric.max = metric.max ?? metric.max_baseline;
          if (metric.name === METRICS.TIMESTAMP) {
            // convert it to ms as js requires.
            metric.min *= 1000;
            metric.max *= 1000;
          }
          metrics.push(metric.name);
        });
        // Mutate metrics array, add index metric. ag is sorted by date so index indicates "when experiment is inserted"
        // Add it after metrics.forEach, we'll manually include INDEX into metrics array, so it sorted in a way we need.
        // Only `min` value is required, but I'd like to keep array homogeneous.
        experiment.metrics.push({
          name: METRICS.INDEX,
          min: index,
          max: index,
          max_baseline: index,
          min_baseline: index,
        });
        if (!authorsSeen.includes(experiment.created_by.id)) {
          experiment.created_by.display = experiment.created_by.display || 'Unknown';
          authors.push(experiment.created_by);
          authorsSeen.push(experiment.created_by.id);
        }
      });
      metrics = [METRICS.CREATED_BY, METRICS.INDEX, ...new Set(metrics)].map(metricToOption);

      // pre-select goals based on target_metric_goal if available, x and y axis and x any y axis select options
      state.scatter.metric.y = cloneDeep(metrics).filter(({value}) => !SCATTER_Y_EXCLUDE.includes(value));
      // usually a project has the same type of experiment so defaulting to target metric can be useful, likely all
      // experiments in the project will have it.
      state.scatter.axis.y = ag[0].target_metric || state.scatter.metric.y[1].value;
      state.scatter.goal.y = GOAL[ag[0].target_metric_goal?.toUpperCase()] || GOAL.MIN;
      state.scatter.metric.x = cloneDeep(metrics);
      state.scatter.axis.x = SCATTER_X_DEFAULT;
      // set aggregated data to render datapoint from
      state.scatter.aggregated = ag;
      // set experiment authors
      state.scatter.authors = authors;
    },

    /**
     * Checks difference between store and passed tags, removes local tags not in new tags and adds missing tags
     * @param state
     * @param {AgTagSerializer[]} tags
     */
    setTags(state, tags) {
      const append = differenceBy(tags, state.scatter.tags, 'id').sortBy({prop: 'name'});
      const remove = differenceBy(state.scatter.tags, tags, 'id');
      state.scatter.tags.push(...append);
      remove.forEach((tag) => {
        this._vm.$delete(state.scatter.tags, state.scatter.tags.indexBy({id: tag.id}));
      });
    },

    /**
     * Removes a tag id from selected tags
     * @param state
     * @param {number} id tag id
     * @param {boolean} selected remove or add
     */
    selectTagByID(state, {id, selected}) {
      if (selected) {
        state.scatter.tagsSelected.push(id);
      } else {
        this._vm.$delete(state.scatter.tagsSelected, state.scatter.tagsSelected.indexOf(id));
      }
    },
  },
  actions: {
    async fetch({state}) {
      const projectId = window.path.projectID;
      const [response, error] = await requestAction('get', apiUrl.projectSummary.format({projectId}));
      if (error) {
        throw error;
      }
      state.summary = response.data;
    },
    async aggregated({state, commit, rootState}, projectUUID) {
      const projectId = projectUUID || window.path.projectID;
      // if no age selected, try to guess the best range based on reported_at (latest datetime of an experiment)
      if (state.scatter.ageSelected === undefined) {
        const project = rootState.project.current;
        let days = -1;
        if (project) {
          days = daysSince(new Date(), new Date(project.reported_at));
        }
        const opt = (state.scatter.age.find(({value}) => value > days) || {value: null});
        state.scatter.ageSelected = opt.value;
      }
      const response = await api.experiments.aggregated(projectId, {max_age: state.scatter.ageSelected});
      commit('setScatter', response.data);
    },
    async agTags({commit}, projectUUID) {
      const project = projectUUID || window.path.projectID;
      const response = await api.experiments.agTags(project);
      commit('setTags', response.data);
    },
    /**
     * @param state
     * @param commit
     * @param {UUID} project id of project
     * @param {UUID} experiment id of experiment
     * @param {AgExperimentStatusSerializer} initial initial values to be overridden by response
     * @param {UUID} projectUUID
     * @returns {Promise<AgExperimentStatusSerializer>}
     */
    async agStatus({state, commit}, {experiment, initial, projectUUID}) {
      const project = projectUUID || window.path.projectID;
      if (experiment in state.experiments) {
        return state.experiments[experiment];
      }
      const response = await api.experiments.agStatus(project, experiment);
      state.experiments[experiment] = refreeze({...initial, ...response.data});
      return state.experiments[experiment];
    },
  },
};
