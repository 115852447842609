import Endpoint from '@/api/resources/base';
import urls from '@/api/urls';

export default class deviceDisplayPreset extends Endpoint {
  /**
   * List of device display presets
   * @param {DeviceDisplayPresetQuerySerializer} params
   */
  list = async (params = {}) => {
    return this.api.get(urls.slm.deviceDisplayPreset.list, params);
  };

  /**
   * A single device display preset.
   * @param {number} id Device display preset ID.
   */
  detail = async (id) => {
    return this.api.get(urls.slm.deviceDisplayPreset.detail.format({id}));
  };

  /**
   * Create a new device display preset.
   */
  create = async (data) => {
    return this.api.post(urls.slm.deviceDisplayPreset.list, data);
  }

  /**
   * Update a device display preset
   */
  update = async (id, data) => {
    return this.api.patch(urls.slm.deviceDisplayPreset.detail.format({id}), data);
  }

  /**
   * Delete a device display preset
   */
  destroy = async (id) => {
    return this.api.delete(urls.slm.deviceDisplayPreset.detail.format({id}));
  }
}
