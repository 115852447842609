import urls from '@/api/urls';
import Endpoint from '@/api/resources/base';

export default class Configuration extends Endpoint {
  /**
   * @param {string} [updated_at]
   * @param {UUID} experiment
   * @returns {Promise<ConfigurationStream[]>}
   */
  stream = async (experiment, updated_at) => {
    const uri = urls.v2.experiment.configuration.stream.format({experiment});
    return this.api.fetchJSONL({
      uri,
      init: {
        method: 'GET',
        signal: this.addCtrl('stream').signal,
      },
      updated_at,
    });
  };
}
