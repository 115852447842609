import {DEFAULT_PAGE_LIMIT} from '@/constants';
import urls from '@/api/urls';
import Endpoint from '@/api/resources/base';

export default class Project extends Endpoint {
  /**
   * Patch project
   * @param {UUID} project
   * @param {{name?: string, description?: string}} data
   * @returns {Promise<import('axios').AxiosResponse<ProjectSerializer>>}
   */
  patch = async (project, data) => {
    const url = urls.project.detail.format({project});
    return this.api.patch(url, data);
  };
  /**
   * Get project members
   * @param {UUID} project project's uuid
   * @returns {Promise<import('axios').AxiosResponse<Member[]>>}
   */
  retrieveMembers = async (project) => {
    const url = urls.project.member.list.format({project});
    return this.api.get(url);
  };
  /**
   * Delete project member
   * @param {UUID} project project's uuid
   * @param {number} member id of member
   * @returns {Promise<import('axios').AxiosResponse>}
   */
  deleteMember = async (project, member) => {
    const url = urls.project.member.detail.format({project, member});
    return this.api.delete(url);
  };
  /**
   * Invite project member
   * @param {UUID} project project's uuid
   * @param {{user: {email: string}, role: string}} data
   * @returns {Promise<import('axios').AxiosResponse<Member>>}
   */
  inviteMember = async (project, data) => {
    const url = urls.project.member.invite.format({project});
    return this.api.post(url, data);
  };
  /**
   * Patch project member role
   * @param {UUID} project project's uuid
   * @param {number} member id of member
   * @param {{role: string}} data
   * @returns {Promise<import('axios').AxiosResponse<Member>>}
   */
  patchMemberRole = async (project, member, data) => {
    const url = urls.project.member.detail.format({project, member});
    return this.api.patch(url, data);
  };

  /**
   * Retrieves a single project details by project guid
   * @param {UUID} project
   * @returns {Promise<import('axios').AxiosResponse<ProjectSerializer>>}
   */
  retrieve = async (project) => {
    const url = urls.project.detail.format({project});
    return this.api.get(url);
  };

  /**
   * Retrieves list of projects
   * @param {number | null} [offset] start fetching from this index
   * @param {number | null} [limit] number of projects to fetch starting from offset
   * @param {Record<string, string>} [query] optional GET query
   * @returns {Promise<import('axios').AxiosResponse<Paginated<ProjectSerializer[]>>>}
   */
  list = async (offset = 0, limit = DEFAULT_PAGE_LIMIT, query = {}) => {
    const data = {offset, limit, ...query};
    return this.api.get(urls.project.list, data);
  };

  /**
   * List of projects available to add to a report
   * @returns {Promise<import('axios').AxiosResponse<ProjectAvailableForReportSerializer>>}
   */
  availableForReport = async () => {
    return this.api.get(urls.project.availableForReport);
  };

  /**
  * Change project's owner
  * @param {string} project project's uuid
  * @param {string} email owner's email
   * @returns {Promise<import('axios').AxiosResponse<Member>>}
  */
  transferOwnership = async (project, email) => {
    const data = {email};
    const url = urls.project.member.transferOwnership.format({project});
    return this.api.post(url, data);
  }
}
