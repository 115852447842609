import urls from '@/api/urls';
import Endpoint from '@/api/resources/base';

export default class Account extends Endpoint {
  /**
   * @param {{email: string}} data
   * @return {Promise<import('axios').AxiosResponse<{detail: string}>>}
   */
  askPasswordReset = async (data) => {
    const url = urls.account.sendResetPasswordLink;
    return this.api.post(url, data);
  };
  /**
   * @param {UserSerializer} data
   * @returns {Promise<import('axios').AxiosResponse<UserSerializer>>}
   */
  selfChange = async (data) => {
    return this.api.patch(urls.account.self.detail, data);
  };
  /**
   * @param {UserEmailSerializer} data
   * @return {Promise<import('axios').AxiosResponse<{email: string}>>}
   */
  changeEmail = async (data) => {
    return this.api.post(urls.account.changeEmail, data);
  };
  /**
   * @param {VerifyEmailSerializer} data
   */
  changeEmailVerify = async (data) => {
    return this.api.post(urls.account.changeEmailVerify, data);
  };
  /**
   * logout user
   * @returns {Promise<import('axios').AxiosResponse<{detail: string}>>}
   */
  logout = async () => {
    return this.api.post(urls.account.logout, {});
  };
  /**
   * Performs session login
   * @param {string} email
   * @param {string} password
   * @returns {Promise<import('axios').AxiosResponse<null>>}
   */
  login = async (email, password) => {
    return this.api.post(
      urls.account.login,
      {email, password},
      {headers: {'X-Session-Auth': true}},
    );
  };
  /**
   * @param {string} data.fistName
   * @param {string} data.lastName
   * @param {string} data.email
   * @param {string} data.password
   * @param {string} data.passwordConfirm
   * @param {{email: string; token: string}} [data.invite]
   * @return {Promise<*>}
   */
  signup = async (data) => {
    return this.api.post(urls.account.registration, data);
  }
  /**
   * Sends verification email
   */
  sendVerificationEmail = async (email) => {
    return this.api.post(urls.account.resendVerification, {email});
  }

  verifyEmail = async (data) => {
    return this.api.post(urls.account.verifyEmail, data);
  }

  verifyInvite = async (data) => {
    return this.api.post(urls.account.verifyInvite, data);
  }
  /**
   * @param {{email: string}} data
   * @return {Promise<import('axios').AxiosResponse<{detail: string}>>}
   */
  resetPassword = async (data) => {
    return this.api.post(urls.account.resetPassword, data);
  };
}
