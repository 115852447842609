import isEmpty from 'lodash/isEmpty';
import AutoQueue from '@/utils/tasks';
import {requestAction} from '@/api/helpers';
import {apiUrl} from '@/api/client';
import {MSG_TYPES} from '@/constants';
import api from '@/api';

/**
 * @typedef {Object} Msg
 * @property {String} level - one of: default, warning, danger, info, success
 * @property {String} msg
 * @property {String} [type] - one fo MSG_TYPES, (optional)
 * @property {Object} [toastOptions] - set of bvToast.toast options, (optional)
 */

/**
 * @typedef {Object} Pricing
 * @property {Number} amount
 * @property {String} currency
 * @property {String} interval - week/month/year
 */

/**
 * @typedef {Object} Status
 * @property {Number} configurations_left - how many configurations left
 * @property {Number} projects - how many projects to create left
 */

/**
 * @typedef {Object} Plan
 * @property {String} name
 * @property {Pricing} pricing
 * @property {Number} collaborators - how many ppl can have User in his projects
 * @property {Number} projects - how many projects can have User
 * @property {Number} configurations - how many configurations can have User
 * @property {Status} status
 * @property {Boolean} loading
 * @property {Boolean} error
 * @property {Boolean} loaded
 */

/**
 * @typedef {Object} SystemVersion
 * @property {String|null} version
 * @property {Boolean} alertShown, indicates if alert about version change was shown
 */

/**
 * @typedef {Object} SysState
 * @property {SystemVersion} systemVersion
  * @property {Object} user
 * @property {Msg[]} messages
 * @property {Plan} myPlan
 * @property {Object} plan
 * @property {Array} notifications
 */


export default {
  namespaced: true,
  /**
   * @return {SysState}
   */
  state: () => ({
    /** @type SystemVersion **/
    systemVersion: {},
    /** @type {UserSerializer} **/
    user: {},
    /** @type {Msg[]} **/
    messages: window.messages || [],
    /** @type {Plan} **/
    myPlan: {
      loading: true,
      error: false,
      loaded: false,
      name: 'Community',
      pricing: {
        currency: 'USD',
        amount: 0.0,
        interval: 'month',
      },
    },
    plan: {
      list: [], // all plans
      interval: 'year', // selected plan interval
      html: '',
    },
    notifications: [
      {field: 'experiment_run', checked: false, text: 'Someone runs experiment for a project I\'m assigned to'},
      {field: 'experiment_done', checked: false, text: 'Experiment is over and results are ready'},
      {field: 'new_definition', checked: false, text: 'New experiment definition version is created'},
      {field: 'new_tag', checked: false, text: 'New tag has been defined'},
      {
        field: 'configurations_limit',
        checked: false,
        text: '80% of available configurations is consumed',
      },
    ],
    // Based on this we'll determine to show or not link to the account management page
    isAccountOwner: null,
    /** @type {Record<string, AutoQueue>} **/
    taskQueues: {}
  }),

  mutations: {
    /**
     * @param {SysState} state
     * @param {Msg} payload
     */
    pushMessage(state, payload) {
      payload.level = payload.level || 'default';
      state.messages.push(payload);
    },
    notifyDanger(state, msg) {
      state.messages.push({msg, level: 'danger'});
    },
    notifyCopy(state, msg = 'Copied!') {
      state.messages.push({msg, level: 'info', type: MSG_TYPES.COPY});
    },
    notifySuccess(state, msg) {
      state.messages.push({msg, level: 'success'});
    },
    /**
     * @param {SysState} state
     * @param {Number} idx
     */
    removeMessage(state, idx = 0) {
      state.messages = [...state.messages.slice(0, idx), ...state.messages.slice(idx + 1)];
    },

    /**
     * @param {SysState} state
     * @param {Object} value
     */
    updateMyPlan(state, value) {
      state.myPlan = {...state.myPlan, ...value};
    },

    /**
     * @param {SysState} state
     * @param {Object} value
     */
    updatePlan(state, value) {
      state.plan = {...state.plan, ...value};
    },

    /**
     * @param {SysState} state
     * @param {Object} data
     */
    setUserData(state, data) {
      state.user = data;
    },

    /**
     * @param {SysState} state
     * @param {Object} data
     */
    setUserAvatar(state, data) {
      state.user.avatar = data.avatar;
    },

    /**
     * @param {SysState} state
     * @param {Object} data
     */
    setUserNotifications(state, data) {
      for (const [key, value] of Object.entries(data)) {
        const result = state.notifications.find((item) => item.field === key);
        if (result) {
          result.checked = value;
        }
      }
    },

    /**
     * @param {SysState} state
     * @param {String} key
     * @param {Object} kwargs
     */
    setKey(state, {key, ...kwargs}) {
      for (let [innerKey, value] of Object.entries(kwargs)) {
        state[key][innerKey] = value;
      }
    },

    /**
     * @param {SysState} state
     * @param {SystemVersion} version
     */
    updateSystemVersion(state, version) {
      state.systemVersion = {...state.systemVersion, ...version};
    },

    awakeTask(state, name) {
      const worker = state.taskQueues[name];
      if (worker) {
        worker.awake();
      }
    }
  },

  getters: {
    isEnterprise(state) {
      return state.myPlan.variant === 'enterprise';
    },
  },

  actions: {
    /**
     * @param commit
     */
    async fetchUser({commit}) {
      const [response, error] = await requestAction('get', apiUrl.me);
      if (response) {
        commit('setUserData', response.data);
      }
      return [response, error];
    },

    /**
     * @param commit
     * @param {Object} data
     */
    async updateUserField({commit}, data) {
      const response = await api.account.selfChange(data);
      commit('setUserData', response.data);
    },

    /**
     * @param commit
     * @param {Object} data
     */
    async uploadAvatar({commit}, data) {
      const response = await api.users.uploadAvatar(data);
      commit('setUserAvatar', response.data);
    },

    /**
     * @param context
     * @param {Object} data
     */
    async changePassword(context, data) {
      const [response, error] = await requestAction('post', apiUrl.changePassword, {
        data: data,
      });
      return [response, error];
    },

    /**
     * @param context
     * @param {Object} data
     */
    async reLogin(context, data) {
      const [response, error] = await requestAction('post', apiUrl.login, {
        data: data,
      });
      return [response, error];
    },

    /**
     * @param commit
     * @param {Object} data
     */
    async fetchNotifications({commit}, data) {
      const [response, error] = await requestAction('get', apiUrl.userNotifications, {
        data: data,
      });
      if (response) {
        commit('setUserNotifications', response.data.notification);
      }
      return [response, error];
    },

    /**
     * @param commit
     * @param {Object} data
     */
    async updateUserNotification({commit}, data) {
      const [response, error] = await requestAction('patch', apiUrl.userNotifications, {
        data: data,
      });
      if (response) {
        commit('setUserNotifications', response.data.notification);
      }
      return [response, error];
    },

    /**
     * @param {SysState} state
     * @param commit
     */
    async fetchMyPlan({state, commit}) {
      const {myPlan} = state;
      if (myPlan.loaded) {
        return;
      }

      const [response, error] = await requestAction('get', apiUrl.myPlan);
      if (error) {
        console.warn(error.detail);
        commit('updateMyPlan', {loading: false, error: true});
      }
      commit('updateMyPlan', {...response.data, loading: false, error: false, loaded: true});
    },

    /**
     * @param {SysState} state
     * @param commit
     */
    async fetchPlanListHTML({state, commit}) {
      const [response, error] = await requestAction('get', apiUrl.planListHTML);
      if (response) {
        commit('updatePlan', {html: response.data});
      } else {
        console.error(error);
      }
    },

    /**
     * @param {SysState} state
     * @param commit
     */
    async checkSystemVersion({state, commit}) {
      const [response, ] = await requestAction('get', apiUrl.systemVersion);
      if (response) {
        if (isEmpty(state.systemVersion)) {
          commit('updateSystemVersion', response.data);
          return;
        }
        const versionChanged = state.systemVersion.version !== response.data.version;
        if (versionChanged && !state.systemVersion.alertShown) {
          commit('pushMessage', {
            msg: 'Service version has changed, please click here to reload page',
            level: 'warning',
            type: MSG_TYPES.RELOAD,
            toastOptions: {noAutoHide: true},
          });
          commit('updateSystemVersion', {alertShown: true});
        }
      }
    },

    async fetchIsAccountOwner({state, commit}) {
      // already fetched
      if (state.isAccountOwner !== null) {
        return;
      }
      const [, error] = await requestAction('HEAD', apiUrl.planAccounts);
      // if user is not account owner, permission denied will be raised
      state.isAccountOwner = !error;
    },

    async patchMe({commit}, data) {
      const [response, error] = await requestAction('patch', apiUrl.me, {data});
      if (response) {
        commit('member/setMember', response.data, {root: true});
      }
      return [response, error];
    },

    /**
     * Adds a periodic task to registry. If task already exists - stops it.
     * @param state
     * @param commit
     * @param {string} name a unique task name
     * @param {function(*): *} cb callback
     * @param {number} ms delay between `cb` calls
     * @param {boolean} [immediate] run task right after adding to queue
     * @returns {Promise<AutoQueue>}
     */
    async addTask({state, commit}, {name, cb, ms, immediate}) {
      // if a worker exists, stop previous task else create a new worker
      console.debug(`task "${name}" runs every ${ms} ms.`);
      let worker = state.taskQueues[name];
      if (worker) {
        await worker.stop();
      } else {
        worker = new AutoQueue({repeat: true, delay: ms, immediate});
      }
      worker.add(cb);
      state.taskQueues[name] = worker;
      return worker;
    },

    async delTask({state, commit}, name) {
      console.debug(`task ${name} removed.`);
      const worker = state.taskQueues[name];
      if (worker) {
        await worker.stop();
        delete state.taskQueues[name];
      }
    },

    async pauseTask({state, commit}, name) {
      const worker = state.taskQueues[name];
      if (worker) {
        await worker.pause();
      }
    },

    async startTask({state, commit}, name) {
      const worker = state.taskQueues[name];
      if (worker) {
        await worker.start();
      }
    },
  },
};
