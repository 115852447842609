import {sleep} from '@/utils/time';

class Queue {
  /**
   * LIFO queue
   */
  constructor() {
    this._items = [];
  }

  /**
   * Number of tasks
   * @returns {number}
   */
  get size() {
    return this._items.length;
  }

  /**
   * Add task to queue
   * @param item
   */
  enqueue(item) {
    this._items.push(item);
  }

  /**
   * Remove task from queue
   * @returns {*}
   */
  dequeue() {
    return this._items.shift();
  }

  /**
   * Empty task queue
   */
  clear() {
    this._items = [];
  }
}

export default class AutoQueue extends Queue {
  /**
   * LIFO task runner
   * @link https://stackoverflow.com/a/63208885
   * @param {boolean} [repeat] add task back to queue
   * @param {number} [delay] delay run next task in ms
   * @param {boolean} [immediate] run task right after addition it to queue, if set to false task will be executed
   *  after delay
   */
  constructor({repeat, delay, immediate}) {
    super();
    this._pendingPromise = false;
    this._pause = false;
    this._stop = false;
    this.repeat = repeat;
    this.delay = delay;
    this.immediate = typeof immediate === 'undefined';
  }

  /**
   * Adds a tasks to queue and start execution
   * @param {Promise} action
   * @returns {Promise<unknown>}
   */
  add(action) {
    return new Promise((resolve, reject) => {
      super.enqueue({action, resolve, reject});
      this.run();
    });
  }

  /**
   * Starts previously stopped auto queue
   * @returns {Promise<*>}
   */
  async start() {
    this._pause = false;
    this._stop = false;
    return await this.run();
  }

  /**
   * Pauses task execution (Note: after current task is done)
   */
  async pause() {
    this._pause = true;
    await this.waitTask();
  }

  /**
   * Stops and clears queued tasks (Note: after current task is done)
   */
  async stop() {
    this._stop = true;
    await this.waitTask();
  }

  /**
   * Runs until `_pendingPromise` flag is set to `true`
   * @returns {Promise<void>}
   */
  async waitTask() {
    // Don't know why `for` instead of `while`.
    for (; ;) {
      if (!this._pendingPromise) {
        return;
      }
      await sleep(5);
    }
  }

  /**
   * Sleeps for a `delay` pausing task execution.
   * @returns {Promise<void>}
   */
  async sleep() {
    if (!this.delay) {
      return;
    }
    this._pause = true;
    // Check each 200ms if `_pause` flag is set to `false`.
    // if set - stop waiting and run next task.
    let slept = 0;
    while (this._pause && slept < this.delay) {
      await sleep(200);
      slept += 200;
    }
    this._pause = false;
  }

  /**
   * Reverse of `sleep` method, just sets `_pause` flag to `false`.
   * The sleep will check the flag and run next task.
   * @returns {Promise<void>}
   */
  awake() {
    this._pause = false;
  }


  /**
   * Executes tasks from queue one by one, before running next task checks if task execution stopped or halted.
   * @returns {Promise<boolean>}
   */
  async run() {
    if (!this.immediate) {
      await this.sleep();
    }
    if (this._pendingPromise || this._pause) {
      return false;
    }
    if (this._stop) {
      this._pause = false;
      this._stop = false;
      this.clear();
      return false;
    }

    let item = super.dequeue();
    if (!item) {
      return false;
    }

    try {
      this._pendingPromise = true;
      let payload = await item.action(this);
      this._pendingPromise = false;

      item.resolve(payload);
    } catch (e) {
      this._pendingPromise = false;
      item.reject(e);
    } finally {
      if (this.immediate) {
        await this.sleep();
      }
      if (this.repeat) {
        super.enqueue(item);
      }
      this.run();
    }

    return true;
  }
}
