/**
 * OLD DON'T ADD NEW ENDPOINTS GO TO api/index.js
 */

import axios from 'axios';

/**
 * @typedef {object} window.preloaded.urls
 */
// roots
const PROJECTS = '/api/projects';
const HINTS = '/api/hints/:hintName';
const PROJECT = `${PROJECTS}/:projectId`;
const DEFINITIONS = `${PROJECT}/experiment-definitions`;
const DEFINITION = `${DEFINITIONS}/:definitionId`;
const EXPERIMENTS = `${PROJECT}/experiments`;
const EXPERIMENT = `${EXPERIMENTS}/:experimentId`;
const SAMPLES = `${EXPERIMENT}/samples`;
const CONFIGURATIONS = `${EXPERIMENT}/configurations`;
const CONFIGURATION = `${CONFIGURATIONS}/:id`;
const FILTERS = `${EXPERIMENT}/filters`;
const FILTER = `${FILTERS}/:filterId`;
const ACCOUNTS = '/api/accounts';
const ME = `${ACCOUNTS}/user`;
const PLAN = '/api/plan';
const TEMPLATES = '/api/templates';
const EVENTS = '/api/events';
const USERS = '/api/users';

const apiUrl = {
  hints: `${HINTS}/`,
  projects: `${PROJECTS}/`,
  project: `${PROJECT}/`,
  totalExperiments: `${PROJECT}/total-experiments/`,
  projectSummary: `${PROJECT}/summary/`,
  projectAccountAccess: `${PROJECT}/account-access/`,

  templates: `${TEMPLATES}/`,
  wizard: `${PROJECT}/wizard/`,
  wizardTemplates: `${PROJECTS}/wizard-templates/`,

  members: `${PROJECT}/members/`,
  memberDetails: `${PROJECT}/members/:memberId/`,
  membersInvite: '/api/projects/:projectId/members/invite/',
  transferOwnership: `${PROJECT}/members/transfer-ownership/`,

  events: `${EVENTS}/`,
  eventsMarkAsRead: `${EVENTS}/mark-as-read/`,

  definitions: `${DEFINITIONS}/`,
  definition: `${DEFINITION}/`,
  definitionTags: `${DEFINITION}/tags/`,
  definitionFiles: `${DEFINITION}/files/`,
  definitionSamples: `${DEFINITION}/count/`,
  definitionsSamples: `${DEFINITIONS}/count/`,

  experiments: `${EXPERIMENTS}/`,
  experiment: `${EXPERIMENT}/`,
  experimentTags: `${EXPERIMENT}/tags/`,
  experimentsTrack: `${EXPERIMENTS}/track/`,
  experimentMetadata: `${EXPERIMENT}/metadata/`,
  experimentInventory: `${EXPERIMENT}/inventory/`,
  experimentDownloadableLinks: `${EXPERIMENT}/downloadable/`,

  experimentFilters: `${FILTERS}/`,
  experimentFilter: `${FILTER}/`,

  samples: `${SAMPLES}/`,
  samplesCount: `${SAMPLES}/count/`,
  statistics: `${SAMPLES}/statistics/`,

  configurations: `${CONFIGURATIONS}/`,
  configuration: `${CONFIGURATION}/`,
  configurationDownload: `${CONFIGURATION}/download/`,

  registration: `${ACCOUNTS}/register/`,
  verifyEmail: `${ACCOUNTS}/verify-registration/`,
  resendVerification: `${ACCOUNTS}/resend-verification-link/`,
  verifyInvite: `${ACCOUNTS}/verify-invite/`,
  sendResetPasswordLink: `${ACCOUNTS}/send-reset-password-link/`,
  resetPassword: `${ACCOUNTS}/reset-password/`,
  login: `${ACCOUNTS}/login/`,
  logout: `${ACCOUNTS}/logout/`,
  me: `${ACCOUNTS}/user/`,
  changePassword: `${ACCOUNTS}/change-password/`,

  userNotifications: `${ME}/notifications/`,

  userAvatar: `${USERS}/upload-user-avatar/`,

  systemVersion: '/api/system/version/',

  pricing: `${PLAN}`,
  myPlan: `${PLAN}/my/`,
  orders: `${PLAN}/orders/`,
  purchase: `${PLAN}/purchase/`,
  planListHTML: '/plan/list/',
  getManagementUrl: `${PLAN}/sso/`,
  planRoles: `${PLAN}/roles/`,
  planRole: `${PLAN}/roles/:id/`,
  planUsers: `${PLAN}/roles/users/`,
  planAccounts: `${PLAN}/accounts/`,
  planAccount: `${PLAN}/accounts/:id/`,

  ...(window.preloaded?.urls || {}),
};

const requests = axios.create({
  xsrfHeaderName: 'X-CSRFToken',
  xsrfCookieName: 'csrftoken',
});

window._axios = requests;

export {requests, apiUrl};
