const HINT_COOCKIE = 'alert_:hintName_closed';
const REPORT_COOKIE = 'reportDataExclude';
const SENSOR_FIELDS_COOKIE = 'selectedSensorFields';

/**
 * Sets cookie
 * @param {string} name
 * @param {string} value
 * @param {{path: string; expires: string}} [options]
 */
function setCookie(name, value, options = {}) {
  options = {
    path: '/',
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += '; ' + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }
  document.cookie = updatedCookie;
}

/**
 * Retrieves a cookie by name
 * @param {string} name
 * @returns {string|undefined}
 */
function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
    `(?:^|; )${ name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') }=([^;]*)`,
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export {setCookie, getCookie, HINT_COOCKIE, REPORT_COOKIE, SENSOR_FIELDS_COOKIE};
