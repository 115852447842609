import {isPlainObject, mapValues} from 'lodash';
import {RGX_EMAIL} from '@/utils/validators';

/**
 * Recursively map string values of object with given callback function
 * @param {Object} o
 * @param {Function} f
 * @return {Record<any, any>}
 */
export function mapStringValues(o, f) {
  return mapValues(o, (v) => {
    return typeof v === 'object' ? mapStringValues(v, f) : f(v);
  });
}

/**
 * @template T
 * @param {T} obj
 * @return {Readonly<T>}
 */
export function refreeze(obj) {
  if (isPlainObject(obj)) {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!Object.isFrozen(value)) {
        refreeze(value);
      }
    });
  }
  if (Array.isArray(obj)) {
    obj.forEach(refreeze);
  }
  if (!Object.isFrozen(obj)) {
    Object.freeze(obj);
  }
  return obj;
}

/**
 * Converts an object into sorted Map
 * @param {object} object
 * @returns {Map<any, any>}
 */
export function sortObject(object) {
  let sorted = new Map();
  if (typeof object === 'object' && !(object instanceof Array)) {
    for (const [key, value] of Object.entries(object).sort()) {
      sorted.set(key, sortObject(value));
    }
  } else if (object instanceof Array) {
    sorted = [];
    for (const item of [...object].sort()) {
      sorted.push(sortObject(item));
    }
  } else {
    sorted = object;
  }
  return sorted;
}

/**
 * Process user data. Validate email.
 * Check whether user already is member of entity, it it is then take user from members list.
 * @param {string} name user fullname or email
 * @param {string} role user role
 * @param {array.<ReportMemberSerializer[]|Member[]>} members array of existing members
 * @param {{direct: boolean}|{}} additionalData additional user information
 * @returns {*|{}}
 */
export function processUserData(name, role, members, additionalData) {
  const data = additionalData || {};
  const userData = {};
  if (RGX_EMAIL.test(String(name).toLowerCase())) {
    userData.email = name;
    data.user = userData;
    data.role = role;
  } else {
    const fullName = name.split(' ');
    const user = members.filter((el) => {
      return el.first_name === fullName[0] && el.last_name === fullName[1];
    });
    if (user.length > 0) {
      userData.email = user[0].email;
      data.user = userData;
      data.role = role;
    }
  }
  return data;
}

/**
 * Similar to the `Object.assign` but calls setattr or whatever it's in js
 * @param {object} target
 * @param {object} source
 * @example
 *  # resetting component state
 *  setAttrs(this, data);
 */
export function setAttrs(target, source) {
  Object.entries(source).forEach(([key, value]) => {
    target[key] = value;
  });
}
