import {groupBy, isObject, maxBy, minBy} from 'lodash';
import {jStat} from 'jstat';
import {DECIMAL_SUBST, DEFAULT_KNOB_REGEX, HEXADECIMAL_SUBST, patternRegex} from '@/utils/regex';
import {namedtuple, zip} from '@/utils/array';
import {refreeze} from '@/utils/object';
import {rehydrate, requestAction} from '@/api/helpers';
import {apiUrl} from '@/api/client';
import api from '@/api';
import {
  BEST_CONFIG_VALUE,
  CONFIG_STATE,
  FILTER_TYPE,
  NUM_OF_NON_BASELINE_KNOBS,
  SCATTER_X_DEFAULT,
  SCATTER_Y_EXCLUDE,
  Stages,
} from '@/constants';
import {AXIS_META, AXIS_TYPES, metricToOption, PointsDivider} from '@/utils/vis';

export const MODE = Object.freeze({
  SAMPLES: 1,
  CONFIGURATIONS: 2,
});
export const STATUS = Object.freeze({
  NOT_READY: 1,
  INITIALIZED: 2,
  REHYDRATED: 3,
  PLAIN_READY: 4,
  MERGE_READY: 5,
  STATISTICS_READY: 6,

  NO_SAMPLES: -1,
  LIMIT_REACHED: -2,
  ERROR: -3,
});
export const STATISTICS = Object.freeze({
  BASELINE: 0, // only baseline samples
  TUNED: 1, // only tuned (not baseline) samples
  ALL: 2, // include in statistics calc both tuned and baseline samples
  READINESS: 3, // only readiness stage samples
});

const makeStatus = (v) => {
  let value = v;
  let text = '';
  if (isObject(v)) {
    value = v.value;
    text = v.text || '';
  }
  return {value, text, updatedAt: +new Date()};
};
// api sample return
const sampleKeys = [
  'metrics', 'stage', 'configuration_id', 'state', 'is_baseline', 'metadata', 'count', BEST_CONFIG_VALUE,
];
export const Sample = namedtuple(...sampleKeys);
// api configuration return
const confKeys = ['id', 'knobs', 'mean', 'stdev', 'cv_of_mean', 'state', 'updated_at'];
export const Configuration = namedtuple(...confKeys);
// each series consist of this points
const Point = namedtuple('x', 'y', 'sample');

export const Knob = namedtuple('value', 'isBaseline');

const getStatistics = () => ({
  min: {},
  max: {},
  mean: {},
  stdev: {},
  cv_of_mean: {},
  total: 0,
  valid: 0,
  failed: 0,
  target_value: 0,
  estimator: 'mean',
  target: '',
  state: '',
  progress: 0,
  improvement: 0,
  baseline_target: 0,
  num_samples_valid: 0,
  anticipated_target: 0,
  num_samples_invalid: 0,
  num_configs_explored: 0,
  current_stage: '',
});

/**
 * Constructor for stage axes.
 * @param {String} [accessor]
 * @param {Array} configurationsOptions
 * @param {Array} samplesOptions
 */
function AXIS(accessor = null, samplesOptions = [], configurationsOptions = []) {
  this.accessor = accessor;
  this.opts = {
    [MODE.SAMPLES]: refreeze(samplesOptions),
    [MODE.CONFIGURATIONS]: refreeze(configurationsOptions),
  };
}

/**
 * @return {CacheableState}
 */
const cacheable = () => ({
  mode: MODE.CONFIGURATIONS,
  yAxis: new AXIS(),
  xAxis: new AXIS(),
  axisTypes: {},
  // this user can change
  pareto: {x: 0, y: 0},
  statistics: {
    [STATISTICS.BASELINE]: getStatistics(),
    [STATISTICS.TUNED]: getStatistics(),
    [STATISTICS.ALL]: getStatistics(),
    [STATISTICS.READINESS]: getStatistics(),
  },

  lastConfigurationTimestamp: null,

  // non mutable entries
  configurations: refreeze({}),
  // THIS DATASET MAY CONTAIN INVALID (old, not updated) `state`, IF YOU NEED EXACT STATE OF CONFIGURATION GET
  // `configuration_id` AND LOOKUP IN THE `state.configurations`.
  data: refreeze({keys: [], values: []}),
  grouped: refreeze({keys: [], values: []}),
  defaultPareto: refreeze({x: 0, y: 0}),
  /** @type {Record<number, number>}
   * calling setGroupedSamples will populate this object,
   * key is configuration id, value is how many samples is in the configuration.
   **/
  perConfPopulation: {},
});
const FROZEN_KEYS = ['defaultPareto', 'data', 'grouped', 'configurations'];

const resetable = () => ({
  experiment: null,
  project: null,
  /** @type ExperimentMetadata **/
  metadata: refreeze({
    knobs: [],
  }),
  status: {
    value: STATUS.NOT_READY,
    updatedAt: +new Date(),
    text: '',
  },
  // show overlay on samples plot
  // here instead of emitting an even on $root and listen because I don't see an easy way
  // to no bind such listener more than once.
  overlay: false,
  isInitialSamplesFetching: false,
});

const getUrlCtx = (state) => ({projectId: state.project, experimentId: state.experiment});

const getPareto = (meta) => ({
  x: 0,
  y: Number(meta.target_metric_goal === 'max'),
});

export default {
  namespaced: true,
  /** @returns ActiveState **/
  state: () => ({
    cached: {},
    ...resetable(),
    ...cacheable(),
  }),

  mutations: {
    /**
     * Puts root state into cache and resets root state
     * @param state
     */
    dehydrate(state) {
      if (!state.experiment) {
        return;
      }
      const cache = {};
      Object.keys(cacheable()).forEach((key) => {
        cache[key] = state[key];
      });
      state.cached[state.experiment] = JSON.stringify(cache);
      // reset everything except cached key.
      Object.assign(state, {
        ...resetable(),
        ...cacheable(),
      });
    },

    /**
     * Takes data from cache and assigns to the root state
     * @param {ActiveState} state
     * @param {Object} payload - any extra data you want add to the rehydrated state
     */
    rehydrate(state, payload = {}) {
      // start from initial values
      const newState = cacheable();
      if (state.experiment in state.cached) {
        // if we have something cached, add it
        Object.assign(newState, JSON.parse(state.cached[state.experiment]));
      }
      // overwrite if necessary
      Object.assign(newState, payload);
      // rehydrate the root state with whatever we got
      Object.entries(newState).forEach(([key, value]) => {
        if (['grouped', 'data'].includes(key)) {
          value = {
            keys: Sample(value.keys),
            values: value.values,
          };
        }
        if (FROZEN_KEYS.includes(key)) {
          this._vm.$set(state, key, refreeze(value));
        } else {
          this._vm.$set(state, key, value);
        }
      });

      const hasPlain = state.data.values.length > 0;
      const hasMerge = state.grouped.values.length > 0;
      const hasStatistics = state.statistics[STATISTICS.ALL].total > 0;
      const status = hasStatistics ? STATUS.STATISTICS_READY
        : hasMerge ? STATUS.MERGE_READY
          : hasPlain ? STATUS.PLAIN_READY
            : STATUS.REHYDRATED;
      state.status = makeStatus(status);
    },

    /**
     * Set of variables to be set before using the store
     * @param {ActiveState} state
     * @param {string} project
     * @param {string} experiment
     */
    use(state, {project, experiment}) {
      state.project = project;
      state.experiment = experiment;
      state.status = makeStatus(STATUS.INITIALIZED);
    },

    /**
     * @param {ActiveState} state
     */
    initializeAxisTypes(state) {
      if (Object.keys(state.axisTypes).length > 0) {
        return;
      }
      // optimization stage in configuration mode keeps the most complete list of metrics
      const xAxisOpts = state.xAxis.opts[MODE.CONFIGURATIONS];
      const yAxisOpts = state.yAxis.opts[MODE.CONFIGURATIONS];
      const metricTypes = [...xAxisOpts, ...yAxisOpts].reduce((pv, cv) => {
        pv[cv.value] = AXIS_META.getByName(cv.value).type;
        return pv;
      }, {});
      const knobTypes = state.metadata.knobs.reduce((pv, cv) => {
        pv[cv.name] = AXIS_TYPES.CATEGORY;
        return pv;
      }, {});
      state.axisTypes = refreeze({...metricTypes, ...knobTypes});
    },

    /**
     * Groups samples by its configuration
     * @param {ActiveState} state
     */
    setGroupedSamples(state) {
      const data = state.data;
      const metricKeys = [...data.keys.metrics, NUM_OF_NON_BASELINE_KNOBS];
      const keys = Sample([metricKeys, ...data.keys.slice(1, data.keys.length), BEST_CONFIG_VALUE]);

      // Edge case when an experiment has no configurations, may happen if someone manually change experiment uuid
      // in admin panel.
      if (Object.keys(state.configurations).length === 0) {
        state.grouped = refreeze({keys, values: []});
        state.status = makeStatus(STATUS.MERGE_READY);
        return;
      }

      // used to find best sample value (t.metric)
      const targetFunctionsMap = {
        'min': minBy,
        'max': maxBy,
      };
      const target = state.metadata.target_metric_goal;
      const targetMetricIndex = state.data.keys.metrics.indexOf(state.metadata.target_metric);
      const timestampMetricIndex = state.data.keys.metrics.indexOf('timestamp');
      const indexOfIndex = state.data.keys.metrics.indexOf('index');
      const configIDs = Object.keys(state.configurations).sort();
      const perConfPopulation = {};
      const pointEstimator = state.metadata.point_estimator;

      const values = Object.entries(groupBy(data.values, (v) => v[2])).map(
        ([confId, confSamples]) => {
          /** @type {number[]} **/
          const metrics = zip(...confSamples.map((s) => s[0])).map((values, index) => {
            // For timestamp metrics return the timestamp of the first sample instead of average.
            // Usually we have timestamp metric but even if we don't, `timestampMetricIndex` will have -1 so `if`
            // will never be true.
            if (index === timestampMetricIndex) {
              return values[0];
            }
            // Calculate average value as percentile if pointEstimator is object
            if (isObject(pointEstimator) && Number.isInteger(pointEstimator.percentile)) {
              return jStat.percentile(values, pointEstimator.percentile / 100);
            }
            // Case if pointEstimator is string
            switch (pointEstimator) {
              case 'average': {
                return jStat(values).mean();
              }
              case 'median': {
                return jStat(values).median();
              }
              case 'mode': {
                // jStat.mode return array if it has several modes, so we need to fetch average values of that array
                const result = jStat(values).mode();
                return Array.isArray(result) ? jStat(result).mean() : result;
              }
              default: {
                console.warn('PE is missing on experiment metadata');
                return jStat(values).mean();
              }
            }
          });
          const count = confSamples.length;
          perConfPopulation[confId] = count;
          let numberOfNonBaselineKnobs = 0;
          let config = {};
          let tMetricBestValue = null;
          try {
            // looking for sample with the best target metric value, then save this value into tMetricBestValue var
            const lookupFunc = targetFunctionsMap[target];
            tMetricBestValue = lookupFunc(confSamples, (s) => s[0][targetMetricIndex])[0][targetMetricIndex];
          } catch (e) {
            // experiment has no metadata
          }
          try {
            config = Configuration(state.configurations[confId]);
            numberOfNonBaselineKnobs = config.knobs.filter((knob) => !knob.isBaseline).length;
          } catch (e) {
            // sometimes a sample arrives before its configuration
            // on next call we'll re-check again and set num of non baseline knobs.
          }
          metrics.push(numberOfNonBaselineKnobs);
          metrics[indexOfIndex] = (configIDs.indexOf(confId) + 1);
          const [, stage, configuration_id, configuration_state, is_baseline, metadata] = confSamples[0];
          // TODO: on update samples are fetched by offset and fetched samples are merged into what we have.
          //  but state of an already fetched sample may change. It affects only CONFIGURATIONS view because we actually
          //  show visually in what state sample is. So here I first try to get state from the state.configurations
          //  this dict gets regular updates and holds exact state we have on backend. If configuration is not available
          //  yet we'll default to the state we have received previously with the samples payload.
          //  Ideally we need remove the state from here and if we need show state we need lookup in
          //  `state.configurations` by `configuration_id`. But because arrays are everywhere it isn't fast and easy
          //  to do so.
          const configState = config.state || configuration_state;
          return [
            metrics, stage, configuration_id, configState, is_baseline, metadata, count, tMetricBestValue,
          ];
        },
      );
      state.grouped = refreeze({keys, values});
      state.perConfPopulation = refreeze(perConfPopulation);
    },

    /**
     * This handles first insert and subsequent inserts
     * On first sets additional variables: list of metrics, pareto config, x/yAxis
     * On next just updates values
     * @param {ActiveState} state
     * @param {string[]} keys
     * @param {SampleGraphValues} data
     */
    insertSamples(state, [keys, ...data]) {
      // Add 1 at the end, it's the count value, count determine how many samples participated to generate this one
      // this mutation inserts "plain" samples or samples "as is". This allows us to have a consistent api.
      // See the `setGroupedSamples` mutation to see what the `count` is actually is (the `aggregated` variable)
      //
      // NOTE: ... is safe if we have less than 150k elements. This place probably the largest spread op push and so far
      // it always no more than 4k elements.
      const indexOfIndex = keys[0].indexOf('index');
      const numOfSamples = state.data.values.length;
      const values = data.map((value, index) => {
        value[0][indexOfIndex] = numOfSamples + (index + 1);
        return [...value, 1];
      });
      // so you can do this, sample.state.data.keys.metrics => list of metrics
      // for other keys it will be keys.stage => 'stage'
      keys = Sample([...keys, 'count']);

      if (state.data.values.length > 0) {
        state.data = refreeze({
          keys,
          values: [...state.data.values, ...values],
        });
        return;
      }

      Object.assign(state, {
        pareto: getPareto(state.metadata),
        defaultPareto: refreeze(getPareto(state.metadata)),
        data: refreeze({keys, values}),
      });
    },

    /**
     * @param {ActiveState} state
     * @param {ConfigurationStream} configurations
     */
    insertConfigurations(state, configurations) {
      if (configurations.length === 0) {
        return;
      }

      const latestConfiguration = Configuration(configurations[configurations.length - 1]);
      state.lastConfigurationTimestamp = latestConfiguration['updated_at'];

      const configurationLookup = configurations.reduce((pv, cv) => {
        const config = Configuration(cv);
        // map knobs with Knob namedtuple in order to have access to values by keys
        cv[1] = config.knobs.map((knob) => Knob(knob));
        pv[config['id']] = cv;
        return pv;
      }, {});

      state.configurations = refreeze({
        ...state.configurations,
        ...configurationLookup,
      });
    },

    /**
     * Sets axis accessor to a value (where value is metric name).
     * @param {ActiveState} state
     * @param {('x'|'y')} axis
     * @param {String} value
     */
    setAxis(state, {axis, value}) {
      state[`${axis}Axis`].accessor = value;
    },

    /**
     * Keeps the state[x/yAxis].opts unique as if it would be for the Set type but also ordered.
     * Accepts a single or multiple (as array of strings) options.
     * @param {ActiveState} state
     */
    initializeAxis(state) {
      // optimization stage in configuration mode keeps the most complete list of metrics
      const xAxisOpts = state.xAxis.opts[MODE.CONFIGURATIONS];
      const yAxisOpts = state.yAxis.opts[MODE.CONFIGURATIONS];

      if (xAxisOpts.length > 0 && yAxisOpts.length > 0) {
        return;
      }

      // axis default and axis options calc
      const metrics = state.data.keys[0];

      const target = state.metadata.target_metric;
      // prefix target metric name with [PE] in axis select box.
      const convertToSelectOptions = (value) => metricToOption(value, target);

      const knobs = state.metadata.knobs.map(({name}) => name);

      const xOptions = [...metrics, ...knobs].map(convertToSelectOptions);
      const xConfigurationOptions = [convertToSelectOptions(NUM_OF_NON_BASELINE_KNOBS), ...xOptions];
      const yOptions = metrics.filter((opt) => SCATTER_Y_EXCLUDE.indexOf(opt) === -1).map(convertToSelectOptions);

      const xAxis = SCATTER_X_DEFAULT;
      const yAxis = target || yOptions[0].value;

      Object.assign(state, {
        xAxis: new AXIS(xAxis, xOptions, xConfigurationOptions),
        yAxis: new AXIS(yAxis, yOptions, yOptions),
      });
    },

    /**
     * Sets pareto, 1 is for max, 0 is for min. So you have 4 pre-mutations together with axis.
     * @param {ActiveState} state
     * @param {('x' | 'y')} axis
     * @param {(1 | 0)} value
     */
    setPareto(state, {axis, value}) {
      state.pareto[axis] = value;
    },

    /**
     * Sets status value and time when the status changed so vue can observe changes even if status changes to a
     * same value.
     * @param {ActiveState} state
     * @param {STATUS} status
     */
    setStatus(state, status) {
      state.status = makeStatus(status);
    },

    /**
     * Two statistics we have, readiness and optimization. The readiness statistics is for readiness stage and part of
     * validation, optimization for all stages except readiness.
     * @param {ActiveState} state
     * @param {STATISTICS} statType
     * @param {Statistics} statistics
     */
    setStatistics(state, {statType, statistics}) {
      state.statistics[statType] = statistics;
    },

    /**
     * Optimization dataset supports SAMPLE and CONFIGURATION mode.
     * In SAMPLE mode samples are shown as is, in CONFIGURATION multiple samples merged into one based on common
     * configuration.
     * @param {ActiveState} state
     * @param {MODE} mode
     */
    setMode(state, mode) {
      state.mode = mode;
    },

    setOverlay(state, payload) {
      state.overlay = payload;
    },

    /**
     * Show loading only after expand an experiment and retrieve all data from requests - getSamples,
     * getConfigurations and getStatistics (the last two may be optional).
     *
     * @param {ActiveState} state
     * @param {boolean} value
     */
    setIsInitialSamplesFetching(state, value) {
      state.isInitialSamplesFetching = value;
    },

    cloneExperimentMetadata(state) {
      // Experiment's metadata is required for filters, statistics and to find a configuration flag name.
      // So let's bind it once here and use the bound value where we need.
      // Non-cacheable value
      state.metadata = refreeze(this._modules.root.state.experiment.metadata[state.experiment] || {knobs: []});
    }
  },

  actions: {
    /**
     * Execute this when user open an experiment
     * @param {ActiveState} state
     * @param commit
     * @param {String} project - uuid
     * @param {String} experiment - uuid
     * @returns {Promise<boolean>}
     */
    async init({state, commit}, {project, experiment}) {
      commit('use', {project, experiment});
      try {
        // verify if user has access permission, after this request, if successful, a special cookie will be set
        // allowing faster permission check on backend
        const {project, experiment} = state;
        await api.samples.options(project, experiment);
      } catch (error) {
        const status = {
          value: error.status === 402 ? STATUS.LIMIT_REACHED : STATUS.ERROR,
          text: error.detail || error,
        };
        commit('setStatus', status);
        return false;
      }
      commit('rehydrate');
      return true;
    },

    /**
     * Fetches all samples for the experiment
     * @param {ActiveState} state
     * @param commit
     * @param getters
     * @return {Promise<boolean>} if new data fetched
     */
    async getSamples({state, commit, getters}) {
      let data = [];
      data = await api.samples.stream(state.experiment, state.data.values.length);
      // both, error and success response. On error set data to a default value
      // to facilitate further checks.
      const hasNewSamples = data.length > 0;
      if (hasNewSamples) {
        data[0][0].push('index');
        commit('insertSamples', data);
        // This action is called on the first request and on refreshes but initialization will be skipped if x and y
        // opts array is not empty.
        commit('initializeAxis');
        // Same for axis types, only if no type is defined yet.
        commit('initializeAxisTypes');
        commit('setStatus', STATUS.PLAIN_READY);
      } else if (state.data.values.length === 0) {
        commit('setStatus', STATUS.NO_SAMPLES);
      }
      return hasNewSamples;
    },

    /**
     * Fetches list of configurations in compressed form
     * @param {ActiveState} state
     * @param commit
     * @param {boolean} refetch - ignore lastConfigurationTimestamp and ask for all configurations
     * @returns {Promise<>}
     */
    async getConfigurations({state, commit}, {refetch} = {refetch: false}) {
      let updatedAt = undefined;
      if (!refetch && state.lastConfigurationTimestamp !== null) {
        updatedAt = state.lastConfigurationTimestamp;
      }
      const data = await api.configuration.stream(state.experiment, updatedAt);
      commit('insertConfigurations', data);
    },

    /**
     * Fetches or readiness or optimization statistics
     * @param {ActiveState} state
     * @param commit
     * @param {STATISTICS} statType
     * @returns {Promise<void>}
     */
    async getStatistics({state, commit}, statType) {
      const paramMap = {
        [STATISTICS.BASELINE]: {is_baseline: 1},
        [STATISTICS.READINESS]: {stage: Stages.readiness},
        [STATISTICS.ALL]: {},
      };
      const url = apiUrl.statistics.format(getUrlCtx(state));
      const [resp, error] = await requestAction('get', url, {params: paramMap[statType]});
      if (error) {
        return;
      }
      commit('setStatistics', {statType, statistics: resp.data});
    },
  },

  getters: {
    /**
     * @param {ActiveState} state
     */
    currentYAxis(state) {
      const axis = state.yAxis;
      return {
        accessor: axis.accessor,
        opts: axis.opts[state.mode],
      };
    },
    /**
     * @param {ActiveState} state
     */
    currentXAxis(state) {
      const axis = state.xAxis;
      return {
        accessor: axis.accessor,
        opts: axis.opts[state.mode],
      };
    },
    /**
     * @param {ActiveState} state
     * @returns {function(string): number}
     */
    parseNumberKnob(state, getters) {
      /**
       * @param {String} knobValue
       * @returns {Number|NaN}
       */
      return (knobValue) => {
        let parser = parseFloat;
        let radix = 10;
        const accessor = getters['currentXAxis'].accessor;
        let format = state.metadata.knobs.find((knob) => knob.name === accessor)?.range?.format;
        let knobValueRegex;
        if (!format) {
          // Default regex if no knob value format isn't specified
          knobValueRegex = DEFAULT_KNOB_REGEX;
        } else {
          const printfFormat = format.replace(patternRegex, '$2');
          let subst = DECIMAL_SUBST;
          // Knob value format is hexadecimal
          if (['%x', '%X'].indexOf(printfFormat) !== -1) {
            parser = parseInt;
            radix = 16;
            subst = HEXADECIMAL_SUBST;
          }
          const pattern = format.replace(patternRegex, subst);
          // Regex built using knob value format
          knobValueRegex = new RegExp(pattern, '');
        }
        let kv = parser(knobValue.replace(knobValueRegex, '$1'), radix);
        return !Number.isNaN(kv) ? kv : NaN;
      };
    },
    /**
     * This getter uses the target metric to find the best sample and return the id of its configuration
     * @param {ActiveState} state
     * @returns {Number|null}
     */
    bestConfigId(state) {
      if (!state.metadata.target_metric) {
        return null;
      }
      const targetFuncMap = {
        'max': maxBy,
        'min': minBy,
      };
      const targetMetric = state.metadata.target_metric;
      const targetMetricGoal = state.metadata.target_metric_goal;

      const data = state.mode === MODE.CONFIGURATIONS ? state.grouped : state.data;

      const indexOfTargetMetric = data.keys[0].indexOf(targetMetric);
      const indexOfConfigId = data.keys.indexOf('configuration_id');

      const targetValues = data.values.map((v) => {
        return {value: v[0][indexOfTargetMetric], configId: v[indexOfConfigId]};
      });

      return targetFuncMap[targetMetricGoal](targetValues, 'value').configId;
    },

    /**
     * A helper to check in what state we have store right now and tell user
     * @param {ActiveState} state
     * @returns {string}
     */
    statusText(state) {
      const {value: sval, text} = state.status;
      if (text) {
        return text;
      }
      if (sval <= STATUS.NO_SAMPLES) {
        if (sval === STATUS.NO_SAMPLES && state.statistics[STATISTICS.ALL].total > 0) {
          return 'All samples are invalid.';
        } else if (sval === STATUS.ERROR) {
          return 'General error.';
        } else if (sval === STATUS.LIMIT_REACHED) {
          return 'You reached your plan limit.';
        }
      }
      if (state.status.value === STATUS.REHYDRATED) {
        // usually it waits a bit here due to calculations,
        // other status go through fast, rename it to something more understandable
        return 'LOADING';
      }
      const int = state.status.value;
      const [key] = (Object.entries(STATUS).find(([key, value]) => value === int) || [int, String(int)]);
      return key.replace(/_/, ' ');
    },

    isLoading(state) {
      const int = state.status.value;
      return state.isInitialSamplesFetching || int > STATUS.NO_SAMPLES && int < STATUS.PLAIN_READY;
    },

    isEmpty(state) {
      const int = state.status.value;
      return int <= STATUS.NO_SAMPLES;
    },

    isRestricted(state) {
      const int = state.status.value;
      return int === STATUS.LIMIT_REACHED;
    },

    isFilterable(state) {
      return state.statistics[STATISTICS.ALL].target !== '';
    },

    isGrouped(state) {
      return state.mode === MODE.CONFIGURATIONS;
    },

    /**
     * Fully rehydrates a single sample. Accept dehydrated sample as argument.
     * @param {ActiveState} state
     * @returns {function(SampleAggregatedValues): CompleteSample}
     */
    getSample(state) {
      const names = (state.metadata.knobs || []).map(({name}) => name);
      const rehydrator = (values) => rehydrate(names, values, ['value', 'is_baseline']);

      return (data) => {
        const sample = Sample([...data]);

        const sampleObj = sampleKeys.reduce((pv, cv, idx) => {
          pv[cv] = sample[idx];
          return pv;
        }, {});
        sampleObj.metadata = (sampleObj.metadata || []).map(([name, value]) => ({name, value}));
        sampleObj.metrics = state.data.keys.metrics.reduce((pv, cv, idx) => {
          pv.push({name: cv, value: sample.metrics[idx]});
          return pv;
        }, []);

        const confArray = state.configurations[sample.configuration_id];
        if (confArray === undefined) {
          console.error('Configuration not found, can be due to changed experiment uuid! Sample:', sampleObj);
        } else {
          const confObj = confKeys.reduce((pv, cv, idx) => {
            pv[cv] = confArray[idx];
            return pv;
          }, {});
          confObj.knobs = rehydrator(confObj.knobs);
          sampleObj.configuration = confObj;
        }

        sampleObj.experimentId = state.experiment;
        sampleObj.projectId = state.project;
        sampleObj.configurationId = sample.configuration_id;

        return refreeze(sampleObj);
      };
    },

    /**
     * Returns count of invalid and non-converging configurations
     * @param state
     * @returns {[number, number]}
     */
    getInvalidConfigurationsCount(state) {
      const stateIndex = confKeys.indexOf('state');
      return Object.values(
        state.configurations,
      ).reduce((pv, cv) => {
        pv[0] += Number(cv[stateIndex] === CONFIG_STATE.INVALID);
        pv[1] += Number(cv[stateIndex] === CONFIG_STATE.NON_CONVERGING);
        return pv;
      }, [0, 0]);
    },

    /**
     * Each configuration contains an 2d knob array,
     * where inner array consist of two elements - knob value and is baseline boolean flag.
     * To reduce amount of transferred data by removing duplicated values it does not have knob name.
     * But array is ordered by state.metadata.knobs.
     * So you can find a knob by name like this -
     *  configurations[ id ][ 1 ][ getters['knobIndexLookup'][name] ]
     * Where id is configuration id stored in each sample, 1 is index of knob array (see confKeys) and name is
     * knob name you interested in.
     * @param state
     * @returns {Record<string, number>|{}}
     */
    knobIndexLookup(state) {
      return state.metadata.knobs.reduce((pv, cv, idx) => {
        pv[cv.name] = idx;
        return pv;
      }, {});
    },

    /**
     * @param {ActiveState} state
     */
    sampleToPoint(state, getters) {
      /**
       * @param {SampleAggregatedValues} sample
       * @returns {Array.<number, number, SampleAggregatedValues>}
       */
      const xAxis = getters['currentXAxis'].accessor;
      const yAxis = getters['currentYAxis'].accessor;
      const keys = state.mode === MODE.SAMPLES ? state.data.keys.metrics : state.grouped.keys.metrics;

      const metricXIndex = keys?.indexOf(xAxis);
      const metricYIndex = keys?.indexOf(yAxis);
      const knobXIndex = getters['knobIndexLookup'][xAxis];
      const knobYIndex = getters['knobIndexLookup'][yAxis];
      const sampleMetricsIndex = sampleKeys.indexOf('metrics');
      const sampleConfigIDIndex = sampleKeys.indexOf('configuration_id');
      const sampleStateIndex = sampleKeys.indexOf('state');
      const configStateIndex = confKeys.indexOf('state');

      return (s) => {
        // unfreeze, we need to sync `state` later
        const sample = [...s];
        let x = sample[sampleMetricsIndex][metricXIndex];
        let y = sample[sampleMetricsIndex][metricYIndex];

        // support for knob metrics (knob values as metrics)
        try {
          const config = state.configurations[sample[sampleConfigIDIndex]];
          sample[sampleStateIndex] = config[configStateIndex];
          if (x === undefined || y === undefined) {
            const knobs = config[1];
            if (x === undefined) {
              x = knobs[knobXIndex][0];
            }
            if (y === undefined) {
              y = knobs[knobYIndex][0];
            }
            // eslint-disable-next-line no-empty
          }
        } catch (e) {
          console.warn(`Can't find configuration id ${sample[sampleConfigIDIndex]} for sample ${sample}`, e);
          // samples and their configuration are fetched together, on first load samples may arrive before configs
        }

        return Point([x, y, Sample(refreeze(sample))]);
      };
    },

    /**
     * @return {Readonly<{sequence: [string,string,string,string,string], groups: Point[][]}>}
     */
    samples(state, getters, rootState, rootGetters) {
      let data;
      const confIdIndex = sampleKeys.indexOf('configuration_id');
      const confStateIndex = confKeys.indexOf('state');
      const configs = state.configurations;

      if (Object.keys(configs).length === 0) {
        // No point to proceed if there are no configurations, we'll have empty chart anyway.
        return refreeze({groups: [], sequence: []});
      }

      // Looking for configs with 'invalid' state, collecting ids.
      const invalidConfIds = Object.keys(configs).filter((key) => {
        return configs[key][confStateIndex] === CONFIG_STATE.INVALID;
      }).map(Number);

      if (state.mode === MODE.CONFIGURATIONS) {
        data = state.grouped.values.filter((el) => {
          return !invalidConfIds.includes(el[confIdIndex]);
        });
      } else {
        data = state.data.values;
      }

      const convertor = getters['sampleToPoint'];

      /** @type {Preset} **/
      const preset = rootGetters['filters/preset'];

      const points = data.filter((el) => {
        const conf = state.configurations[el[confIdIndex]] || [];
        const knobs = conf[1];
        return preset.body.every(({name, value}) => {
          const knobIndex = getters['knobIndexLookup'][name];
          const [knobValue] = knobs[knobIndex];
          return value.filter(({enabled}) => enabled).some(({type, value}) => {
            switch (type) {
              case FILTER_TYPE.SPECIFIC: {
                return value === knobValue;
              }
              case FILTER_TYPE.STRING: {
                return value.map((v) => v.trim()).indexOf(knobValue.trim()) > -1;
              }
              case FILTER_TYPE.RANGE: {
                let kv = getters['parseNumberKnob'](knobValue);
                return !Number.isNaN(kv) && (kv >= value.from && kv <= value.to);
              }
              default: {
                return false;
              }
            }
          });
        });
      }).map(convertor);
      let bestConfig = null;
      const accessor = getters['currentXAxis'].accessor;
      if (state.axisTypes[accessor] === AXIS_TYPES.CATEGORY) {
        bestConfig = getters.bestConfigId;
      }
      const divider = new PointsDivider(points, state.pareto, bestConfig);
      return refreeze({groups: divider.groups(), sequence: divider.sequence});
    },
  },
};
