import {get} from 'lodash';
import moment from 'moment-timezone';
import {formatDate, parseDate} from '@/utils/datetime';
import api from '@/api';
import {getCookie, SENSOR_FIELDS_COOKIE, setCookie} from '@/utils/cookies';

const RECENT_SEARCH_QUERIES_KEY = 'recent-search-queries';

const setInitialSensorFields = () => ([
  {label: 'PMU IDENTIFIER', key: 'pmu_identifier'},
  {label: 'ENDPOINT IDENTIFIER', key: 'endpoint_identifier'},
  {label: 'DESIGNED MIN MARGIN', key: 'designed_min_margin'},
  {label: 'MEASURED MARGIN', key: 'measured_margin'},
  {label: 'TEMPERATURE', key: 'temperature'},
  {label: 'VOLTAGE', key: 'voltage'},
  {label: 'FREQUENCY', key: 'frequency'},
]);
/**
 * @param {string} metric
 * @param {UUID | number} id
 * @returns {string}
 */
export const getStatisticsKey = (metric, id) => `${metric}-${id}`;

export default {
  namespaced: true,
  state: () => ({
    /** @type {{count: number, results: AlertSerializer[]}} **/
    searchResults: {
      count: 0,
      results: [],
    },
    query: {
      // user's 5 recent searches
      /** @type {{createdAt: string; query: string}[]} **/
      recent: JSON.parse(localStorage.getItem(RECENT_SEARCH_QUERIES_KEY) || '[]'),
    },
    /** @type SavedSearchSerializer[] **/
    savedSearches: [],
    savedLoading: true,
    // in the next three properties number is alert id
    /** @type {Record<number, RelatedProjectsSerializer[]>} **/
    alertRelatedProjects: {},
    /** @type {Record<number, AlertSerializer[]>} **/
    alertRelatedEvents: {},
    /** @type {Record<number, Record<string, SLMSamplesSerializer>>} **/
    alertSamples: {},
    alertSamplesLoading: false,
    alertSamplesError: false,
    /** @type {AlertQuerySerializer} **/
    searchQuery: {
      q: '',
      starts_at__gt: formatDate(moment().subtract(1, 'weeks')._d, 'UTC'),
      starts_at__lt: formatDate(moment()._d, 'UTC'),
      // does nothing, for date picker, user can reload page but these selected values will remain
      tz: 'UTC',
      // for date picker, index of selected range, see DateFilter.vue
      range: '4',
    },
    searchQueryLoading: false,
    // UI flag, show loading animation when user hits bottom of the page, and we're loading more alerts.
    // Here instead of component state because function, that loads more results ResultTabContent:fetchMoreResults, has
    // access to vue (this) but not to the component state. A workaround.
    loadingMoreAlerts: false,
    /** @type {Record<number, RelatedProjectsSerializer[]>} **/
    deviceRelatedProjects: {},
    // string for Session statistics, int for Alert statistics, single object for both because it's convenient for me.
    /** @type {Record<(string | int), {min: number, max: number, mean: number, stdev: number, total: number}>} **/
    statistics: {},
    /** @type {Record<string, SensorStatisticSerializer[]>} session guid: list of sensor statistic **/
    sensorStatistic: {},
    sensorStatisticsLoading: false,
    initialSensorFields: setInitialSensorFields(),
    selectedSensorFields: getCookie(SENSOR_FIELDS_COOKIE)
      ? getCookie(SENSOR_FIELDS_COOKIE).split(',')
      : setInitialSensorFields().map((el) => el.key),
    statisticsFetching: false,
    statisticsMetric: null,
    statisticsLoading: false,
    compareLoading: false,
    /**
     * @typedef AlertEventStoreEntry
     * @property {AlertEventSerializer} detail
     * @property {AlertEventLocationSerializer} location
     * @property {AlertCompareSerializer} compare
     */
    /** @type {Record<number, AlertEventStoreEntry>} **/
    events: {},
    /** @type {number | null} **/
    openAlertID: null,
    /** @type {Record<number, BlockDesignSerializer[]>} cache storage for blocks **/
    blocks: {},
    overview: {
      /** @type {OverviewStatisticSerializer} **/
      statistics: {},
      /** @type {AlertChartSerializer[]} **/
      chart: []
    }
  }),
  getters: {
    /**
     * Returns location of event for any open alert id, null if no alert is open.
     * @param state
     * @returns {AlertEventSerializer | {}}
     */
    openAlertIDEventDetail(state) {
      return get(state.events[state.openAlertID], 'detail', {});
    },
    /**
     * Returns location of event for any open alert id, null if no alert is open.
     * @param state
     * @returns {AlertEventLocationSerializer | {}}
     */
    openAlertIDEventLocation(state) {
      return get(state.events[state.openAlertID], 'location', {});
    },
    /**
     * Returns compare result of event for any open alert id, null if no alert is open.
     * @param state
     * @returns {AlertCompareSerializer | {}}
     */
    openAlertIDEventCompare(state) {
      return get(state.events[state.openAlertID], 'compare', {});
    },
    /**
     * Sensor statistics table fields
     * @param state
     * @returns {object[]}
     */
    sensorFields(state) {
      if (state.selectedSensorFields.length > 0) {
        return state.initialSensorFields.filter((el) => state.selectedSensorFields.indexOf(el.key) > -1);
      }
      return state.initialSensorFields;
    },
  },
  mutations: {
    /**
     * Update selected columns array and store it into cookies
     * @param state
     * @param {array} selectedFields array of selected fields
     */
    updateSelectedSensorFields(state, selectedFields) {
      state.selectedSensorFields = selectedFields;
      setCookie(SENSOR_FIELDS_COOKIE, state.selectedSensorFields.join(','));
    },
    /**
     * Adds a query to state and persistent storage
     * @param state
     * @param {string} query search query
     */
    addQueryToRecent(state, query) {
      if (!query || query.length === 0) {
        return;
      }
      const data = {
        createdAt: new Date().strftime('%b %d, %Y %H:%M:%S'),
        query,
      };
      // if this query already in recent, filter it out
      const recent = state.query.recent.filter((r) => r.query !== query);
      // keep only last 5
      state.query.recent = [data, ...recent].slice(0, 5);
      // save it presistent storage
      localStorage.setItem(RECENT_SEARCH_QUERIES_KEY, JSON.stringify(state.query.recent));
    },
    delQueryFromRecent(state, {query}) {
      const recent = state.query.recent.filter((r) => r.query !== query);
      state.query.recent = recent;
      localStorage.setItem(RECENT_SEARCH_QUERIES_KEY, JSON.stringify(recent));
    },
    /**
     * Adds saved search
     * @param state
     * @param {SavedSearchSerializer} data
     */
    addSavedSearch(state, data) {
      state.savedSearches.push(data);
    },
    /**
     * Removes saved search
     * @param state
     * @param {number} searchId id of search
     */
    removeSavedSearch(state, searchId) {
      state.savedSearches = state.savedSearches.filter((el) => el.id !== searchId);
    },
    /**
     * Set related projects to alert by id
     * @param state
     * @param {number} alertId id of alert
     * @param {RelatedProjectsSerializer[]} data
     */
    setAlertRelatedProjects(state, {alertId, data}) {
      state.alertRelatedProjects[alertId] = data;
    },
    /**
     * Clears count and alert search results, use it before applying and fetching filtered content
     * @param state
     */
    resetSearchResults(state) {
      state.searchResults.count = 0;
      state.searchResults.results = [];
    },
    /**
     * Set related events to alert by id
     * @param state
     * @param {number} alertId id of alert
     * @param {AlertSerializer[]} data
     */
    setAlertRelatedEvents(state, {alertId, data}) {
      state.alertRelatedEvents[alertId] = data;
    },
    setDeviceRelatedProjects(state, {guid, data}) {
      state.deviceRelatedProjects[guid] = data;
    },
    /**
     * Merges in `obj` into state
     * @param state
     * @param {AlertQuerySerializer} obj
     */
    mergeInSearchQuery(state, obj) {
      state.searchQuery = {...state.searchQuery, ...obj};
    },
    /**
     * @param state
     * @param {boolean} value
     */
    setSearchQueryLoading(state, value) {
      state.searchQueryLoading = value;
    },
    /**
     * @param state
     * @param {boolean} value
     */
    setLoadingMoreAlerts(state, value) {
      state.loadingMoreAlerts = value;
    },
    setAlertSamplesLoading(state, value) {
      state.alertSamplesLoading = value;
    },
    setStatisticsLoading(state, value) {
      state.statisticsLoading = value;
    },
    setStatisticsMetric(state, value) {
      state.statisticsMetric = value;
    },
    setStatistics(state, {metric, id, value}) {
      state.statistics[getStatisticsKey(metric, id)] = value;
    },
    setCompareLoading(state, value) {
      state.compareLoading = value;
    },
    /**
     * Sets location information. Uses `this._vm.$set` because `alertId` can potentially be initially missing.
     * @param state
     * @param {number} alertId
     * @param {AlertEventSerializer} detail
     */
    setEventDetail(state, {alertId, detail}) {
      const event = state.events[alertId] || {};
      this._vm.$set(state.events, alertId, {...event, detail});
    },
    /**
     * Sets location information. Uses `this._vm.$set` because `alertId` can potentially be initially missing.
     * @param state
     * @param {number} alertId
     * @param {AlertEventLocationSerializer} location
     */
    setEventLocation(state, {alertId, location}) {
      const event = state.events[alertId] || {};
      this._vm.$set(state.events, alertId, {...event, location});
    },
    /**
     * Sets alert event compare information.
     * @param state
     * @param {number} alertId
     * @param {AlertCompareSerializer} compare
     */
    setEventCompare(state, {alertId, compare}) {
      const event = state.events[alertId] || {};
      this._vm.$set(state.events, alertId, {...event, compare});
    },
    /**
     * @param state
     * @param {number | null} alertId
     */
    setOpenAlertID(state, alertId) {
      state.openAlertID = alertId;
    },
    /**
     * @param state
     * @param {boolean} value
     */
    setSensorStatisticsLoading(state, value) {
      state.sensorStatisticsLoading = value;
    },
    /**
     * Sets SensorStatistics data and pagination.
     * @param state
     * @param {UUID} sessionId
     * @param {SensorStatisticSerializer[]} results
     * @param {number} count
     * @param {number} page
     */
    setSensorStatistics(state, {sessionId, results, count, page}) {
      const storeSensor = state.sensorStatistic[sessionId] || {};
      const sensorStatistics = {
        data: results,
        pagination: {
          count,
          onPage: page,
          perPage: 6,
        }
      };
      this._vm.$set(state.sensorStatistic, sessionId, {...storeSensor, ...sensorStatistics});
    },
    delAlertsBySessionId(state, sessionId) {
      state.searchResults.results = state.searchResults.results.filter((el) => el.session_guid !== sessionId);
    },
    /**
     * @param state
     * @param {OverviewStatisticSerializer} value
     */
    setOverviewStatistics(state, value) {
      state.overview.statistics = value;
    },
    /**
     * @param state
     * @param {AlertChartSerializer} value
     */
    setOverviewChartEvents(state, value) {
      state.overview.chart = value;
    },
    /**
     * @param state
     * @param {UUID} alertId
     * @param {string} y
     * @param {string[]} samples
     * @param {boolean} isInit
     */
    setSamples(state, {alertId, y, samples, isInit}) {
      if (isInit) {
        if (state.alertSamples[alertId] === undefined) {
          this._vm.$set(state.alertSamples, alertId, {});
        }
        this._vm.$set(state.alertSamples[alertId], y, samples);
      }
      if (samples.length) {
        state.samples[alertId][y].values.push(...samples);
      }
    },
  },
  actions: {
    /**
     * Fetch sensor statistics data and pagination for session and save it in store.
     * @param state
     * @param commit
     * @param {UUID} sessionId
     * @param {number} page page number
     * @param {{size: number, StartsAtQuerySerializer}} params
     * @return {Promise<void>}
     */
    async fetchSensorStatistic({state, commit}, {sessionId, page = 1, params}) {
      commit('setSensorStatisticsLoading', true);
      try {
        const response = await api.slm.session.sensorStatistics(sessionId, page, params);
        const {count, results} = response.data;
        commit('setSensorStatistics', {sessionId, results, count, page});
      } catch (error) {
        console.error(error.toString());
      }
      commit('setSensorStatisticsLoading', false);
    },
    /**
     * Receive alerts by new query
     * @param state
     */
    async fetchSearchResults({state}) {
      const response = await api.slm.alert.list(state.searchQuery, state.searchResults.results.length);
      const {count, results} = response.data;
      state.searchResults.count = count;
      state.searchResults.results.push(...results);
    },
    /**
     * Takes the latest alert's starts_at and looks for newer alerts after this date
     * @param state
     * @return {Promise<void>}
     */
    async refreshSearchResults({state}) {
      // GTE IS FROM
      // LTE IS TO
      // skip auto-update if upper time is set
      if (state.searchQuery.starts_at__lt) {
        return;
      }
      const alert = state.searchResults.results[0];
      const query = {
        q: state.searchQuery.q,
        starts_at__gt: state.searchQuery.starts_at__gt,
      };
      if (alert) {
        query.starts_at__gt = formatDate(parseDate(alert.starts_at), 'UTC');
      }
      // null - non paginated
      const response = await api.slm.alert.list(query, 0, null);
      if (response.data.length) {
        state.searchResults.count += response.data.length;
        response.data.push(...state.searchResults.results);
        state.searchResults.results = response.data;
      }
    },
    async fetchSavedSearches({state}) {
      state.savedLoading = true;
      try {
        const response = await api.slm.search.list();
        state.savedSearches = response.data;
      } catch (error) {
        console.error(error.toString());
      }
      state.savedLoading = false;
    },
    /**
     * Delete query from saved search by id
     * @param state
     * @param commit
     * @param {number} searchId id of saved search
     */
    async deleteSavedSearch({state, commit}, searchId) {
      state.savedLoading = true;
      try {
        await api.slm.search.destroy(searchId);
        commit('removeSavedSearch', searchId);
      } catch (error) {
        console.error(error.toString());
      }
      state.savedLoading = false;
    },
    async fetchAlertRelatedProjects({state, commit}, alertId) {
      if (!(alertId in state.alertRelatedProjects)) {
        try {
          const response = await api.slm.alert.relatedProjects(alertId);
          commit('setAlertRelatedProjects', {alertId, data: response.data});
        } catch (error) {
          console.error(error.toString());
        }
      }
    },
    async fetchAlertRelatedEvents({state, commit}, {alertId, refresh = false}) {
      if (!(alertId in state.alertRelatedEvents) || refresh) {
        try {
          const response = await api.slm.alert.relatedEvents(alertId);
          commit('setAlertRelatedEvents', {alertId, data: response.data});
        } catch (error) {
          this.$store.commit('sys/notifyDanger', error.toString());
        }
      }
    },
    async fetchDeviceRelatedProjects({state, commit}, guid) {
      if (!(guid in state.deviceRelatedProjects)) {
        try {
          const response = await api.slm.device.relatedProjects(guid);
          commit('setDeviceRelatedProjects', {guid, data: response.data});
        } catch (error) {
          console.error(error.toString());
        }
      }
    },
    /**
     * Fetches metrics for a `alertId`, if metrics exist, take last metric timestamp and fetch metrics after this ts.
     * @param state
     * @param commit
     * @param {number} alertId
     * @param {string} [y]
     * @param {boolean} isEnded alert has ends_at, don't refresh samples
     * @return {Promise<boolean>}
     */
    async fetchAlertSamples({state, commit}, {alertId, y, isEnded}) {
      const samples = state.alertSamples[alertId];
      const isInit = !samples || samples[y] === undefined;

      if (isInit && !state.alertSamplesError) {
        // set the loading flag only if no metrics present and previous request completed successfully
        state.alertSamplesLoading = true;
      }

      if (isInit) {
        try {
          const response = await api.slm.alert.samples(alertId, undefined, y);
          response.data.values = response.data.values.map(Object.freeze);
          state.alertSamplesError = false;
          commit('setSamples', {alertId, y, samples: response.data, isInit});
        } catch (e) {
          state.alertSamplesError = true;
          console.error(e);
        } finally {
          state.alertSamplesLoading = false;
        }
        return false;
      }

      if (isEnded) {
        return false;
      }

      // Will be undefined if the initial fetch did fetch any samples.
      const lastSample = samples[y].values[samples[y].values.length - 1];
      try {
        const response = await api.slm.alert.samples(
          alertId,
          lastSample ? lastSample[0] : undefined,
          y,
        );
        if (response.data.values.length === 0) {
          return false;
        }
        response.data.values = response.data.values.map(Object.freeze);
        samples[y].values.push(...response.data.values);
        return true;
      } catch (e) {
        console.error(e);
      } finally {
        state.alertSamplesLoading = false;
      }
    },
    /**
     * Fetches and sets a metric statistics
     * @param state
     * @param commit
     * @param {string | number} id
     * @param {string} [metric]
     * @returns {Promise<void>}
     */
    async fetchStatistics({state, commit}, {id, metric}) {
      // Allow only 1 request at time, resolve the issue when refresh is called and user selects another metric in the
      // same time.
      if (state.statisticsFetching) {
        return;
      }
      metric = metric || state.statisticsMetric;
      state.statisticsFetching = true;
      const func = typeof id === 'string' ? api.slm.session.statistics : api.slm.alert.statistics;
      try {
        const response = await func(id, metric);
        commit('setStatisticsMetric', metric);
        commit('setStatistics', {metric, id, value: response.data});
      } catch (e) {
        console.error(e);
      }
      state.statisticsFetching = false;
    },
    /**
     * Fetches and stores location information of an alert.
     * @param commit
     * @param state
     * @param {number} alertId
     * @throws {import('axios').AxiosError}
     * @returns {Promise<AlertEventSerializer>}
     */
    async fetchAlertEventDetail({state, commit}, alertId) {
      if (!state.events[alertId]?.detail) {
        const response = await api.slm.event.detail(alertId);
        commit('setEventDetail', {alertId, detail: response.data});
      }
      return state.events[alertId].detail;
    },
    /**
     * Fetches and stores location information of an alert.
     * @param commit
     * @param state
     * @param {number} alertId
     * @throws {import('axios').AxiosError}
     * @returns {Promise<AlertEventLocationSerializer>}
     */
    async fetchAlertEventLocation({state, commit}, alertId) {
      if (!state.events[alertId]?.location) {
        const response = await api.slm.event.location(alertId);
        commit('setEventLocation', {alertId, location: response.data});
      }
      return state.events[alertId].location;
    },
    /**
     * Fetch and cache device blocks
     * @param state
     * @param commit
     * @param {UUID} deviceId
     * @returns {Promise<BlockDesignSerializer[]>}
     */
    async fetchDeviceBlocks({state, commit}, deviceId) {
      if (state.blocks[deviceId] === undefined) {
        const response = await api.slm.device.blocks(deviceId);
        state.blocks[deviceId] = response.data;
      }
      return state.blocks[deviceId];
    },
    /**
     * Fetch AlertEvent comparison results
     * @param state
     * @param commit
     * @param {number} alertId
     * @param {string} [to]
     * @returns {Promise<{AlertCompareSerializer}>}
     */
    async fetchCompareResults({state, commit}, {alertId, to}) {
      try {
        const response = await api.slm.event.compare(alertId, to);
        commit('setEventCompare', {alertId, compare: response.data});
      } catch (e) {
        console.error(e);
      }
      return state.events[alertId].compare;
    },
    /**
     * Fetch AlertEvent PMU margin
     * @param state
     * @param commit
     * @param {number} alertId
     * @returns {Promise<AlertPMUMarginSerializer[]>}
     */
    async fetchPMUMargin({state, commit}, alertId) {
      try {
        const response = await api.slm.event.pmuMargin(alertId);
        return response.data;
      } catch (e) {
        console.error(e);
      }
    },
    /**
     * Fetch overview statistic
     * @param state
     * @param commit
     * @param {StartsAtQuerySerializer} params
     * @returns {Promise<void>}
     */
    async fetchOverviewStatistic({state, commit}, params) {
      const response = await api.slm.overview.list(params);
      commit('setOverviewStatistics', response.data);
    },
    /**
     * Fetch overview statistic
     * @param state
     * @param commit
     * @param {StartsAtQuerySerializer} params
     * @returns {Promise<void>}
     */
    async fetchOverviewChartEvents({state, commit}, params) {
      try {
        const response = await api.slm.overview.chart(params);
        commit('setOverviewChartEvents', response.data);
      } catch (e) {
        console.error(e);
      }
    },
  },
};
