export const validationSeriesFactory = (name) => ({
  name,
  type: 'line',
  data: [],
  animation: false,
  silent: true,
  areaStyle: {opacity: 0.75},
  itemStyle: {opacity: 0},
  smoothMonotone: 'x',
  showSymbol: true,
});
