import qs from 'qs';
import {apiUrl} from '@/api/client';
import {requestAction} from '@/api/helpers';
import {findStringInObject} from '@/utils/array';
import {DEFAULT_PAGE_LIMIT, Stages, States} from '@/constants';
import {mapStringValues, refreeze, setAttrs} from '@/utils/object';
import {b64DecodeUnicode} from '@/utils/text';
import api from '@/api';

const ADD_METHOD = {
  REPLACE: 1,
  APPEND: 2,
  PREPEND: 3,
};

export const LOAD_TYPE = {
  INITIAL: 'loading',
  NEXT: 'moreLoading',
  PREVIOUS: 'previousLoading',
  REFRESH: 'refreshing',
  DELETE: 'deleting',

  /**
   * Checks every if any of load type is in true state
   * @param state
   */
  isLoading(state) {
    return Object.values(this).some((value) => state[value]);
  }
};


const initialState = () => ({
  /** @type {ExperimentSerializer[]} **/
  list: [],
  [LOAD_TYPE.INITIAL]: true,
  [LOAD_TYPE.NEXT]: false,
  [LOAD_TYPE.PREVIOUS]: false,
  [LOAD_TYPE.REFRESH]: false,
  // block requests during deletion
  [LOAD_TYPE.DELETE]: false,
  pagination: {
    // total
    count: 0,
    // current start index
    start: Infinity,
    // current end index
    end: -Infinity,
  },
  term: '',
  inPlaceSearch: true,
  // selected experiment uuid
  _selected: '',
  // projectId for fetched list
  projectId: '',
  selectedList: [],

  metadata: {},
  inventory: {},
  definition: {},
  downloadableLinks: {},
  /** @type {Record<UUID, ExperimentHistorySerializer[]>} **/
  history: {},
});

export const EXPERIMENT_GROUPS = Object.freeze({
  FUTURE: 1,
  CURRENT: 2,
  FINISHED: 3,

  /**
   * @param {(Experiment | {on_stage: Experiment.on_stage, state: Experiment.state})} experiment
   * @returns {EXPERIMENT_GROUPS[keyof EXPERIMENT_GROUPS]}
   */
  getForExperiment(experiment) {
    if (experiment.on_stage >= Stages.done) {
      return this.FINISHED;
    }
    switch (experiment.state) {
      case States.ready: {
        return this.FUTURE;
      }
      case States.running: {
        return this.CURRENT;
      }
      default: {
        return this.FINISHED;
      }
    }
  },

  /**
   * @param {{inPlaceSearch: bool, list: any[], term: string}} state
   * @param {EXPERIMENT_GROUPS[keyof EXPERIMENT_GROUPS]} [group]
   */
  filter(state, group) {
    if (group === undefined) {
      return state.inPlaceSearch ? state.list.search(state.term) : state.list;
    }
    const list = state.list.filter((el) => this.getForExperiment(el) === group);
    return state.inPlaceSearch ? list.search(state.term) : list;
  },
});

export default {
  namespaced: true,

  state: initialState,

  getters: {
    filtered(state) {
      return EXPERIMENT_GROUPS.filter(state);
    },

    future(state) {
      return EXPERIMENT_GROUPS.filter(state, EXPERIMENT_GROUPS.FUTURE);
    },

    current(state) {
      return EXPERIMENT_GROUPS.filter(state, EXPERIMENT_GROUPS.CURRENT);
    },

    recent(state) {
      return EXPERIMENT_GROUPS.filter(state, EXPERIMENT_GROUPS.FINISHED);
    },

    selected(state) {
      return state.list.find((el) => el.guid === state._selected);
    },

    currentDLLinks(state) {
      const links = state.downloadableLinks[state._selected] || {};
      return ['settings', 'logs', 'samples'].map((key) => ({
        url: links[key],
        text: key.capitalize(),
        id: key,
      }));
    },
  },

  mutations: {
    /**
     * @param state
     * @param {ExperimentTrackSerializer[]} data
     * @param {ADD_METHOD[keyof ADD_METHOD]} method
     */
    addToList(state, {results, method}) {
      if (results.length === 0) {
        if (method === ADD_METHOD.REPLACE) {
          state.list = [];
        }
        return;
      }
      if (method === ADD_METHOD.REPLACE) {
        state.list = results;
        return;
      }
      const resultGUIDs = results.map((e) => e.guid);
      const list = state.list.exclude((e) => resultGUIDs.includes(e.guid));
      if (method === ADD_METHOD.APPEND) {
        // remove elements from current list of they are in result list, workaround.
        state.list = [...list, ...results];
      } else if (method === ADD_METHOD.PREPEND) {
        state.list = [...results, ...list];
      }
    },

    reset(state) {
      setAttrs(state, initialState());
    },

    setTerm(state, value) {
      state.term = value;
    },

    setProjectId(state, {value}) {
      state.projectId = value;
    },

    setSelected(state, value) {
      if (state._selected === value) {
        state._selected = '';
      } else {
        state._selected = value;
      }
    },

    selectAll(state) {
      if (state.term === '') {
        state.selectedList = state.list.map((el) => el.guid);
        return;
      }
      const filtered = findStringInObject(state.list, ['state', 'guid', 'tags', 'stages'], state.term);
      state.selectedList = filtered.map((el) => el.guid);
    },

    /**
     * Removes experiment(s) from store and update pagination
     * @param state
     * @param {UUID[]} guids
     */
    removeExperiments(state, guids) {
      const left = state.pagination.count - guids.length;
      if (state.pagination.end === state.pagination.count) {
        state.pagination.end = left;
      }
      state.pagination.count = left;

      state.list = state.list.filter((el) => !guids.includes(el.guid));
      state.selectedList = state.selectedList.filter((el) => !guids.includes(el));
    },

    pushSelected(state, guid) {
      state.selectedList.push(guid);
    },

    removeSelected(state, guid) {
      const guidIdx = state.selectedList.indexOf(guid);
      if (guidIdx > -1) {
        state.selectedList.splice(guidIdx, 1);
      }
    },

    clearSelectedList(state) {
      state.selectedList = [];
    },

    setMetadata(state, {data, id}) {
      state.metadata[id] = data;
    },

    setInventory(state, {data, id}) {
      state.inventory[id] = refreeze(data);
    },

    setDefinition(state, {data, id}) {
      state.definition[id] = refreeze(mapStringValues(data, b64DecodeUnicode));
    },

    setCurrentDLLinks(state, {data}) {
      this._vm.$set(state.downloadableLinks, state._selected, data);
    },

    /**
     * Removes experiments with matching definition id(s)
     * @param state
     * @param {number[]} ids
     */
    removeByDefinitionIDs(state, ids) {
      const removedExperimentGUIDs = [];
      state.list = state.list.filter((experiment) => {
        if (ids.includes(experiment.definition.id)) {
          removedExperimentGUIDs.push(experiment.guid);
          return false;
        }
        return true;
      });
      state.selectedList = state.selectedList.filter((guid) => !removedExperimentGUIDs.includes(guid));
    },

    /**
     * Parses pagination response and sets parsed data on state
     *      prev  curr  next
     * [... [...] [...] [...] ...]
     * prev[TP:TP+10], curr[TP+10:TN], next[TN:TN+10]
     * @param state
     * @param {Pagination} pagination
     */
    setPagination(state, pagination) {
      /**
       * @param {string | null} url
       * @param {'offset' | 'limit'} urlKey
       * @returns {null | number}
       */
      const getStateValue = (url, urlKey) => {
        if (typeof url !== 'string') {
          return null;
        }
        const parsedURL = new URL(url);
        const query = qs.parse(parsedURL.search.replace(/\?/, ''));
        const value = query[urlKey];
        if (typeof value === 'string' && !Number.isNaN(value)) {
          return Number(value);
        }
        return null;
      };
      const next = getStateValue(pagination.next, 'offset');
      const previous = getStateValue(pagination.previous, 'offset');

      state.pagination.count = pagination.count;
      if (state[LOAD_TYPE.INITIAL] || state[LOAD_TYPE.PREVIOUS]) {
        state.pagination.start = previous === null ? 0 : Math.max(0, previous + DEFAULT_PAGE_LIMIT);
      }
      if (state[LOAD_TYPE.INITIAL] || state[LOAD_TYPE.NEXT]) {
        state.pagination.end = next === null ? pagination.count : Math.min(pagination.count, next);
      }
    },

    /**
     * @param state
     * @param {LOAD_TYPE[keyof LOAD_TYPE]} type
     * @param {boolean} value
     */
    setLoading(state, {type, value}) {
      state[type] = value;
    },

    changeExperiment(state, {guid, ...rest}) {
      state.list = state.list.map((el) => {
        if (el.guid === guid) {
          return {...el, ...rest};
        }
        return el;
      });
    },
  },

  actions: {
    async delete({state, commit}, experimentId) {
      if (LOAD_TYPE.isLoading(state)) {
        return;
      }
      commit('setLoading', {type: LOAD_TYPE.DELETE, value: true});

      try {
        await api.experiments.destroy(state.projectId, experimentId);
        commit('removeExperiments', [experimentId]);
        commit(
          'project/setProject',
          {guid: state.projectId, experiment_count: state.pagination.count},
          {root: true},
        );
      } finally {
        commit('setLoading', {type: LOAD_TYPE.DELETE, value: false});
      }
    },

    async fetchList({state, commit}, {projectId, goto}) {
      if (projectId === state.projectId) {
        return;
      }
      commit('setLoading', {type: LOAD_TYPE.INITIAL, value: true});
      try {
        const response = await api.experiments.track(projectId, 0, DEFAULT_PAGE_LIMIT, {goto});
        const {results, ...pagination} = response.data;
        commit('setPagination', pagination);
        commit('addToList', {results, method: ADD_METHOD.REPLACE});
      } catch (error) {
        console.error(error);
      }
      commit('setLoading', {type: LOAD_TYPE.INITIAL, value: false});
      commit('setProjectId', {value: projectId});
    },

    async fetchPrevious({state, commit}) {
      const {start} = state.pagination;
      if (LOAD_TYPE.isLoading(state) || start === 0) {
        return;
      }
      commit('setLoading', {type: LOAD_TYPE.PREVIOUS, value: true});
      try {
        const response = await api.experiments.track(
          state.projectId,
          Math.max(0, start - DEFAULT_PAGE_LIMIT),
          DEFAULT_PAGE_LIMIT,
          {search: state.term},
        );
        const {results, ...pagination} = response.data;
        commit('setPagination', pagination);
        commit('addToList', {results, method: ADD_METHOD.PREPEND});
      } finally {
        commit('setLoading', {type: LOAD_TYPE.PREVIOUS, value: false});
      }
    },

    async fetchMore({state, commit}) {
      const {end, count} = state.pagination;
      if (LOAD_TYPE.isLoading(state) || end >= count) {
        return;
      }
      commit('setLoading', {type: LOAD_TYPE.NEXT, value: true});
      try {
        const response = await api.experiments.track(
          state.projectId,
          end,
          DEFAULT_PAGE_LIMIT,
          {search: state.term}
        );
        const {results, ...pagination} = response.data;
        commit('setPagination', pagination);
        commit('addToList', {results, method: ADD_METHOD.APPEND});
      } finally {
        commit('setLoading', {type: LOAD_TYPE.NEXT, value: false});
      }
    },

    async updateExperimentTags({state, commit}, {guid, tags}) {
      const url = apiUrl.experimentTags.format({projectId: state.projectId, experimentId: guid});
      const [response, error] = await requestAction('put', url, {data: {tags}});
      if (response) {
        commit('changeExperiment', {guid, tags: response.data.tags});
      }
      return [response, error];
    },

    async updateExperiment({state, commit}, {guid, ...data}) {
      await api.experiments.update(state.projectId, guid, {...data});
      // ignore .update response, it differs from what we have in store, only change values we've sent in the PATCH
      // request.
      commit('changeExperiment', {guid, ...data});
    },

    async refresh({state, commit}) {
      const {start, end} = state.pagination;
      // eslint-disable-next-line no-constant-condition
      if (LOAD_TYPE.isLoading(state) || !Number.isFinite(start) || !Number.isFinite(end)) {
        return;
      }
      commit('setLoading', {type: LOAD_TYPE.REFRESH, value: true});
      try {
        const response = await api.experiments.track(
          state.projectId,
          start,
          // always fetch at least DEFAULT_PAGE_LIMIT elements
          Math.max(state.list.length, DEFAULT_PAGE_LIMIT),
          {refresh: 1, search: state.term}
        );
        const {results, ...pagination} = response.data;
        commit('addToList', {results, method: ADD_METHOD.REPLACE});
        commit('setPagination', pagination);
      } catch (error) {
        console.error(error.toString());
      } finally {
        commit('setLoading', {type: LOAD_TYPE.REFRESH, value: false});
      }
    },

    async fetchMetadata({state, commit}, {id, projectID}) {
      projectID = projectID || window.path.projectID;
      if (id in state.metadata) {
        return;
      }
      const url = apiUrl.experimentMetadata.format({projectId: projectID, experimentId: id});
      const [response, error] = await requestAction('get', url);
      if (error) {
        console.error(error);
        return;
      }
      commit('setMetadata', {data: response.data, id});
    },

    async fetchInventory({state, commit}, {id, projectID}) {
      projectID = projectID || window.path.projectID;
      if (id in state.inventory) {
        return;
      }
      const url = apiUrl.experimentInventory.format({projectId: projectID, experimentId: id});
      const [response, error] = await requestAction('get', url);
      if (error) {
        console.error(error);
        return;
      }
      commit('setInventory', {data: response.data, id});
    },

    async fetchDefinition({state, commit}, {id, projectID}) {
      if (id in state.definition) {
        return;
      }
      projectID = projectID || window.path.projectID;
      const url = apiUrl.definitionFiles.format({projectId: projectID, definitionId: id});
      const [response, error] = await requestAction('get', url, {ver: '2.0'});
      if (error) {
        console.error(error);
        return;
      }
      commit('setDefinition', {data: response.data, id});
    },

    /**
     * Fetch and set logs, settings and samples download links. If links are fetched already - skips fetching.
     * @param state
     * @param commit
     * @returns {Promise<void>}
     */
    async fetchCurrentDLLinks({state, commit}) {
      const {logs, samples} = state.downloadableLinks[state._selected] || {};
      if ([logs, samples].every((link) => !!link)) {
        return;
      }
      const response = await api.experiments.downloadable(state.projectId, state._selected);
      commit('setCurrentDLLinks', {data: response.data});
    },

    /**
     * Receive history for selected experiment.
     * If history[experiment guid] is not empty,
     * takes the latest log's created_at and looks for newer log after this date.
     * @param state
     * @param commit
     */
    async fetchHistory({state, commit}) {
      if (!(state._selected in state.history)) {
        const response = await api.experiments.history(state.projectId, state._selected);
        this._vm.$set(state.history, state._selected, response.data);
      } else {
        const params = {};
        const log = state.history[state._selected].last();
        if (log) {
          params.created_at__gt = log.created_at;
        }
        const response = await api.experiments.history(state.projectId, state._selected, params);
        if (response.data.length) {
          state.history[state._selected].push(...response.data);
        }
      }
    },
    /**
     * Force change experiment state to "state".
     * @param state
     * @param {UUID} experimentId
     */
    async moveToDone({state}, {experimentId}) {
      await api.experiments.update(state.projectId, experimentId, {state: States.done});
    },

    /**
     * Run experiment search, updates pagination and replaces experiment list with the search results
     * @param state
     * @param commit
     * @param {string} search GET query
     * @returns {Promise<void>}
     */
    async search({state, commit}, search) {
      if (LOAD_TYPE.isLoading(state)) {
        return;
      }
      commit('setTerm', search);
      commit('setLoading', {type: LOAD_TYPE.INITIAL, value: true});
      state.inPlaceSearch = search.length === 0;
      try {
        const response = await api.experiments.track(state.projectId, 0, DEFAULT_PAGE_LIMIT, {search});
        const {results, ...pagination} = response.data;
        commit('setPagination', pagination);
        commit('addToList', {results, method: ADD_METHOD.REPLACE});
      } finally {
        commit('setLoading', {type: LOAD_TYPE.INITIAL, value: false});
      }
    },

    /**
     * Deletes selected experiments
     * @param state
     * @param commit
     * @returns {Promise<void>}
     */
    async deleteSelected({state, commit}) {
      if (LOAD_TYPE.isLoading(state)) {
        return;
      }
      commit('setLoading', {type: LOAD_TYPE.DELETE, value: true});

      const errors = [];
      const removed = [];

      for (const guid of state.selectedList) {
        try {
          await api.experiments.destroy(state.projectId, guid);
          removed.push(guid);
        } catch (e) {
          errors.push(e.toString());
        }
      }

      errors.forEach((error) => commit('sys/notifyDanger', error), {root: true});
      commit('removeExperiments', removed);
      commit(
        'project/setProject',
        {guid: state.projectId, experiment_count: state.pagination.count},
        {root: true},
      );
      commit('setLoading', {type: LOAD_TYPE.DELETE, value: false});
    },

    /**
     * Mark as Done selected experiments
     * @param state
     * @param commit
     * @returns {Promise<void>}
     */
    async markAsDoneSelected({state, commit}) {
      if (LOAD_TYPE.isLoading(state)) {
        return;
      }
      commit('setLoading', {type: LOAD_TYPE.REFRESH, value: true});

      const errors = [];
      for (const guid of state.selectedList) {
        try {
          await api.experiments.update(state.projectId, guid, {state: States.done});
        } catch (e) {
          errors.push(e.toString());
        }
      }
      commit('clearSelectedList');
      errors.forEach((error) => commit('sys/notifyDanger', error), {root: true});
      commit('setLoading', {type: LOAD_TYPE.REFRESH, value: false});
    },
  },
};
