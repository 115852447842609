import Endpoint from '@/api/resources/base';
import urls from '@/api/urls';

export default class SLMDevice extends Endpoint {
  /**
   * List of devices available to user (based on user's account)
   * @param {number} page
   * @param {number} [size] page size
   * @param {string} [search] search by device guid
   * @param {string} [sort] sort by device key
   * @return {Promise<import('axios').AxiosResponse<Paginated<DeviceSerializer[]>>>}
   */
  list = async (page = 1, size, search, sort) => {
    return this.api.get(urls.slm.device.list, {page, size, search, sort});
  };

  /**
   * List of sessions on this device
   * @param {UUID} deviceId
   * @param {string} [search] - search by session guid and tags
   * @returns {Promise<import('axios').AxiosResponse<BriefDeviceSessionSerializer[]>>}
   */
  sessions = async (deviceId, search = '') => {
    const url = urls.slm.device.sessions.format({deviceId});
    return this.api.get(url, {search});
  };

  /**
   * List of sessions id and ends_at only fields on this device
   * @param {UUID} deviceId
   * @returns {Promise<import('axios').AxiosResponse<DeviceSessionTrackSerializer[]>>}
   */
  trackSessions = async (deviceId) => {
    const url = urls.slm.device.trackSessions.format({deviceId});
    return this.api.get(url);
  };

  /**
   * Retrieve related projects
   * @param {UUID} deviceId session guid
   * @returns {Promise<import('axios').AxiosResponse<RelatedProjectsSerializer[]>>}
   */
  relatedProjects = async (deviceId) => {
    const url = urls.slm.device.relatedProjects.format({deviceId});
    return this.api.get(url);
  };

  /**
   * Upload chip design file.
   * @param {UUID} deviceId session guid
   * @param {FormData} data
   * @param {function(progressEvent: *): void} [onUploadProgress]
   * @returns {Promise<import('axios').AxiosResponse<null>>}
   */
  uploadUDF = async (deviceId, data, onUploadProgress) => {
    const url = urls.slm.device.udf.format({deviceId});
    return this.api.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
  };
  /**
   * List of all blocks available on a device
   * @param {UUID} deviceId
   * @returns {Promise<import('axios').AxiosResponse<BlockDesignSerializer[]>>}
   */
  blocks = async (deviceId) => {
    const url = urls.slm.device.blocks.format({deviceId});
    return this.api.get(url);
  }
  /**
   * List of all chips available on a device
   * @param {UUID} deviceId
   * @returns {Promise<import('axios').AxiosResponse<ChipSerializer[]>>}
   */
  chips = async (deviceId) => {
    const url = urls.slm.device.chips.format({deviceId});
    return this.api.get(url);
  }
}
