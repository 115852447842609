import Endpoint from '@/api/resources/base';
import urls from '@/api/urls';

export default class Hint extends Endpoint {
  /**
   * Get hint details
   * @param {String} slug
   * @returns {Promise<import('axios').AxiosResponse<HintSerializer>>}
   */
  detail = async (slug) => {
    const url = urls.hint.detail.format({slug});
    return this.api.get(url);
  };
}
