import {cloneDeep, sortBy} from 'lodash';

import {SCATTER_Y_EXCLUDE, GOAL, GOAL_EXCLUDE, METRICS} from '@/constants';
import {AXIS_META, metricToOption} from '@/utils/vis';
import {refreeze} from '@/utils/object';
import api from '@/api';

/**
 * @typedef AxisMetricSelect
 * @property {string | null} accessor
 * @property {SelectOption[]} opts
 * @property {GOAL[keyof GOAL]} goal
 */

/**
 * @typedef {object} SelectedPoint
 * @property {number | null} seriesId
 * @property {number | null} dataIndex
 * @property {object} data
 */

const initialState = () => ({
  /**
   * @type {ComparisonExperimentSerializer[]}
   */
  experiments: [],
  scatter: {
    /**
     * @type {ComparisonSamples[]}
     */
    aggregated: [],
    /**
     * @type {{x: string[]; y: string[]}}
     */
    metric: {x: [], y: []},
    /**
     * @type {{x: string | null; y: string | null}}
     */
    axis: {x: null, y: null},
    /**
     * @type {{x: string; y: string}}
     */
    goal: {x: GOAL.MIN, y: GOAL.MIN},
  },
  /**
   * @type {{left: SelectedPoint | null; right: SelectedPoint | null}}
   */
  selectedPoints: {left: null, right: null},
  /**
   * @type {Boolean}
   */
  overlay: false,
});

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    reset(state) {
      Object.entries(initialState()).map(([key, value]) => {
        state[key] = value;
      });
    },
    /**
     *
     * @param state
     * @param {{values: ComparisonSamples[]; metrics: string[]}} data
     */
    setSamples(state, data) {
      if (data.values.length === 0) {
        throw new Error('No samples.');
      }
      data.metrics.push(METRICS.INDEX);
      const metrics = data.metrics.map(metricToOption);
      state.scatter.metric.y = cloneDeep(metrics).filter(({value}) => !SCATTER_Y_EXCLUDE.includes(value));
      // usually a project has the same type of experiment so defaulting to target metric can be useful, likely all
      // experiments in the project will have it.
      state.scatter.axis.y = state.scatter.metric.y[0].value;
      state.scatter.goal.y = GOAL.MIN;
      state.scatter.metric.x = cloneDeep(metrics);
      state.scatter.axis.x = METRICS.INDEX;
      state.scatter.aggregated = sortBy(data.values, function (o) {
        return (o.metrics.find((metric) => metric.name === METRICS.TIMESTAMP) || {}).max;
      });
    },
    /**
     * Set overlay flag
     * @param state
     * @param {Boolean} payload
     */
    setOverlay(state, payload) {
      state.overlay = payload;
    },
    /**
     * Add point to selected points
     * @param state
     * @param {String} type left or right point
     * @param {SelectedPoint} point
     */
    addSelectedPoint(state, {type, point}) {
      state.selectedPoints[type] = point;
    },
    /**
     * Remove all selected points
     * @param state
     */
    clearSelectedPoints(state) {
      state.selectedPoints = {left: null, right: null};
    },
  },
  getters: {
    /**
     * @param state
     * @returns {ComparisonExperimentSerializer|{}}
     */
    left(state) {
      return state.experiments[0] || {};
    },
    /**
     * @param state
     * @returns {ComparisonExperimentSerializer|{}}
     */
    right(state) {
      return state.experiments[1] || {};
    },
    leftTitle(state) {
      if (state.experiments.length < 1) {
        return '';
      }
      return state.experiments[0].guid.shorten('Ex\'');
    },
    rightTitle(state) {
      if (state.experiments.length < 2) {
        return '';
      }
      return state.experiments[1].guid.shorten('Ex\'');
    },

    /**
     * See getters.dataset, combining selected axis (metric name) and goal (min or max) we can create a dimension key
     * for x and y, sending the new dimension to echarts it will re-render plot and change dots
     * @param state
     * @returns {string[]}
     */
    dimensions(state) {
      const {axis, goal} = state.scatter;
      let {x, y} = axis;
      // See the `dataset` getter, author metric doesn't have min or max.
      if (!GOAL_EXCLUDE.includes(x)) {
        x = AXIS_META.createUniqueName(axis.x, goal.x);
      }
      if (!GOAL_EXCLUDE.includes(y)) {
        y = AXIS_META.createUniqueName(axis.y, goal.y);
      }
      return [x, y];
    },
    /**
     * Filtered samples by metrics in case if user has selected a metric that is present in experiment A, but not in B.
     * @param state
     * @returns {Readonly<ComparisonSamples[]>}
     */
    aggregated(state) {
      const {axis, aggregated} = state.scatter;
      return refreeze(aggregated.filter(({metrics}) => {
        // filter-out experiments without matching x or y metric
        const xExist = axis.x === METRICS.INDEX || metrics.find((metric) => metric.name === axis.x);
        const yExist = axis.y === METRICS.INDEX || metrics.find((metric) => metric.name === axis.y);
        return xExist && yExist;
      }));
    },
    /**
     * Turns aggregated data from backend into array of elements for echarts essentially making metrics flat -
     *  {{name: string, max: number, min: number }}
     *  ->
     *  {[name + min + goal]: number, [name + max + goal]: number}
     * This allows run this only when the aggregated array changes (in case if experiments where added or removed).
     * @param state
     * @param getters
     * @returns {Readonly<Record<string, number>[][]>}
     */
    dataset(state, getters) {
      const [left, right] = state.experiments;
      if (!left || !right) {
        return [[], []];
      }
      const leftDataset = [];
      const rightDataset = [];
      getters.aggregated.forEach((sample) => {
        const metrics = sample.metrics.reduce((pv, cv) => {
          let {name, min, max} = cv;
          if (name === METRICS.TIMESTAMP) {
            min *= 1000;
            max *= 1000;
          }
          if (GOAL_EXCLUDE.includes(name)) {
            pv[name] = min;
          } else {
            pv[AXIS_META.createUniqueName(name, GOAL.MIN)] = min;
            pv[AXIS_META.createUniqueName(name, GOAL.MAX)] = max;
          }
          return pv;
        }, {});
        metrics.$configuration = sample.configuration;
        metrics.$experiment = sample.experiment;
        if (left.guid === sample.experiment) {
          metrics[METRICS.INDEX] = leftDataset.length;
          leftDataset.push(metrics);
        } else {
          metrics[METRICS.INDEX] = rightDataset.length;
          rightDataset.push(metrics);
        }
      });
      return refreeze([leftDataset, rightDataset]);
    },
  },
  actions: {
    async fetchExperiments({state}, [aType, aID, bType, bID]) {
      const response = await api.comparison.list(aType, aID, bType, bID);
      state.experiments = response.data;
    },
    async fetchSamples({state, commit}, [aType, aID, bType, bID]) {
      const response = await api.comparison.samples(aType, aID, bType, bID);
      commit('setSamples', response.data);
    },
  },
};
