import urls from '@/api/urls';
import Endpoint from '@/api/resources/base';

export default class Report extends Endpoint {
  ACTIONS = {
    ADD: 'add',
    REMOVE: 'remove',
  };
  ENTITIES = {
    PROJECT: 'project',
    EXPERIMENT: 'experiment',
    CONFIGURATION: 'configuration',
    ALERT: 'alert',
  };
  /**
   * Patch report
   * @param {number} report
   * @param {{name?: string, goal?: string}} data
   * @returns {Promise<import('axios').AxiosResponse<ReportSerializer>>}
   */
  patch = async (report, data) => {
    const url = urls.report.detail.format({report});
    return this.api.patch(url, data);
  };
  /**
   * Create new report
   * @param {{name: string, goal?: string}} data
   * @returns {Promise<import('axios').AxiosResponse<ReportSerializer>>}
   */
  create = async (data) => {
    const url = urls.report.list;
    return this.api.post(url, data);
  };
  /**
   * Create new report with content
   * @param {{name: string, content: object}} data
   * @returns {Promise<import('axios').AxiosResponse<ReportDetailSerializer>>}
   */
  fullCreate = async (data) => {
    const url = urls.report.content;
    return this.api.post(url, data);
  };
  /**
   * Get list of reports
   * @returns {Promise<import('axios').AxiosResponse<ReportSerializer[]>>}
   */
  list = async () => {
    const url = urls.report.list;
    return this.api.get(url);
  };
  /**
   * Get specific report
   * @returns {Promise<import('axios').AxiosResponse<ReportDetailSerializer>>}
   */
  retrieve = async (report) => {
    const url = urls.report.detail.format({report});
    return this.api.get(url);
  };
  /**
   * Delete report
   * @param {number} report id of report
   * @returns {Promise<import('axios').AxiosResponse>}
   */
  destroy = async (report) => {
    const url = urls.report.detail.format({report});
    return this.api.delete(url);
  };
  /**
   * Patch report content. Returns 204 No content
   * @param {number} report
   * @param {Report.ENTITIES} entity
   * @param {UUID | number} identifier
   * @param {Report.ACTIONS} action
   * @returns {Promise<import('axios').AxiosResponse>}
   */
  patchContent = async (report, entity, identifier, action) => {
    const url = urls.report.patchContent.format({report});
    const data = {entity, identifier, action};
    return this.api.patch(url, data);
  };
  /**
   * Get report data
   * @returns {Promise<import('axios').AxiosResponse<ReportEntrySerializer[]>>}
   */
  retrieveData = async (report) => {
    const url = urls.report.data.format({report});
    return this.api.get(url);
  };
  /**
   * Get report members
   * @param {number} report id of report
   * @returns {Promise<import('axios').AxiosResponse<ReportMemberSerializer[]>>}
   */
  retrieveMembers = async (report) => {
    const url = urls.report.member.list.format({report});
    return this.api.get(url);
  };
  /**
   * Delete report member
   * @param {number} report id of report
   * @param {number} member id of member
   * @returns {Promise<import('axios').AxiosResponse>}
   */
  deleteMember = async (report, member) => {
    const url = urls.report.member.detail.format({report, member});
    return this.api.delete(url);
  };
  /**
   * Invite report member
   * @param {number} report id of report
   * @param {{user: {email: string}, role: string}} data
   * @returns {Promise<import('axios').AxiosResponse<ReportMemberSerializer>>}
   */
  inviteMember = async (report, data) => {
    const url = urls.report.member.invite.format({report});
    return this.api.post(url, data);
  };
  /**
   * Patch report member role
   * @param {number} report id of report
   * @param {number} member id of member
   * @param {{role: string}} data
   * @returns {Promise<import('axios').AxiosResponse<ReportMemberSerializer>>}
   */
  patchMemberRole = async (report, member, data) => {
    const url = urls.report.member.detail.format({report, member});
    return this.api.patch(url, data);
  };
  /**
   * Transfer report ownership
   * @param {number} report id of report
   * @param {{email: string}} data
   * @returns {Promise<import('axios').AxiosResponse<ReportMemberSerializer>>}
   */
  transferOwnership = async (report, data) => {
    const url = urls.report.member.transferOwnership.format({report});
    return this.api.post(url, data);
  };
  /**
   * Returns list of related sessions
   * @param report
   * @returns {Promise<import('axios').AxiosResponse<DeviceSessionReportSerializer[]>>}
   */
  sessions = async (report) => {
    const url = urls.report.sessions.format({report});
    return this.api.get(url);
  };
}
