import Endpoint from '@/api/resources/base';
import urls from '@/api/urls';

export default class SLMSearch extends Endpoint {
  /**
   * List of saved searches based on user's account
   * @return {Promise<import('axios').AxiosResponse<SavedSearchSerializer[]>>}
   */
  list = async () => {
    return this.api.get(urls.slm.search.list);
  };

  /**
   * Save search
   * @param {{query: string}} data
   * @returns {Promise<import('axios').AxiosResponse<SavedSearchSerializer>>}
   */
  create = async (data) => {
    const url = urls.slm.search.list;
    return this.api.post(url, data);
  };

  /**
   * Delete search
   * @param {number} searchId id of saved search
   * @returns {Promise<import('axios').AxiosResponse>}
   */
  destroy = async (searchId) => {
    const url = urls.slm.search.detail.format({searchId});
    return this.api.delete(url);
  };
}
