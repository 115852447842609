import sys from '@/store/modules/sys';
import project from '@/store/modules/project';
import definition from '@/store/modules/definition';
import experiment from '@/store/modules/experiment';
import member from '@/store/modules/member';
import overview from '@/store/modules/overview';
import team from '@/store/modules/team';
import subscription from '@/store/modules/subscription';
import filters from '@/store/modules/filters';
import samples from '@/store/modules/samples';
import report from '@/store/modules/report';
import monitors from '@/store/modules/monitors';
import comparison from '@/store/modules/comparison';
import device from '@/store/modules/device';
import session from '@/store/modules/session';

export default {
  sys,
  project,
  definition,
  experiment,
  member,
  overview,
  team,
  subscription,
  filters,
  samples,
  comparison,
  report,
  monitors,
  device,
  session,
};
