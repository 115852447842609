import qs from 'qs';
import {ALEVEL, DEFAULT_PAGE_LIMIT, HINT_NAMES} from '@/constants';
import {requestAction} from '@/api/helpers';
import {apiUrl} from '@/api/client';
import {substring} from '@/utils/text';
import {setAttrs} from '@/utils/object';
import api from '@/api';

const createFormState = () => ({
  name: '',
  description: '',
  cloneID: null,
  loading: false,
  error: {detail: '', fields: {}},
  state: true,
  validation: {description: {max_length: 100}},
  nextActions: [
    {text: 'Walk Through Wizard', value: 'next'},
    {text: 'Scratch', value: 'scratch'},
    {text: 'Clone Example', value: 'clone'},
  ],
  nextActionSelected: 'next',
  requires: {},
});

const editFormState = () => ({
  name: '',
  description: '',
  id: '',
  loading: false,
  error: {detail: '', fields: {}},
  state: true,
});

export default {
  namespaced: true,
  state: () => ({
    /** @type {Project[]} **/
    list: [],
    /** @type {Project[]} **/
    recent: [],
    /** @type {(Project | null)} **/
    current: null,
    /** @type {Paginated} **/
    pagination: {},
    currentLoading: false,
    moreLoading: false,
    recentLoading: false,
    definitionTemplates: [],
    fullTemplates: [],
    term: '',
    loading: false,
    errorMsg: '',
    selected: '',
    createForm: createFormState(),
    editForm: editFormState(),
    searchMode: false,
  }),
  getters: {
    /**
     * @param state
     * @returns {Project[]}
     */
    filteredList(state) {
      return state.list.search(state.term);
    },
    /**
     * @param state
     * @returns {Project[]}
     */
    filteredRecent(state) {
      return state.recent.search(state.term);
    },
    /**
     * @param state
     * @returns {boolean}
     */
    hasTemplate(state) {
      return !!state.current?.created_from_id;
    },
    /**
     * Project create: buttons to choice from
     * @param state
     * @returns {[{hintName: string, disabled: boolean, text: string, value: string}, {hintName: string, text: string, value: string}, {hintName: string, disabled: boolean, text: string, value: string}]}
     */
    createFormNextActions(state) {
      return [
        {
          text: 'Walk Through Wizard',
          value: 'next',
          disabled: state.definitionTemplates.length === 0,
          hintName: HINT_NAMES.wizardTooltip,
        },
        {
          text: 'Scratch',
          value: 'scratch',
          hintName: HINT_NAMES.scratchTooltip,
        },
        {
          text: 'Clone Example',
          value: 'clone', disabled: state.fullTemplates.length === 0,
          hintName: HINT_NAMES.cloneTooltip,
        },
      ];
    },
    isViewer(state) {
      return (state.current?.access_level || ALEVEL.NOBODY) > ALEVEL.NOBODY;
    },
    isEditor(state) {
      return (state.current?.access_level || ALEVEL.NOBODY) > ALEVEL.PROJECT_VIEWER;
    },
    isManager(state) {
      return (state.current?.access_level || ALEVEL.NOBODY) > ALEVEL.PROJECT_EDITOR;
    },
    isProjectOwner(state) {
      return (state.current?.access_level || ALEVEL.NOBODY) > ALEVEL.PROJECT_MANAGER;
    },
    isAccountOwner(state) {
      return (state.current?.access_level || ALEVEL.NOBODY) > ALEVEL.PROJECT_OWNER;
    },
    accessLevel(state) {
      return state.current?.access_level || ALEVEL.NOBODY;
    },
  },
  mutations: {
    addRequire(state, require) {
      Object.assign(state.createForm.requires, require);
    },

    clearRequires(state) {
      state.createForm.requires = {};
    },

    resetCreateForm(state) {
      state.createForm = createFormState();
    },

    setCreateForm(state, value) {
      Object.assign(state.createForm, value);
    },

    prependToList(state, data) {
      state.pagination.count += 1;
      state.list = [data, ...state.list];
    },

    setLoading(state, {loading, errorMsg = ''}) {
      state.loading = loading;
      state.errorMsg = errorMsg;
    },

    setTerm(state, term) {
      state.term = term;
    },

    /**
     * @param state
     * @param {UUID | ''} guid
     */
    setSelected(state, guid) {
      state.selected = guid;
      if (guid.length === 0) {
        state.current = null;
      }
    },

    setProject(state, {guid, ...rest}) {
      const setter = (el) => {
        if (el.guid === guid) {
          return {...el, ...rest};
        }
        return el;
      };
      state.list = state.list.map(setter);
      state.recent = state.recent.map(setter);
    },

    /**
     * For each project in membership, if membership role owner, change project's owner
     * @param state
     * @param {PlanUser} user new owner
     * @param {string[]} projects project uuids to update
     */
    setProjectsOwner(state, {user, projects}) {
      /**
       * @param {ProjectSerializer} el
       */
      const setter = (el) => {
        if (!projects.includes(el.guid)) {
          return el;
        }
        return {...el, author: user};
      };
      state.list = state.list.map(setter);
      state.recent = state.recent.map(setter);
    },

    removeProject(state, guid) {
      const deleter = (el) => el.guid !== guid;
      state.list = state.list.filter(deleter);
      state.recent = state.recent.filter(deleter);
      state.pagination.count -= 1;
    },

    setThis(state, obj) {
      Object.assign(state, obj);
    },

    setProjectConfig(state, data) {
      state.current.created_from = data['created_from'];
      state.current.config_template = data['config_template'];
    },

    /**
     * @param state
     * @param {ProjectSerializer} data
     */
    setCurrent(state, data) {
      state.current = data;
    },

    updateCurrent(state, data) {
      setAttrs(state.current, data);
    },

    cleanCurrent(state) {
      state.current = null;
    }
  },


  actions: {
    async createProject({state, commit}, onSuccess) {
      commit('setCreateForm', {
        loading: true,
        state: null,
        error: createFormState().error,
      });
      const cf = {...state.createForm};
      const data = {name: cf.name, description: cf.description};
      if (state.createForm.nextActionSelected !== 'scratch') {
        data.clone = cf.cloneID;
        data.requires = cf.requires;
      }
      const [response, error] = await requestAction('post', apiUrl.projects, {data});
      if (error) {
        commit('setCreateForm', {loading: false, error, state: false});
      } else if (response) {
        commit('resetCreateForm');
        commit('prependToList', response.data);
        onSuccess(response.data);
      }
    },

    async deleteProject({state}, project) {
      const projectId = project || state.selected;
      const url = apiUrl.project.format({projectId});
      return await requestAction('delete', url);
    },

    async fetchList({state, commit}, resetSearch = false) {
      if (state.list.length > 0 && !resetSearch) {
        return;
      }
      state.searchMode = false;
      commit('setLoading', {loading: true});
      try {
        const response = await api.projects.list();
        const {results, ...pagination} = response.data;
        state.list = results;
        state.pagination = pagination;
      } finally {
        commit('setLoading', {loading: false});
      }
    },

    async refresh({state}) {
      const response = await api.projects.list(0, state.list.length);
      const {results, ...pagination} = response.data;
      state.list = results;
      state.pagination = pagination;
      if (state.recent.length > 0) {
        const recent = await api.projects.list(0, state.recent.length, {ordering: '-reported_at'});
        state.recent = recent.data.results;
      }
    },

    async fetchMore({state}) {
      if (state.searchMode) {
        return;
      }
      state.moreLoading = true;
      const response = await api.projects.list(state.list.length, DEFAULT_PAGE_LIMIT, {search: state.term});
      try {
        const {results, ...pagination} = response.data;
        state.list.push(...results);
        state.pagination = pagination;
      } finally {
        state.moreLoading = false;
      }
    },

    async fetchRecentList({state}) {
      if (state.recent.length > 0) {
        return;
      }
      state.recentLoading = true;
      try {
        const response = await api.projects.list(0, 8, {ordering: '-reported_at'});
        const {results} = response.data;
        state.recent = results;
      } finally {
        state.recentLoading = false;
      }
    },

    async fetchTemplateGroups({state, commit}, data) {
      const templateType = data.definition_only ? 'definitionTemplates' : 'fullTemplates';
      if (state[templateType].length > 0) {
        return;
      }
      const url = `${apiUrl.templates}?${qs.stringify(data)}`;
      const [response, error] = await requestAction('get', url, {data});
      if (response) {
        commit('setThis', {[templateType]: response.data});
        if (templateType === 'definitionTemplates' && response.data.length === 0) {
          commit('setCreateForm', {nextActionSelected: 'scratch'});
        }
      } else {
        console.error(error);
      }
    },

    /**
     * Change project's owner
     * @param state
     * @param commit
     * @param {string} guid project's uuid
     * @param {string} email owner's email
     * @returns {Promise<void>}
     */
    async transfer({state, commit}, {guid, email}) {
      const url = substring(apiUrl.transferOwnership, {projectId: guid});
      const data = {email};
      const [response, error] = await requestAction('post', url, {data});
      if (response) {
        commit('setProjectsOwner', {user: response.data.user, projects: [guid]});
        return;
      }
      throw error;
    },

    /**
     * Enables or disables access to the project for all account members. Switching this state will add or removes
     * members from project, request returns list of all project members.
     * @param state
     * @param commit
     * @param enable
     * @returns {Promise<Member[]>}
     */
    async accountAccess({state, commit}, enable) {
      const method = enable ? 'POST' : 'DELETE';
      const [response, error] = await requestAction(method, apiUrl.projectAccountAccess.format({
        projectId: state.selected,
      }));
      if (!error) {
        commit('setProject', {guid: state.selected, has_account_access: enable});
        return response.data;
      }
      throw error;
    },

    /**
     * Fetches project details from backend and saves in local store
     * @param state
     * @param commit
     * @param {UUID} guid
     * @returns {Promise<boolean>}
     */
    async fetchCurrentProject({state, commit}, guid) {
      if (state.current?.guid === guid) {
        return true;
      }
      commit('cleanCurrent');
      state.currentLoading = true;
      try {
        const response = await api.projects.retrieve(guid);
        commit('setCurrent', response.data);
      } catch (e) {
        console.error(e);
        return false;
      }
      state.currentLoading = false;
      return true;
    },

    async refreshCurrentProject({state, commit}) {
      const guid = state.current?.guid;
      if (!guid) {
        return;
      }
      try {
        const response = await api.projects.retrieve(guid);
        commit('updateCurrent', response.data);
      } catch (e) {
        console.error(e);
        return false;
      }
    },

    async searchProjects({state, commit}) {
      if (state.searchMode) {
        return;
      }
      commit('setLoading', {loading: true});
      state.searchMode = true;
      try {
        const response = await api.projects.list(null, null, {search: state.term});
        state.list = response.data;
      } catch (e) {
        console.error(e);
      }
      commit('setLoading', {loading: false});
    }
  },
};
