import {version} from 'vue';
import {getDeviceTableFields, getDeviceFlatList} from '@/api/services/device';
import api from '@/api';

const DEVICES_PER_PAGE = 10;

export default {
  namespaced: true,
  state: () => ({
    /** @type {Record<number, DeviceSerializer[]>} **/
    pages: {},
    pagination: {
      onPage: 0,
      count: 0,
      perPage: DEVICES_PER_PAGE,
    },
    /** @type {TableField[]} **/
    fields: [],
    term: '',
    sortBy: '',
    loading: false,
  }),
  getters: {
    /**
     * @param state
     * @return {Record<string, string>[]}
     */
    list(state) {
      const {onPage} = state.pagination;
      if (version.startsWith('3')) {
        return state.pages[onPage] || [];
      }
      return getDeviceFlatList(state.pages[onPage] || []);
    },
  },
  mutations: {
    /**
     * Looks for a device by guid, if device exist, updates it with the data provided.
     * @param state
     * @param {{guid: string} & {[key in string]: any}} data
     */
    updateDevice(state, data) {
      for (const [page, devices] of Object.entries(state.pages)) {
        for (let i = 0; i < devices.length; i++) {
          const device = devices[i];
          if (device.guid === data.guid) {
            this._vm.$set(state.pages[page], i, {...device, ...data});
          }
        }
      }
    },
    /**
     * Sets device pages update pagination state and discover table fields.
     * @param state
     * @param {DeviceSerializer[]} results
     * @param {number} count
     * @param {number} page
     */
    setDevicePage(state, {results, count, page}) {
      this._vm.$set(state.pages, page, results);
      state.pagination = {
        count,
        onPage: page,
        perPage: DEVICES_PER_PAGE,
      };
      state.fields = getDeviceTableFields(results);
    },
    setTerm(state, term) {
      state.term = term;
    },
    setSortBy(state, sortBy) {
      state.sortBy = sortBy;
    }
  },
  actions: {
    /**
     * @param state
     * @param commit
     * @param {number | 'next' | 'prev'} page page number or direction
     * @return {Promise<void>}
     */
    async fetchDevicePage({state, commit}, page) {
      if (page === 'next') {
        page = state.pagination.onPage + 1;
      } else if (page === 'prev') {
        page = state.pagination.onPage - 1;
      } else if (typeof page === 'number') {
        state.pagination.onPage = page;
      }
      state.loading = true;
      try {
        const response = await api.slm.device.list(page, DEVICES_PER_PAGE, state.term, state.sortBy);
        const {count, results} = response.data;
        commit('setDevicePage', {count, results, page});
      } finally {
        state.loading = false;
      }
    },
    /**
     * Fetches list of session for a device, after verifies if the device is present on any page in
     * `state.device.pages`, if it does and if not search `session_count` will be synchronized in case
     * if after user has open the discovery page a new session has been added.
     * I don't like it, but I don't like the idea of updating table every 5 seconds nether.
     * @param state
     * @param commit
     * @param {string} deviceId
     * @param {string} search search by session guid and tags
     * @returns {Promise<BriefDeviceSessionSerializer[]>}
     */
    async fetchAndSyncSessionOnDevice({state, commit}, {deviceId, search}) {
      const response = await api.slm.device.sessions(deviceId, search);
      if (!search) {
        commit('updateDevice', {guid: deviceId, session_count: response.data.length});
      }
      return response.data;
    },
  }
};
