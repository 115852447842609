import {DEFAULT_PAGE_LIMIT, MANAGER_CALL, STAGES} from '@/constants';
import {findObjectIndex, findStringInObject} from '@/utils/array';
import {setAttrs} from '@/utils/object';
import {apiUrl} from '@/api/client';
import {requestAction} from '@/api/helpers';
import api from '@/api';

const ADD_METHOD = {
  REPLACE: 1,
  APPEND: 2,
};

const initialState = () => ({
  /** @type {DefinitionSerializer[]} **/
  list: [],
  /** @type {Paginated} **/
  pagination: {
    count: 0,
  },
  /** @type {Record<number, number>} maps a definition id to number of samples in the definition **/
  sampleCount: {},
  term: '',
  loading: true,
  moreLoading: false,
  errorMsg: '',
  // for cache purposes store here project's id and then check it
  projectId: '',
  selected: null,
  selectedList: [],

  availableStages: [
    {text: 'Readiness', value: STAGES.READINESS},
    {text: 'Optimization', value: STAGES.OPTIMIZATION},
    {text: 'Refinement', value: STAGES.REFINEMENT},
    {text: 'Validation', value: STAGES.VALIDATION},
  ],
  /** @type {string[]} **/
  selectedStages: [STAGES.READINESS, STAGES.OPTIMIZATION, STAGES.VALIDATION],
  // updates pagination count.
  updateCount: 0,
});

export default {
  namespaced: true,
  state: initialState,
  getters: {
    filtered(state) {
      return state.list.search(state.term);
    },

    current(state, getters) {
      return getters['filtered'].find(({is_active}) => is_active);
    },

    recent(state, getters) {
      return getters['filtered'].filter(({is_active}) => !is_active);
    },

    optimizerCall(state) {
      const stages = state.selectedStages.join(',');
      return MANAGER_CALL.format({stages});
    },
  },
  mutations: {
    reset(state) {
      setAttrs(state, initialState());
    },

    /**
     * @param state
     * @param {(Paginated<DefinitionTrackSerializer[]> | DefinitionTrackSerializer[])} data
     * @param {ADD_METHOD[keyof ADD_METHOD]} method
     */
    addToList(state, {data, method}) {
      // If ALL elements are requested from backend, `data` will be non-paginated
      if (Array.isArray(data)) {
        data = {results: data, ...state.pagination};
      }

      const {results, ...pagination} = data;
      // noinspection JSValidateTypes
      if (state.pagination.count && pagination.count > state.pagination.count) {
        state.updateCount = pagination.count - state.pagination.count;
      } else {
        state.updateCount = 0;
      }
      state.pagination = pagination;

      if (results.length === 0) {
        if (method === ADD_METHOD.REPLACE) {
          state.list = [];
        }
        return;
      }

      if (method === ADD_METHOD.REPLACE) {
        state.list = results;
      } else if (method === ADD_METHOD.APPEND) {
        state.list = [...state.list, ...results];
      }
    },

    /**
     * @deprecated DEAD CODE?
     */
    setSelected(state, id) {
      state.selected = state.list.find((el) => el.id === id);
    },

    setLoading(state, {loading, errorMsg = ''}) {
      state.loading = loading;
      state.errorMsg = errorMsg;
    },

    setTerm(state, term) {
      state.term = term;
    },

    /**
     * @deprecated DEAD CODE?
     */
    setProjectId(state, projectId) {
      state.projectId = projectId;
    },

    resetStage(state) {
      state.selectedStages = initialState().selectedStages;
    },

    setStage(state, value) {
      const getIndex = (stageValue) => state.availableStages.findIndex((stage) => stage.value === stageValue);
      // Use `.slice()` to shallow copy `value` because `.sort()` works in place.
      state.selectedStages = value.slice().sort((a, b) => getIndex(a) - getIndex(b));
    },

    pushDefinition(state, value) {
      if (state.list.length === 0) {
        state.list.push(value);
        return;
      }
      const [idx, obj] = findObjectIndex(state.list, {id: value.id});
      if (idx > -1) {
        state.list = [...state.list.slice(0, idx), {...obj, ...value}, ...state.list.slice(idx + 1)];
      } else {
        state.list = [value, ...state.list];
      }
    },

    setDefinition(state, {id, ...rest}) {
      state.list = state.list.map((el) => {
        if (el.id === id) {
          return {...el, ...rest};
        }
        return el;
      });
    },

    pushSelected(state, id) {
      state.selectedList.push(id);
    },

    removeSelected(state, id) {
      const idIdx = state.selectedList.indexOf(id);
      state.selectedList.splice(idIdx, 1);
    },

    /**
     * Push all definitions ids to selectedList. Can't use getters in mutations so can't use this.filtered.
     */
    selectAll(state) {
      if (state.term === '') {
        state.selectedList = state.list.map((el) => el.id);
        return;
      }
      const filtered = findStringInObject(state.list, ['version', 'description', 'tags'], state.term);
      state.selectedList = filtered.map((el) => el.id);
    },

    clearSelectedList(state) {
      state.selectedList = [];
    },
  },
  actions: {
    async refresh({state, commit}) {
      if (state.loading || state.moreLoading) {
        return;
      }
      const limit = state.list.length ? state.list.length + state.updateCount : DEFAULT_PAGE_LIMIT;
      try {
        const response = await api.definitions.track(
          state.projectId,
          0,
          limit,
          {refresh: 1, search: state.term}
        );
        commit('addToList', {data: response.data, method: ADD_METHOD.REPLACE});
      } catch (error) {
        console.error(error.toString());
      }
    },

    async createDefinition({state}, {data, projectId = state.projectId}) {
      const url = apiUrl.definitions.format({projectId});
      return await requestAction('post', url, {data});
    },

    /**
     * Deletes one or multiple definitions, returns array of errors, if any
     * @param state
     * @param {number[]} ids
     * @returns {Promise<[number[], string[]]>}
     */
    async delete({state}, ids) {
      const deleted = [];
      const errors = [];
      for (const id of ids) {
        try {
          await api.definitions.destroy(state.projectId, id);
          deleted.push(id);
        } catch (e) {
          errors.push(e.toString());
        }
      }
      state.selectedList = state.selectedList.filter((id) => !deleted.includes(id));
      state.list = state.list.filter(({id}) => !deleted.includes(id));
      return [deleted, errors];
    },

    /**
     * Fetches sample counts and updates definitions with it.
     * If all definition has a sample count set, refreshes only current active definition.
     * @param state
     * @param commit
     * @returns {Promise<void>}
     */
    async fetchSampleCounts({state, commit}) {
      if (state.list.length === 0) {
        return;
      }
      if (Object.keys(state.sampleCount).length === 0) {
        try {
          const response = await api.definitions.countSamples(state.projectId);
          state.sampleCount = response.data.reduce((pv, cv) => {
            pv[cv.id] = cv.samples;
            return pv;
          }, {});
        } catch (e) {
          console.error(e);
        }
      } else {
        const definitionID = state.list[0].id;
        try {
          const response = await api.definitions.countSamples(state.projectId, definitionID);
          state.sampleCount[definitionID] = response.data.samples;
        } catch (e) {
          console.error(e);
        }
      }
    },

    /**
     * Initial definition list fetch
     * @param state
     * @param commit
     * @param {UUID} projectId
     * @returns {Promise<void>}
     */
    async fetchList({state, commit}, projectId) {
      if (projectId === state.projectId) {
        return;
      }
      state.projectId = projectId;
      state.loading = true;
      state.errorMsg = '';
      try {
        const response = await api.definitions.track(state.projectId);
        commit('addToList', {data: response.data, method: ADD_METHOD.REPLACE});
      } catch (error) {
        console.error(error.toString());
        state.errorMsg = error.toString();
      }
      state.loading = false;
    },

    async fetchMore({state, commit}, {limit} = {limit: DEFAULT_PAGE_LIMIT}) {
      if (!state.pagination.next || state.moreLoading) {
        return;
      }
      state.moreLoading = true;
      try {
        const response = await api.definitions.track(state.projectId, state.list.length, limit, {search: state.term});
        commit('addToList', {data: response.data, method: ADD_METHOD.APPEND});
      } finally {
        state.moreLoading = false;
      }
    },

    async updateDefinitionTags({state, commit}, {id, tags}) {
      const url = apiUrl.definitionTags.format({projectId: state.projectId, definitionId: id});
      const [response, error] = await requestAction('put', url, {data: {tags}});
      if (response) {
        commit('setDefinition', {id, tags: response.data.tags});
      }
      return [response, error];
    },

    async updateDefinition({state, commit}, {id, ...data}) {
      const url = apiUrl.definition.format({projectId: state.projectId, definitionId: id});
      const [response, error] = await requestAction('patch', url, {data});
      if (response) {
        commit('setDefinition', response.data);
      }
      return [response, error];
    },

    async search({state, commit}, search) {
      if (state.loading) {
        return;
      }
      commit('setTerm', search);
      commit('setLoading', {loading: true});
      try {
        const response = await api.definitions.track(state.projectId, 0, DEFAULT_PAGE_LIMIT, {search});
        commit('addToList', {data: response.data, method: ADD_METHOD.REPLACE});
      } catch (e) {
        console.error(e);
      }
      commit('setLoading', {loading: false});
    }
  },
};
