import urls from '@/api/urls';
import Endpoint from '@/api/resources/base';

export default class Users extends Endpoint {
  /**
   * @return {Promise<import('axios').AxiosResponse<UserSerializer[]>>}
   */
  list = async () => {
    return this.api.get(urls.users.list);
  };

  /**
   * Upload new user avatar. Return 201 Created.
   * @param {UserAvatarSerializer} data
   * @return {Promise<import('axios').AxiosResponse<{avatar: string}>>}
   */
  uploadAvatar = async (data) => {
    return this.api.patch(urls.users.uploadAvatar, data);
  };

  /**
   * Connected social auth accounts.
   * @return {Promise<import('axios').AxiosResponse<string[]>>}
   */
  connectedAccounts = async () => {
    return this.api.get(urls.users.connectedAccounts);
  }
}
