/**
 * Creates table fields definitions from the devices list.
 * Device list is required because it contains dynamic device properties (such as
 * ip, mac, etc.) which we cannot know in advance.
 * @param {DeviceSerializer[]} devices
 * @returns {TableField<DeviceSerializer>[]}
 */
export function getDeviceTableFields(devices) {
  /**
   * Default formatter, returns value as is.
   * @type {FieldFormatter<DeviceSerializer>}
   */
  const formatter = (v) => v;

  /**
   * Default `is clickable` function, returns true if value is truthy.
   * @type {FieldClickable<DeviceSerializer>}
   */
  const clickable = (v) => !!v;

  /**
   * @type {TableField}
   */
  const DEVICE_ID = {
    key: 'device_id',
    label: 'DEVICE ID',
    sortable: true,
    formatter,
    clickable,
  };

  /**
   * @type {TableField}
   */
  const SESSIONS = {
    key: 'session_count',
    label: 'sessions',
    sortable: true,
    formatter: (v, _, i) => `${v || 0} (${v ? i['event_count'] : 0} events)`,
    clickable,
  };

  /**
   * @type {TableField}
   */
  const LAST_UPDATED = {
    key: 'last_updated',
    label: 'last updated',
    sortable: true,
    formatter: (v) => v && new Date(v).strftime('__long__'),
    clickable: (_) => false,
  };

  /**
   * @type {TableField}
   */
  const LAYOUT = {
    key: 'has_chip',
    label: 'layout',
    sortable: true,
    clickable: (_) => true,
  };
  /**
   * @type {TableField}
   */
  const ACTIONS = {
    key: 'actions',
    sortable: false,
    clickable: (_) => false,
  };

  let dynamic = Array.from(new Set(devices.flatMap((d) => Object.keys(d.props))));
  dynamic.sort();
  dynamic = dynamic.map((key) => ({
    key,
    sortable: true,
    clickable,
    formatter,
  }));

  return [DEVICE_ID, SESSIONS, ...dynamic, LAST_UPDATED, LAYOUT, ACTIONS];
}

/**
 * Creates a flat list of devices from the nested list by moving `props` object attributes on top.
 * @param {DeviceSerializer[]} devices
 * @returns {Device.DeviceInfo}
 */
export function getDeviceFlatList(devices) {
  return devices.map((device) => {
    const {props, ...rest} = device;
    return {...rest, ...props};
  });
}
