export default {
  project: {
    list: '/projects',
    get detail() {
      return `${this.list}/:project`;
    },
    get totalExperiments() {
      return `${this.detail}/total-experiments`;
    },
    get accountAccess() {
      return `${this.detail}/account-access`;
    },
    get wizard() {
      return `${this.list}/wizard`;
    },
    get templates() {
      return `${this.list}/wizard-templates`;
    },
    get availableForReport() {
      return `${this.list}/available-for-report`;
    },
    member: {
      get list() {
        return `${this.$parent.detail}/members`;
      },
      get detail() {
        return `${this.list}/:member`;
      },
      get invite() {
        return `${this.list}/invite`;
      },
      get transferTo() {
        return `${this.$parent.detail}/transfer-ownership`;
      },
      get transferOwnership() {
        return `${this.list}/transfer-ownership`;
      },
    },
    definition: {
      get list() {
        return `${this.$parent.detail}/experiment-definitions`;
      },
      get detail() {
        return `${this.list}/:definition`;
      },
      get tags() {
        return `${this.detail}/tags`;
      },
      get files() {
        return `${this.detail}/files`;
      },
      get countList() {
        return `${this.list}/count`;
      },
      get count() {
        return `${this.detail}/count`;
      },
      get track() {
        return `${this.list}/track`;
      },
    },
    experiment: {
      get list() {
        return `${this.$parent.detail}/experiments`;
      },
      get detail() {
        return `${this.list}/:experiment`;
      },
      get tags() {
        return `${this.detail}/tags`;
      },
      get track() {
        return `${this.list}/track`;
      },
      get metadata() {
        return `${this.detail}/metadata`;
      },
      get inventory() {
        return `${this.detail}/inventory`;
      },
      get downloadable() {
        return `${this.detail}/downloadable`;
      },
      get aggregated() {
        return `${this.list}/aggregated`;
      },
      get agTags() {
        return `${this.list}/ag-tags`;
      },
      get agStatus() {
        return `${this.detail}/ag-status`;
      },
      get agConfiguration() {
        return `${this.detail}/ag-configuration`;
      },
      get history() {
        return `${this.detail}/history`;
      },
      sample: {
        get list() {
          return `${this.$parent.detail}/samples`;
        },
        get count() {
          return `${this.list}/count`;
        },
        get statistics() {
          return `${this.list}/statistics`;
        },
        get tTest() {
          return `${this.list}/t-test`;
        },
      },
      configuration: {
        get list() {
          return `${this.$parent.detail}/configurations`;
        },
        get detail() {
          return `${this.list}/:configuration`;
        },
        get download() {
          return `${this.detail}/download`;
        },
      },
      filter: {
        get list() {
          return `${this.$parent}/filters`;
        },
        get detail() {
          return `${this.list}/:filter`;
        },
      },
    },
  },
  hint: {
    list: '/hints',
    get detail() {
      return `${this.list}/:slug`;
    },
  },
  login: {
    list: '/login',
    provider: {
      get list() {
        return `${this.$parent.list}/provider`;
      },
      get check() {
        return `${this.list}/check`;
      }
    },
  },
  account: {
    list: '/accounts',
    self: {
      get detail() {
        return `${this.$parent.list}/user`;
      },
      get notifications() {
        return `${this.detail}/notifications`;
      },
    },
    get registration() {
      return `${this.list}/register`;
    },
    get verifyEmail() {
      return `${this.list}/verify-registration`;
    },
    get resendVerification() {
      return `${this.list}/resend-verification-link`;
    },
    get verifyInvite() {
      return `${this.list}/verify-invite`;
    },
    get sendResetPasswordLink() {
      return `${this.list}/send-reset-password-link`;
    },
    get resetPassword() {
      return `${this.list}/reset-password`;
    },
    get login() {
      return `${this.list}/login`;
    },
    get logout() {
      return `${this.list}/logout`;
    },
    get changePassword() {
      return `${this.list}/change-password`;
    },
    get changeEmail() {
      return `${this.list}/change-email`;
    },
    get changeEmailVerify() {
      return `${this.list}/verify-email`;
    },
  },
  plan: {
    list: '/plan',
    get my() {
      return `${this.list}/my`;
    },
    get orders() {
      return `${this.list}/orders`;
    },
    get purchase() {
      return `${this.list}/purchase`;
    },
    get listHtml() {
      return `${this.list}/list/`;
    },
    get fsSSO() {
      return `${this.list}/sso`;
    },
    account: {
      get list() {
        return `${this.$parent.list}/accounts`;
      },
      get detail() {
        return `${this.list}/:account`;
      },
    },
    role: {
      get list() {
        return `${this.$parent.list}/roles`;
      },
      get detail() {
        return `${this.list}/:role`;
      },
      get users() {
        return `${this.list}/users`;
      },
    },
  },
  template: {
    list: '/templates',
  },
  event: {
    list: '/events',
    get markAsRead() {
      return `${this.list}/mark-as-read`;
    },
  },
  users: {
    list: '/users',
    get uploadAvatar() {
      return `${this.list}/user-avatar`;
    },
    get connectedAccounts() {
      return `${this.list}/connected-accounts`;
    },
  },
  system: {
    version: '/system/version',
  },
  v2: {
    list: '/v2',
    experiment: {
      get list() {
        return `${this.$parent.list}/experiments`;
      },
      get detail() {
        return `${this.list}/:experiment`;
      },
      sample: {
        get list() {
          return `${this.$parent.detail}/samples`;
        },
        get stream() {
          return `${this.list}/stream`;
        },
      },
      configuration: {
        get list() {
          return `${this.$parent.detail}/configurations`;
        },
        get stream() {
          return `${this.list}/stream`;
        },
      },
    },
  },
  report: {
    list: '/reports',
    get content() {
      return `${this.list}/content`;
    },
    get detail() {
      return `${this.list}/:report`;
    },
    get patchContent() {
      return `${this.list}/:report/content`;
    },
    get data() {
      return `${this.list}/:report/data`;
    },
    get sessions() {
      return `${this.detail}/sessions`;
    },
    member: {
      get list() {
        return `${this.$parent.detail}/members`;
      },
      get detail() {
        return `${this.list}/:member`;
      },
      get invite() {
        return `${this.list}/invite`;
      },
      get transferOwnership() {
        return `${this.list}/transfer-ownership`;
      },
    },
  },
  comparison: {
    list: '/comparison/:aType/:aID/:bType/:bID',
    get samples() {
      return `${this.list}/samples`;
    },
    get knobs() {
      return `${this.list}/knobs`;
    },
  },
  slm: {
    device: {
      list: '/slm/devices',
      get detail() {
        return `${this.list}/:deviceId`;
      },
      get sessions() {
        return `${this.detail}/sessions`;
      },
      get trackSessions() {
        return `${this.detail}/track-sessions`;
      },
      get relatedProjects() {
        return `${this.detail}/related-projects`;
      },
      get udf() {
        return `${this.detail}/udf`;
      },
      get blocks() {
        return `${this.detail}/blocks`;
      },
      get chips() {
        return `${this.detail}/chips`;
      }
    },
    alert: {
      list: '/slm/alerts',
      get detail() {
        return `${this.list}/:alertId`;
      },
      get bulkArchive() {
        return `${this.list}/bulk-archive`;
      },
      get dateRange() {
        return `${this.list}/date-range`;
      },
      get samples() {
        return `${this.detail}/samples`;
      },
      get relatedProjects() {
        return `${this.detail}/related-projects`;
      },
      get relatedEvents() {
        return `${this.detail}/related-events`;
      },
      get trackRelatedEvents() {
        return `${this.detail}/track-related-events`;
      },
      get statistics() {
        return `${this.detail}/statistics`;
      },
      get event() {
        return `${this.detail}/event`;
      },
      get location() {
        return `${this.detail}/location`;
      },
      get brief() {
        return `${this.detail}/brief`;
      },
      get compare() {
        return `${this.detail}/compare`;
      },
      get pmuMargin() {
        return `${this.detail}/pmu-margin`;
      },
      get sensorLayout() {
        return `${this.detail}/sensor-layout`;
      },
      get metrics() {
        return `${this.detail}/metrics`;
      },
    },
    overview: {
      list: '/slm/overview',
      get chart() {
        return `${this.list}/chart`;
      }
    },
    session: {
      list: '/slm/sessions',
      get detail() {
        return `${this.list}/:sessionId`;
      },
      get bulkArchive() {
        return `${this.list}/bulk-archive`;
      },
      get samples() {
        return `${this.detail}/samples`;
      },
      get metrics() {
        return `${this.detail}/metrics`;
      },
      get alerts() {
        return `${this.detail}/alerts`;
      },
      get statistics() {
        return `${this.detail}/statistics`;
      },
      get sensorStatistics() {
        return `${this.detail}/sensors-statistics`;
      },
    },
    label: {
      list: '/slm/labels',
    },
    search: {
      list: '/slm/saved-search',
      get detail() {
        return `${this.list}/:searchId`;
      },
    },
    configuration: {
      list: '/slm/configurations',
      get detail() {
        return `${this.list}/:configurationId`;
      },
    },
    deviceDisplayPreset: {
      list: '/slm/device-display-presets',
      get detail() {
        return `${this.list}/:id`;
      },
    },
  },
  init: function () {
    for (const i in this) {
      const isGetter = Object.getOwnPropertyDescriptor(this, i).get !== undefined;
      if (!isGetter && typeof this[i] === 'object') {
        this[i].init = this.init;
        this[i].init();
        this[i].$parent = this;
      }
    }
    return this;
  },
  /**
   * Returns full test server url.
   * @param absolute
   */
  abs: function (absolute) {
    return `/api${absolute}/`;
  }
}.init();
