import {defaults} from "lodash";
import Endpoint from '@/api/resources/base';
import urls from '@/api/urls';

function assertSession(sessionId, deviceId) {
  if (!sessionId && !deviceId) {
    throw new Error('Session ID and Device ID are missing');
  }
  if (sessionId && deviceId) {
    throw new Error('Session ID and Device ID are mutually exclusive');
  }
}

export default class SLMSession extends Endpoint {
  /**
   * Retrieve a debug session
   * @param {UUID} sessionId id of a debug session
   * @returns {Promise<import('axios').AxiosResponse<DeviceSessionSerializer>>}
   */
  detail = async (sessionId) => {
    const url = urls.slm.session.detail.format({sessionId});
    return this.api.get(url);
  };

  /**
   * List of samples for a session
   * @param {UUID} [sessionId]
   * @param {DateTime} [after] - Deprecated, use params.after instead
   * @param {string} [metric] - Deprecated, use params.metric instead
   * @param {SLMSamplesQuerySerializer} [params]
   * @returns {Promise<import('axios').AxiosResponse<SLMSamplesSerializer>>}
   */
  samples = async (sessionId, after, metric, params = {}) => {
    assertSession(sessionId, params.device_id);
    defaults(params, {metric, after});
    const url = urls.slm.session.samples.format({sessionId});
    return this.api.get(url, params);
  };

  /**
   * List of metric names available to a session
   * @param {UUID} sessionId
   * @param {DeviceSessionQuerySerializer} [params]
   * @returns {Promise<import('axios').AxiosResponse<string[]>>}
   */
  metrics = async (sessionId, params = {}) => {
    const url = urls.slm.session.metrics.format({sessionId});
    return this.api.get(url, params);
  };

  /**
   * List of alerts on a session
   * @param {UUID} sessionId
   * @param {ALERT_KIND_TYPE} [kind]
   * @param {SessionAlertsQuerySerialize} [params]
   * @returns {Promise<import('axios').AxiosResponse<BriefAlertSerializer[]>>}
   */
  alerts = async (sessionId, kind, params) => {
    const url = urls.slm.session.alerts.format({sessionId});
    return this.api.get(url, {kind});
  };

  /**
   * Stats of a metric
   * @param {UUID} [sessionId]
   * @param {string} [metric]
   * @param {SLMSamplesQuerySerializer} [params]
   * @returns {Promise<import('axios').AxiosResponse<SLMStatisticsSerializer>>}
   */
  statistics = async (sessionId, metric, params = {}) => {
    assertSession(sessionId, params.device_id);
    defaults(params, {metric});
    const url = urls.slm.session.statistics.format({sessionId});
    return this.api.get(url, params);
  };
  /**
   * List of session sensor statistic (based on user's account)
   * @param {UUID} [sessionId]
   * @param {number} [page] - Deprecated, use params.page instead
   * @param {SensorStatisticQuerySerializer} params
   * @return {Promise<import('axios').AxiosResponse<Paginated<SensorStatisticSerializer[]>>>}
   */
  sensorStatistics = async (sessionId, page = 1, params = {}) => {
    assertSession(sessionId, params.device_id);
    const url = urls.slm.session.sensorStatistics.format({sessionId});
    defaults(params, {page, size: 6});
    const data = {page, ...{size: 6}, ...params};
    return this.api.get(url, data);
  };
  /**
   * Delete session
   * @param {UUID} sessionId
   * @returns {Promise<import('axios').AxiosResponse>}
   */
  destroy = async (sessionId) => {
    const url = urls.slm.session.detail.format({sessionId});
    return this.api.delete(url);
  };
  /**
   * Bulk archive sessions
   * @param {{events: UUID[]}} data
   * @returns {Promise<import('axios').AxiosResponse>}
   */
  bulkArchive = async (data) => {
    const url = urls.slm.session.bulkArchive;
    return this.api.post(url, data);
  };
}
