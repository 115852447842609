import {ROLES} from '@/constants';
import api from '@/api';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import {findObjectIndex} from '@/utils/array';

export default {
  namespaced: true,
  state: () => ({
    /** @type {Member[]} **/
    list: [],
    loading: true,
    projectId: '',
    roles: [
      {
        title: ROLES.VIEWER,
        description: 'Can only view',
        short: 'Can view',
      },
      {
        title: ROLES.EDITOR,
        description: 'Can run new experiments, create new versions and filters etc.',
        short: 'Can edit',
      },
      {
        title: ROLES.MANAGER,
        description: 'Can delete a project and get the relevant owner notifications, plus Add and Remove other people from it..',
        short: 'Can manage',
      },
      {
        title: ROLES.OWNER,
        description: 'All permissions.',
        short: 'Owner',
        disabled: true,
      }
    ]
  }),
  getters: {
    me(state, getters, rootState) {
      // can be called before sys.user is populated
      const myID = rootState.sys.user?.id;
      if (myID === undefined) {
        return {};
      }
      return state.list.find((el) => el.user.id === myID) || {};
    },

    emails(state) {
      return state.list.map((el) => el.user.email);
    },

    /**
     * Who owns project
     */
    owner(state) {
      return state.list.find((el) => el.role === ROLES.OWNER);
    },

    /**
     * Active (non-pending) members
     */
    active(state) {
      return state.list.filter((el) => !el.pending);
    },
  },
  mutations: {

    setList(state, data) {
      state.list = orderBy(data, ['is_owner', 'id'], ['desc', 'desc']);
    },

    setProjectId(state, projectId) {
      state.projectId = projectId;
    },

    setMember(state, {id, ...rest}) {
      state.list = state.list.map((el) => {
        if (el.id === id) {
          return {...el, ...rest};
        }
        return el;
      });
    },

    addMember(state, data) {
      state.list = orderBy(uniqBy([data, ...state.list]), ['is_owner', 'id'], ['desc', 'desc']);
    },

    popMember(state, id) {
      const [idx] = findObjectIndex(state.list, {id});
      state.list = [...state.list.slice(0, idx), ...state.list.slice(idx + 1)];
    },
  },
  actions: {
    async refresh({state, commit}) {
      // rendering multiple components this refresh can be called during fetchList when user opens a project, ignore
      // such call as projectId will be set only when fetchList is finished.
      if (!state.projectId) {
        console.warn('No project id set.');
        return;
      }
      try {
        const response = await api.projects.retrieveMembers(state.projectId);
        commit('setList', response.data);
      } catch (error) {
        console.error(error.toString());
      }
    },
  },
};
