/***
 * As a property value supports string or array of strings
 * @param {Object[]} array - haystack
 * @param {String[]} properties - object's properties
 * @param {String} term - needle
 * @returns {Object[]} - found object with needle in a property
 */
export function findStringInObject(array, properties, term) {
  term = term.toLowerCase();
  return array.filter((el) => {
    for (let field of properties) {
      const value = el[field];
      if (Array.isArray(value) && typeof value[0] === 'string') {
        if (value.some((el) => el.toLowerCase().indexOf(term) > -1)) {
          return true;
        }
      }
      if (typeof value === 'string') {
        if (value.toLowerCase().indexOf(term) > -1) {
          return true;
        }
      }
    }
  });
}

/**
 * Accepts an array of objects and object to find in the array. If every property of the `need_object`
 * matches with an element of array, returns found index and object itself, or -1 and null.
 * @template T
 * @param {T[]} array
 * @param {T} need_object
 * @returns {[number, (T | null)]}
 */
export function findObjectIndex(array, need_object) {
  let idx = -1;
  for (let found_object of array) {
    idx += 1;
    // skip primitives
    if (found_object !== Object(found_object)) {
      continue;
    }
    const full_match = Object
      .entries(need_object)
      .every(([key, value]) => found_object[key] && found_object[key] === value);
    if (!full_match) {
      continue;
    }
    return [idx, found_object];
  }
  return [-1, null];
}

/**
 * Merges multiple arrays together
 * @deprecated use lodash's zip
 * @param {*[]} rows
 * @returns {*[][]}
 */
export function zip(...rows) {
  return [...rows[0]].map((_, c) => rows.map((row) => row[c]));
}

/**
 * Cycles through an iterable
 * Usage:
 *  const c = cycle([1, 2]);
 *  c.next() === 1;
 *  c.next() === 2;
 *  c.next() === 1;
 * @param iterable
 * @returns {Generator<*, void, *>}
 */
export function* cycle(iterable) {
  let counter = -1;
  while (true) {
    counter++;
    if (counter > iterable.length - 1) {
      counter = 0;
    }
    yield iterable[counter];
  }
}

/**
 * Creates a python-like namedtuple from an array.
 * Usage:
 *  const Point = namedtuple('x', 'y');
 *  const point = Point([1, 2]);
 *  point.x === 1;
 *  point.y === 2;
 *  point[0] === 1;
 *  point[1] === 2;
 * @param {string[]} names
 * @returns {function(*): *}
 */
export const namedtuple = (...names) => (array) => {
  return new Proxy(array, {
    get: function (target, property, receiver) {
      if (property === Symbol.iterator) {
        return target[Symbol.iterator].bind(target);
      }
      const nameIndex = names.indexOf(property);
      property = nameIndex === -1 ? property : nameIndex;
      return Reflect.get(target, property, receiver);
    },
  });
};

/**
 * Compares two objects.
 * @link https://github.com/epoberezkin/fast-deep-equal/blob/master/src/index.jst
 * @param {(Array | Map | Set | ArrayBuffer | RegExp)} a
 * @param {(Array | Map | Set | ArrayBuffer | RegExp)} b
 * @returns {boolean}
 */
export function isEqual(a, b) {
  if (a === b) {
    return true;
  }

  if (a && b && typeof a === 'object' && typeof b === 'object') {
    if (a.constructor !== b.constructor) {
      return false;
    }

    let length, i, keys;
    if (Array.isArray(a)) {
      length = a.length;
      if (length !== b.length) {
        return false;
      }
      for (i = length; i-- !== 0;) {
        if (!isEqual(a[i], b[i])) {
          return false;
        }
      }
      return true;
    }

    if ((a instanceof Map) && (b instanceof Map)) {
      if (a.size !== b.size) {
        return false;
      }
      for (i of a.entries()) {
        if (!b.has(i[0])) {
          return false;
        }
      }
      for (i of a.entries()) {
        if (!isEqual(i[1], b.get(i[0]))) {
          return false;
        }
      }
      return true;
    }

    if ((a instanceof Set) && (b instanceof Set)) {
      if (a.size !== b.size) {
        return false;
      }
      for (i of a.entries()) {
        if (!b.has(i[0])) {
          return false;
        }
      }
      return true;
    }

    if (ArrayBuffer.isView(a) && ArrayBuffer.isView(b)) {
      length = a.length;
      if (length !== b.length) {
        return false;
      }
      for (i = length; i-- !== 0;) {
        if (a[i] !== b[i]) {
          return false;
        }
      }
      return true;
    }

    if (a.constructor === RegExp) {
      return a.source === b.source && a.flags === b.flags;
    }
    if (a.valueOf !== Object.prototype.valueOf) {
      return a.valueOf() === b.valueOf();
    }
    if (a.toString !== Object.prototype.toString) {
      return a.toString() === b.toString();
    }

    keys = Object.keys(a);
    length = keys.length;
    if (length !== Object.keys(b).length) {
      return false;
    }

    for (i = length; i-- !== 0;) {
      if (!Object.prototype.hasOwnProperty.call(b, keys[i])) {
        return false;
      }
    }

    for (i = length; i-- !== 0;) {
      const key = keys[i];
      if (!isEqual(a[key], b[key])) {
        return false;
      }
    }

    return true;
  }

  // true if both NaN, false otherwise
  return a !== a && b !== b;
}


/**
 * @description Turns every element in `values` into object taking keys from the `keys`.
 *
 * @example
 *  rehydrate(['id', ['author', ['first_name', 'last_name']], 'link'], [[1, ['John', 'Doe'], 'ddg.gg']])
 *  [{id: 1, author: {first_name: 'John', last_name: 'Doe'}, link: 'ddg.gg'}]
 *
 * @param {string[]} keys
 * @param {any[][]} values
 * @returns {any[]} unfortunately I cannot define a correct type here.
 */
export function rehydrate(keys, values) {
  /**
   * @param {(string[] | string[][])} keys
   * @returns {(function(Record<string, any>, (string | string[]), number): (Record<string, any>))}
   * @throws Error
   */
  if (values === undefined) {
    return [];
  }
  const reducer = (keys) => (previousValue, currentValue, currentIndex) => {
    const key = keys[currentIndex];
    if (typeof key === 'string') {
      previousValue[key] = currentValue;
      return previousValue;
    }
    if (Array.isArray(key)) {
      const [subKey, subKeys] = key;
      if (!Array.isArray(subKeys)) {
        throw new Error('Sub keys must be an array.');
      }
      previousValue[subKey] = currentValue.reduce(reducer(subKeys), {});
      return previousValue;
    }
    throw new Error(`Unexpected key type "${typeof key}".`);
  };
  return values.map((value) => value.reduce(reducer(keys), {}));
}
