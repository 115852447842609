const RGX_EMAIL = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export {RGX_EMAIL};

/**
 * Valid range is an array with exactly two values inside
 * @param {any} x
 * @return boolean
 */
export function isValidRange(x) {
  return Array.isArray(x) && x.length === 2;
}

/**
 * Valid range is an array with exactly two values inside and one of them or both is number
 * @param {any} x
 * @return boolean
 */
export function isValidOptionalNumberRange(x) {
  return isValidRange(x) && (isNumber(x[0]) || isNumber(x[1]));
}

/**
 * Check whether a value is a finite number or a string representation of the number
 * @param {any} val
 * @return boolean
 */
export function isNumber(val) {
  return val !== null && typeof +val === 'number' && !isNaN(val);
}


/**
 * Checks if val1 is greater than or equal to val2.
 * @param {number} val1 - The first value to compare.
 * @param {number} val2 - The second value to compare.
 * @returns {boolean} - `true` if val1 is greater than or equal to val2, `false` otherwise.
 */
export function isBiggerThan(val1, val2) {
  if (isNumber(val1) && isNumber(val2)) {
    return Number(val1) > Number(val2);
  }
  return false;
}

/**
 * Checks if val1 is less than or equal to val2.
 * @param {number} val1 - The first value to compare.
 * @param {number} val2 - The second value to compare.
 * @returns {boolean} - `true` if val1 is greater than or equal to val2, `false` otherwise.
 */
export function isLessThan(val1, val2) {
  if (isNumber(val1) && isNumber(val2)) {
    return Number(val1) < Number(val2);
  }
  return false;
}


/**
 * Call callbacks until their returned result matches the expected value.
 * If a callback returns a value that differs from the expected value,
 * it will be returned and the other callbacks will not be called.
 * @param {(function(*): any)[]} callbacks
 * @param {any[]} args - input args for callback
 * @param {any} expected_value
 * @returns {expected_value|any} - expected value or result of any callbacks
 */
export function combineValidator(callbacks, args, expected_value) {
  return callbacks.reduce((value, fn) => value === expected_value ? fn(...args) : value, expected_value);
}
